import React from "react";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { showFormPopUp } from "../../Reducers/settings";
import CONFIG from "../../Services/ConfigService";
import "./index.scss";

export default function CTAButtonWrapper({ options, data, children }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const onClickBtn = (actionType) => {
    if (actionType === "early-access") {
      const formOptions = {
        content: {
          type: "early-access",
          variables: [
            {
              section: "description",
              keys: {
                productName: data.productName,
              },
            },
          ],
        },
        theme: options.formPopupTheme,
      };
      dispatch(showFormPopUp(formOptions));
    }

    if (actionType === "security-session") {
      const formOptions = {
        content: {
          type: "security-session",
          variables: [
            {
              section: "description",
              keys: {
                productName: data.productName,
              },
            },
          ],
        },
        theme: options.formPopupTheme,
      };
      dispatch(showFormPopUp(formOptions));
    }

    if (actionType === "register") {
      const generateUTMLink =
        CONFIG.APP.REGISTER_URL +
        `?utm_source=${encodeURIComponent(
          window.location.href
        )}&utm_medium=referral&utm_content=${options?.utmLabel}`;
      window.location.href = generateUTMLink;
    }

    if (actionType === "view-tutorials") {
      navigate("/tutorials");
    }

    if (actionType === "book-a-demo") {
      const formOptions = {
        content: {
          type: "book-a-demo",
        },
        theme: "light",
      };

      dispatch(showFormPopUp(formOptions));
    }
  };

  return (
    <div className={options?.className} onClick={() => onClickBtn(data.action)}>
      {children}
    </div>
  );
}
