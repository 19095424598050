import React, { useState, useEffect } from "react";
import { Link, useParams, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import { showFormPopUp } from "../../Reducers/settings";
import MTReport2024CoverImage from "../../Assets/Images/Reports/mt-2024.png";
import MTPlayground2022CoverImage from "../../Assets/Images/Reports/mt-playground-2022.png";
import "./index.scss";

export default function Reports() {
  const dispatch = useDispatch();
  const onClickBtn = (variables) => {
    dispatch(
      showFormPopUp({
        content: {
          type: "get-report",
          variables: variables,
        },
        theme: "dark",
      })
    );
  };
  return (
    <div className="reports-page">
      <section className="reports-page-header js-section_nav-dark">
        <div className="container mx-auto">
          <div className="parent">
            <div className="div1">
              <div className="reports-page-header__title">
                Reports by <span className="shine">lugath</span>
              </div>
            </div>
            <div className="div2">
              <div className="reports-page-header__content">
                <div className="reports-page-header__content-desc">
                  Looking for data, research backed studies, and helpful tips
                  and recommendations from industry experts? You came to the
                  right page.
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="reports-page-content ">
        <div className="reports-page-content__list">
          <div className="reports-page-content__list-item__wrapper">
            <div className="container mx-auto">
              <div className="reports-page-content__list-item">
                <div className="reports-page-content__list-item__content">
                  <div className="reports-page-content__list-item__content-title">
                    Machine Translation Assessment '24
                  </div>
                  <div className="reports-page-content__list-item__content-desc">
                    Do you have insight into which machine translation engine
                    aligns best with your content, considering language and
                    industry domain?
                    <br />
                    <br />
                    Select the appropriate engine to reduce post-editing efforts
                    by up to 70%.
                    <br />
                    <br />
                    Explore our report to discover how we assess translation
                    quality using machine learning and natural language
                    processing methods.
                  </div>

                  <div className="reports-page-content__list-item__content-cta">
                    <button
                      onClick={() => {
                        onClickBtn([
                          {
                            section: "title",
                            keys: {
                              reportName: "Machine Translation Assessment '24",
                            },
                          },
                          {
                            section: "description",
                            keys: {
                              reportName: "Machine Translation Assessment '24",
                            },
                          },
                        ]);
                      }}
                    >
                      {" "}
                      Get The Report
                    </button>
                  </div>
                </div>
                <div className="reports-page-content__list-item__image">
                  <img src={MTReport2024CoverImage} alt="" />
                </div>
              </div>
            </div>
          </div>
          {/*<div className="reports-page-content__list-item__wrapper">
            <div className="container mx-auto">
              <div className="reports-page-content__list-item">
                <div className="reports-page-content__list-item__content">
                  <div className="reports-page-content__list-item__content-title">
                    Machine Translation Playground
                  </div>
                  <div className="reports-page-content__list-item__content-desc">
                    The report shows MT products with API services. <br />
                    <br />
                    All product names and brands are property of their
                    respective owners. All brands, products and services used in
                    this report are for identification purposes only. <br />{" "}
                    <br />
                    Use of these brands does not imply endorsement.
                  </div>

                  <div className="reports-page-content__list-item__content-cta">
                    <button
                      onClick={() => {
                        onClickBtn([
                          {
                            section: "title",
                            keys: {
                              reportName: "Machine Translation Playground",
                            },
                          },
                          {
                            section: "description",
                            keys: {
                              reportName: "Machine Translation Playground",
                            },
                          },
                        ]);
                      }}
                    >
                      {" "}
                      Get The Report
                    </button>
                  </div>
                </div>
                <div className="reports-page-content__list-item__image">
                  <img src={MTPlayground2022CoverImage} alt="" />
                </div>
              </div>
            </div>
                    </div>*/}
        </div>
      </section>
    </div>
  );
}
