import React, { useState } from "react";
import "./index.scss";
import Image from "../Image";
import BrowserFrame from "../BrowserFrame";

export default function TextToutWithImage({
  data,
  options = { theme: "dark", image: "right", text: "left", withFrame: false },
}) {
  return (
    <section
      className={`text-tout-with-image text-tout-with-image--${options.theme} ${
        options.theme === "light" ? "js-section_nav-dark" : ""
      }`}
    >
      <div className="container mx-auto">
        <div className="text-tout-with-image__wrapper">
          <div className={`${options.text === "left" ? "div1" : "div2"}`}>
            <div className="text-tout-with-image__content">
              <div className="text-tout-with-image__content-title">
                {data.title}
              </div>
              <div className="text-tout-with-image__content-desc">
                {data.description}
              </div>
            </div>
          </div>
          <div className={`${options.image === "left" ? "div1" : "div2"}`}>
            <div className="text-tout-with-image__image-wrapper">
              {options.withFrame ? (
                <BrowserFrame
                  url={options.frameOptions.url}
                  path={options.frameOptions.path}
                  image={{
                    source: data.image.source,
                    width: data.image.width,
                    height: data.image.height,
                  }}
                  onClickAction={options.frameOptions.onClickAction}
                  action={options.frameOptions.action}
                />
              ) : (
                <div className="text-tout-with-image__image">
                  <Image
                    fileName={data.image.source}
                    width={data.image.width}
                    height={data.image.height}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
