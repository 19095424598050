import _extend from "lodash/extend";
import { productsData } from "../Services/DataService";
const SUCCESS_SUFFIX = "_SUCCESS";
const LIST_PRODUCTS = "LIST_PRODUCTS";
const GET_PRODUCT = "GET_PRODUCT";

const initialState = [];

// eslint-disable-next-line
export default (state = initialState, action) => {
  switch (action.type) {
    case LIST_PRODUCTS + SUCCESS_SUFFIX:
      return action.payload.data;
    case GET_PRODUCT + SUCCESS_SUFFIX:
      return action.payload.data;
    default:
      return state;
  }
};

export const allProducts = () => {
  return (dispatch) => {
    dispatch({
      type: LIST_PRODUCTS + SUCCESS_SUFFIX,
      payload: { data: productsData },
    });
  };
};

export const setActiveProduct = (slug) => {
  return (dispatch) => {
    dispatch({
      type: GET_PRODUCT + SUCCESS_SUFFIX,
      payload: { data: productsData.filter((item) => item.slug === slug) },
    });
  };
};
