import React from "react";
import { Link } from "react-router-dom";
import { ReactComponent as ChevronRight } from "../../Assets/Icons/chevron-right.svg";
export default function SolutionCard({ item, index }) {
  return (
    <div
      key={index}
      className={`col-span-full lg:col-span-2 row-span-2  grid grid-cols-4 grid-rows-2 information-box ${
        item.disabled ? "disabled" : ""
      }`}
    >
      <div
        className={`${
          index % 2 === 0
            ? "col-span-2 lg:col-span-full row-span-full grid grid-rows-2 bg-dot"
            : "col-span-2 lg:col-span-full row-span-full grid grid-rows-2 col-start-3 bg-dot"
        }`}
      >
        <div className="col-span-full relative grid items-end">
          <h3 dangerouslySetInnerHTML={{ __html: item.title }} />
          {item.showNumber ? <h5>{index < 10 ? "0" + index : index}</h5> : null}
        </div>
        <div className="col-span-full flex items-center">
          {item.disabled ? null : (
            <Link to={"/solutions/" + item.slug}>
              Discover{" "}
              <span className="readmore-icon">
                <ChevronRight />
              </span>
            </Link>
          )}
        </div>
      </div>
    </div>
  );
}
