import React from "react";
import { v4 as uuidv4 } from "uuid";
import MileStone from "../MileStone";
import CTAButton from "../CTABtnWrapper";

import "./index.scss";

export default function BetaForm({ data, productName }) {
  return (
    <section className="product-beta-access-bg js-section_nav-dark">
      <div className="container mx-auto product-beta-access__container">
        <div className="product-beta-access__wrapper">
          <div className="product-beta-access__inner">
            <div className="parent">
              <div className="div1">
                <div className="product-beta-access__content-wrapper">
                  <div className="product-beta-access__content-inner">
                    <div className="product-beta-access__content">
                      <div className="product-beta-access__content-title">
                        Be part of it{" "}
                      </div>
                      <div className="product-beta-access__content-desc">
                        <span className="highlight">
                          Are you an early adapter?
                        </span>{" "}
                        Sign up for free and experience{" "}
                        <span className="highlight-cyan">
                          {data?.productName}
                        </span>{" "}
                        before it’s available to the public.
                      </div>{" "}
                    </div>
                  </div>
                </div>
              </div>
              <div className="div2">
                <div className="product-beta-access__subscriber-wrapper">
                  <div className="product-beta-access__subscriber-inner">
                    <div className="product-beta-access__subscriber">
                      <span className="product-beta-access__subscriber-count">
                        <MileStone
                          duration={0.5}
                          end={
                            data?.subscriberCount ? data?.subscriberCount : 0
                          }
                        />
                      </span>
                      <span className="product-beta-access__subscriber-label">
                        Users Already Joined
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="div3">
                <div className="product-beta-access__form-wrapper">
                  <div className="product-beta-access__form-inner">
                    <div className="product-beta-access__form">
                      {data.cta.map((c, i) => (
                        <CTAButton
                          key={uuidv4()}
                          options={{
                            formPopupTheme: "light",
                          }}
                          children={
                            <button className="product-beta-access__form-btn">
                              {c.label}
                            </button>
                          }
                          data={{
                            ...c,
                            ...{ productName: productName ? productName : "" },
                          }}
                        ></CTAButton>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
