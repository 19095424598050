import React, { useState, useEffect, useCallback } from "react";
import { ReactComponent as ChevronRight } from "../../../Assets/Icons/chevron-right.svg";
import { ReactComponent as DropboxLogo } from "../../../Assets/Images/Integration/dropbox.svg";
import { ReactComponent as NotionLogo } from "../../../Assets/Images/Integration/notion.svg";
import { ReactComponent as EvernoteLogo } from "../../../Assets/Images/Integration/evernote.svg";
import { ReactComponent as IntercomLogo } from "../../../Assets/Images/Integration/intercom.svg";
import { ReactComponent as HubspotLogo } from "../../../Assets/Images/Integration/hubspot.svg";
import { ReactComponent as SlackLogo } from "../../../Assets/Images/Integration/slack.svg";
import { ReactComponent as JiraLogo } from "../../../Assets/Images/Integration/jira.svg";
import { ReactComponent as StrapiLogo } from "../../../Assets/Images/Integration/strapi.svg";
import { ReactComponent as ZendeskLogo } from "../../../Assets/Images/Integration/zendesk.svg";
import { ReactComponent as ZapierLogo } from "../../../Assets/Images/Integration/zapier.svg";
import { ReactComponent as TypeformLogo } from "../../../Assets/Images/Integration/typeform.svg";
import { ReactComponent as LugathLogo } from "../../../Assets/Icons/logo-t.svg";
import { Link } from "react-router-dom";
import "./index.scss";

export default function Security() {
  return (
    <div className="bg-security">
      <div className="security-section container mx-auto">
        <h1 className="security-section__title">
          Secure &
          <br />
          <span style={{ color: "#aaa" }}>Compliant </span>
        </h1>
        {/*<p className="security-section__desc">
          Integrates with all your essential apps.
  </p>*/}
        <div className="security-section__content">
          <div className="parent">
            <div className="security-card div1">SOC 2 Type II </div>
            <div className="security-card div2"> </div>
            <div className="security-card div3">No Data Indexing </div>
            <div className="security-card div4"> </div>
            <div className="security-card div5"> </div>
            <div className="security-card div6"> GDPR</div>
            <div className="security-card div7"> </div>
            <div className="security-card div8"> </div>
            <div className="security-card div9">SSO </div>
            <div className="security-card div10"> </div>
            <div className="security-card div11">CCPA </div>
            <div className="security-card div12"> SSL</div>
            <div className="security-card div13"> </div>
          </div>
        </div>
      </div>
    </div>
  );
}
