import { React, useState, useEffect } from "react";
import { scrollToElement } from "../../Libs/utils";
import { isInViewport } from "../../Libs/utils";
import { glossaryData } from "../../Services/DataService";
import "./translation_defined.scss";

const alphabets = [
  "A",
  "B",
  "C",
  "D",
  "E",
  "F",
  "G",
  "H",
  "I",
  "J",
  "K",
  "L",
  "M",
  "N",
  "O",
  "P",
  "Q",
  "R",
  "S",
  "T",
  "U",
  "V",
  "W",
  "X",
  "Y",
  "Z",
];

export default function Glossary() {
  const [activeSection, setActiveSection] = useState(glossaryData[0].name);

  const checkAlphabetHasContent = (alphabet) => {
    return glossaryData.filter((item) => item.name === alphabet).length
      ? true
      : false;
  };

  const checkAlphhabetIsActive = (alphabet) => {
    return activeSection === alphabet ? true : false;
  };

  const onClickAlphabet = (alphabet) => {
    checkAlphabetHasContent(alphabet) && scrollToAlphabet(alphabet);
  };

  const scrollToAlphabet = (alphabet) => {
    const id = "section_letter-" + alphabet;
    scrollToElement(id, 100);
  };

  const onScroll = () => {
    glossaryData.map(
      (item) =>
        isInViewport(document.getElementById("section_letter-" + item.name)) &&
        setActiveSection(item.name)
    );
  };

  useEffect(() => {
    document.addEventListener("scroll", onScroll);

    return () => {
      document.removeEventListener("scroll", onScroll);
    };
  }, []);

  return (
    <div className="translation-defined">
      <div className="w-full">
        <div className="grid-container container mx-auto">
          <div className="col-span-full lg:col-span-7 lg:col-start-2 row-start-1">
            <h1>Glossary </h1>
          </div>
        </div>
      </div>
      <div className="w-full bg-white py-48">
        <div className="grid-container container mx-auto">
          <div className="row-span-full col-start-1 char-wrapper">
            <div className="char-inner">
              {alphabets.map((alphabet, index) => {
                return (
                  <div
                    key={index}
                    className="character-wrapper flex justify-center "
                  >
                    <div
                      onClick={() => onClickAlphabet(alphabet)}
                      className={`${
                        checkAlphabetHasContent(alphabet)
                          ? checkAlphhabetIsActive(alphabet)
                            ? "flex justify-between w-2/4 active"
                            : "flex justify-between w-2/4"
                          : "flex justify-between w-2/4 empty"
                      }`}
                    >
                      <p className="cursor-pointer">{alphabet}</p>
                      <div className="dot"></div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
          <div className="col-span-3 lg:col-span-7 grid grid-cols-3 lg:grid-cols-7 ">
            {glossaryData.map((category, index) =>
              category.items.length ? (
                <div
                  key={index}
                  id={"section_letter-" + category.name}
                  className="col-span-full grid grid-cols-3 lg:grid-cols-7 "
                >
                  <div className="col-span-full lg:col-span-1 flex items-start lg:justify-center">
                    <h3 className="uppercase">{category.name}</h3>
                  </div>
                  {category.items.map((item, index) => {
                    return (
                      <div
                        key={index}
                        className="col-span-6 lg:col-span-5 col-start-1 lg:col-start-2"
                      >
                        <h2>{item.heading}</h2>
                        <p>{item.description}</p>
                      </div>
                    );
                  })}
                </div>
              ) : null
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
