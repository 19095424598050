import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import DefaultLayout from "../Layout/default";
import ErrorLayout from "../Layout/error";
import EmptyLayout from "../Layout/error";
import Reports from "../Pages/Reports";
import Security from "../Pages/Security";
import Solutions from "../Pages/Solutions";
import Products from "../Pages/Products";
import API from "../Pages/API";
import Integrations from "../Pages/Integrations";
import IntegrationDetails from "../Pages/Integrations/details";
import Pricing from "../Pages/Pricing";
import Home from "../Pages/Home";
import Company from "../Pages/Company";
import NotFound from "../Pages/NotFound";
import Signature from "../Pages/Signature";
import SolutionDetailsPage from "../Pages/SolutionDetailsPage";
import ProductDetailsPage from "../Pages/ProductDetailsPage";
import News from "../Pages/News";
import NewsDetail from "../Pages/NewsDetail";
import Updates from "../Pages/Updates";
import VideoTutorials from "../Pages/VideoTutorials";
import UpdateDetails from "../Pages/Updates/details";
import Glossary from "../Pages/Glossary";
import TermsOfUse from "../Pages/TermsOfUse/index-t";
import PrivacyPolicy from "../Pages/TermsOfUse/index-p";
import CookiePolicy from "../Pages/TermsOfUse/index-c";
import Contact from "../Pages/Contact";
import Faq from "../Pages/Faq";
import CookieSettings from "../Pages/CookieSettings";
import "./index.scss";

export default function RouteConfig(props) {
  const getRoutes = () => {
    return (
      <div className="app">
        <Routes>
          <Route
            exact
            path="/"
            element={
              <DefaultLayout {...props}>
                {" "}
                <Home {...props} />{" "}
              </DefaultLayout>
            }
          />
          <Route
            exact
            path="/reports"
            element={
              <DefaultLayout {...props}>
                {" "}
                <Reports {...props} />{" "}
              </DefaultLayout>
            }
          />

          <Route
            path="/api"
            exact
            component={() => {
              <p>{(window.location.href = "https://example.com")}</p>;
            }}
          />
          <Route
            exact
            path="/solutions"
            element={
              <DefaultLayout {...props}>
                {" "}
                <Solutions {...props} />{" "}
              </DefaultLayout>
            }
          />
          <Route
            exact
            path="/products"
            element={
              <DefaultLayout {...props}>
                {" "}
                <Products {...props} />{" "}
              </DefaultLayout>
            }
          />
          <Route
            exact
            path="/pricing"
            element={
              <DefaultLayout {...props}>
                {" "}
                <Pricing {...props} />{" "}
              </DefaultLayout>
            }
          />
          <Route
            exact
            path="/integrations"
            element={
              <DefaultLayout {...props}>
                {" "}
                <Integrations {...props} />{" "}
              </DefaultLayout>
            }
          />
          <Route
            exact
            path="/integrations/:appName"
            element={
              <DefaultLayout {...props}>
                {" "}
                <IntegrationDetails {...props} />{" "}
              </DefaultLayout>
            }
          />
          <Route
            exact
            path="/company"
            element={
              <DefaultLayout {...props}>
                {" "}
                <Company {...props} />{" "}
              </DefaultLayout>
            }
          />
          <Route
            exact
            path="/security"
            element={
              <DefaultLayout {...props}>
                {" "}
                <Security {...props} />{" "}
              </DefaultLayout>
            }
          />
          <Route
            exact
            path="/solutions/:slug"
            element={
              <DefaultLayout {...props}>
                {" "}
                <SolutionDetailsPage {...props} />{" "}
              </DefaultLayout>
            }
          />
          <Route
            exact
            path="/products/:slug"
            element={
              <DefaultLayout {...props}>
                {" "}
                <ProductDetailsPage {...props} />{" "}
              </DefaultLayout>
            }
          />

          <Route
            exact
            path="/tutorials"
            element={
              <DefaultLayout {...props}>
                {" "}
                <VideoTutorials {...props} />{" "}
              </DefaultLayout>
            }
          />
          <Route
            exact
            path="/news"
            element={
              <DefaultLayout {...props}>
                {" "}
                <Updates {...props} />{" "}
              </DefaultLayout>
            }
          />
          <Route
            exact
            path="/news/:updateID"
            element={
              <DefaultLayout {...props}>
                {" "}
                <UpdateDetails {...props} />{" "}
              </DefaultLayout>
            }
          />
          <Route
            exact
            path="/glossary"
            element={
              <DefaultLayout {...props}>
                {" "}
                <Glossary {...props} />{" "}
              </DefaultLayout>
            }
          />
          <Route
            exact
            path="/terms-of-use"
            element={
              <DefaultLayout {...props}>
                {" "}
                <TermsOfUse {...props} />{" "}
              </DefaultLayout>
            }
          />
          <Route
            exact
            path="/privacy-policy"
            element={
              <DefaultLayout {...props}>
                {" "}
                <PrivacyPolicy {...props} />{" "}
              </DefaultLayout>
            }
          />
          <Route
            exact
            path="/cookie-policy"
            element={
              <DefaultLayout {...props}>
                {" "}
                <CookiePolicy {...props} />{" "}
              </DefaultLayout>
            }
          />
          <Route
            exact
            path="/cookie-settings"
            element={
              <DefaultLayout {...props}>
                {" "}
                <CookieSettings {...props} />{" "}
              </DefaultLayout>
            }
          />
          <Route
            exact
            path="/contact"
            element={
              <DefaultLayout {...props}>
                {" "}
                <Contact {...props} />{" "}
              </DefaultLayout>
            }
          />
          <Route
            exact
            path="/faqs"
            element={
              <DefaultLayout {...props}>
                {" "}
                <Faq {...props} />{" "}
              </DefaultLayout>
            }
          />
          <Route
            exact
            path="/signature"
            element={
              <EmptyLayout {...props}>
                {" "}
                <Signature {...props} />{" "}
              </EmptyLayout>
            }
          />
          <Route
            exact
            path="/error"
            element={
              <ErrorLayout {...props}>
                {" "}
                <NotFound {...props} />{" "}
              </ErrorLayout>
            }
          />
          <Route
            path="*"
            element={
              <ErrorLayout {...props}>
                {" "}
                <NotFound {...props} />{" "}
              </ErrorLayout>
            }
          />
        </Routes>
      </div>
    );
  };

  return getRoutes();
}
