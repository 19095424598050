import React, { useState, useEffect } from "react";
import ReactPlayer from "react-player";
import "./index.scss";

export default function VideoPlayer({ url }) {
  return (
    <>
      <ReactPlayer
        className="react-player"
        width="100%"
        height="100%"
        style={{
          maxWidth: "1280px",
          maxHeight: "720px",
        }}
        config={{
          youtube: {
            playerVars: {
              showInfo: 0,
              modestBranding: 0,
              rel: 0,
              controls: 1,
              lightMode: 1,
            },
          },
        }}
        url={url}
      />
    </>
  );
}
