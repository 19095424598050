import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { ReactComponent as PlayCircleIcon } from "../../Assets/Icons/play-circle.svg";
import { ReactComponent as CloseIcon } from "../../Assets/Icons/close-icon--alternative.svg";
import ReactModal from "react-modal";
import VideoPlayer from "../../Components/VideoPlayer";
import { videosData } from "../../Services/DataService";
import "./index.scss";

export default function VideoTutorials() {
  const [showModal, setShowModal] = useState(false);
  const [activeVideoURL, setActiveVideoURL] = useState(false);

  const onClickVideoItem = (item) => {
    setActiveVideoURL(item.videoURL);
  };

  const onCloseModal = () => {
    setShowModal(false);
    setActiveVideoURL(false);
  };

  useEffect(() => {
    if (activeVideoURL?.length) {
      setShowModal(true);
    }
  }, [activeVideoURL]);

  return (
    <div className="video-tutorials-page">
      <section className="video-tutorials-page-hero__wrapper js-section_nav-dark">
        <div className="video-tutorials-page-hero">
          <div className="container mx-auto">
            <div className="parent">
              <div className="div1">
                <div className="video-tutorials-page-hero__header">
                  Tutorials
                </div>
                <div className="video-tutorials-page-hero__desc">
                  Learn to use all lugath features step-by-step.
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="video-tutorials-page-content js-section_nav-dark">
        {videosData.map((category) => {
          return (
            <div className="video-tutorials-page-content__item">
              <div className="container mx-auto">
                <div className="parent">
                  <div className="div1">
                    <div className="video-tutorials-page-content__card-wrapper">
                      <div className="video-tutorials-page-content__card">
                        <div className="video-tutorials-page-content__card-title">
                          {category.categoryName}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="div2">
                    <div className="video-tutorials-page-content__card-wrapper">
                      <div className="video-tutorials-page-content__card">
                        <ul className="video-tutorials-page-content__card-list">
                          {category.items.map((item) => (
                            <li className="video-tutorials-page-content__card-list__item">
                              <button onClick={() => onClickVideoItem(item)}>
                                <span className="icon">
                                  <PlayCircleIcon />
                                </span>
                                <span className="label">{item.videoName}</span>
                              </button>
                            </li>
                          ))}
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </section>
      <ReactModal
        style={{
          overlay: {
            position: "fixed",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: "rgba(0, 0, 0, 0.75)",
            zIndex: "9999999999",
          },
          content: {
            position: "absolute",
            top: "50%",
            left: "50%",
            width: "80%",
            height: "80%",
            maxWidth: "1280px",
            maxHeight: "720px",
            transform: "translate(-50%, -50%)",
            background: "#282828",
            overflow: "visible",
            WebkitOverflowScrolling: "touch",
            borderRadius: "0px",
            outline: "none",
            padding: "0",
            border: "0px",
            boxShadow: "0px 0px 125px rgba(0,0,0,0.5)",
          },
        }}
        isOpen={showModal}
        contentLabel="Minimal Modal Example"
      >
        <VideoPlayer url={activeVideoURL} />
        <button className="close-video-btn" onClick={() => onCloseModal()}>
          <span className="label">CLOSE</span>
          <CloseIcon />
        </button>
      </ReactModal>
    </div>
  );
}
