import React from "react";
import { useSelector } from "react-redux";
import NewsList from "../../NewsPageComponents/List";

export default function FourthSection() {
  const news = useSelector((state) => state.news);

  return (
    <div className="bg-white">
      <div className="grid-container homepage-fourthSection container mx-auto">
        <div className="col-span-full hidden lg:flex lg:col-span-6"></div>
        <div className="lg:col-start-2 col-span-full lg:col-span-6 flex items-center">
          <h1 className="">Updates</h1>
        </div>
        <NewsList news={news} />
        <div className="col-span-full lg:col-span-6"></div>
      </div>
    </div>
  );
}
