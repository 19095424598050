export const glossary = [
  {
    id: 1,
    name: "A",
    items: [
      {
        id: 1,
        heading: "Aligment",
        description:
          "Automatic matching of source and target translations, segment by segment.",
      },
      {
        id: 2,
        heading: "Auto-propagation",
        description:
          "Save time by eaisly pre-translating a file with the content in your Translation Memory (TM).",
      },
      {
        id: 3,
        heading: "Auto-Suggest",
        description:
          "Quickly lookup previously translated words in your Translation Memory (TM) using Concordance search or Work faster with LookAhead instantly retrieving translations as you work.",
      },
    ],
  },
  {
    id: 2,
    name: "B",
    items: [{
        id: 1,
        heading: "Basement",
        description:
          "Automatic matching of source and target translations, segment by segment.",
      },
      {
        id: 2,
        heading: "Bearer",
        description:
          "Save time by eaisly pre-translating a file with the content in your Translation Memory (TM).",
      },
      {
        id: 3,
        heading: "Backup",
        description:
          "Quickly lookup previously translated words in your Translation Memory (TM) using Concordance search or Work faster with LookAhead instantly retrieving translations as you work.",
      },
    ],
  },
  {
    id: 3,
    name: "E",
    items: [
      {
        id: 1,
        heading: "Concordance",
        description:
          "Automatic matching of source and target translations, segment by segment.",
      },
      {
        id: 2,
        heading: "Context-Match",
        description:
          "Save time by eaisly pre-translating a file with the content in your Translation Memory (TM).",
      },
    ],
  },
  {
    id: 4,
    name: "G",
    items: [
      {
        id: 1,
        heading: "Doncordance",
        description:
          "Automatic matching of source and target translations, segment by segment.",
      },
      {
        id: 2,
        heading: "Dontext-Match",
        description:
          "Save time by eaisly pre-translating a file with the content in your Translation Memory (TM).",
      },
    ],
  },
  {
    id: 5,
    name: "M",
    items: [{
        id: 1,
        heading: "Eina",
        description:
          "Automatic matching of source and target translations, segment by segment.",
      },
      {
        id: 2,
        heading: "Error",
        description:
          "Save time by eaisly pre-translating a file with the content in your Translation Memory (TM).",
      },
      {
        id: 3,
        heading: "Estimation",
        description:
          "Quickly lookup previously translated words in your Translation Memory (TM) using Concordance search or Work faster with LookAhead instantly retrieving translations as you work.",
      },],
  },
  {
    id: 4,
    name: "Z",
    items: [
      {
        id: 1,
        heading: "Foncordance",
        description:
          "Automatic matching of source and target translations, segment by segment.",
      },
      {
        id: 2,
        heading: "Fntext-Match",
        description:
          "Save time by eaisly pre-translating a file with the content in your Translation Memory (TM).",
      },
    ],
  }
];
