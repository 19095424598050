import React, { useState, useEffect } from "react";
import { Link, useParams, useLocation } from "react-router-dom";
import Image from "../../Components/Image";
import HeroTextToutWithList from "../../Components/TextToutWithList";
import { productsData } from "../../Services/DataService";
import "./index.scss";

export default function ProductList({
  data,
  options = {
    shape: "icon",
    imageMinWidth: "64px",
  },
}) {
  const [productData, setProductData] = useState(false);
  const transformData = (data) => {
    const items = data
      .map((d) => ({
        slug: d.slug,
        productName: d.productName,
        icon: d.icon,
        data: d.modules.find((m) => m.moduleName === "ProductDetailsHeader")
          ?.data,
      }))
      .filter((i) => !!i.data);
    const transformItems = items.map((i) => {
      return {
        image:
          options.shape === "icon"
            ? `Assets/Icons/${i.icon}`
            : `Assets/Images/Products/${i.data?.icon}`,
        link: `/products/${i.slug}`,
        title: i.productName,
        //description: i.data.description,
      };
    });
    return transformItems;
  };

  const getTransformedData = () => {
    const defaultData = {
      primaryTitle: data.title,
      secondaryTitle: data.description,
      features: transformData(productsData),
    };
    return defaultData;
  };

  useEffect(() => {
    setProductData(getTransformedData(productsData));
  }, []);

  return productData ? (
    <HeroTextToutWithList
      options={{
        theme: "dark",
        box: "dark",
        listItems: 3,
        imageMinWidth: options.imageMinWidth,
        primaryTextAlign: "center",
        secondaryTextAlign: "center",
        padding: "large-both", //default, large-both, small-both, large-top, large-bottom, small-both, small-top, small-bottom
      }}
      data={productData}
    />
  ) : null;
}
