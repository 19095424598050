import _extend from "lodash/extend";
import { solutionsData } from "../Services/DataService";
const SUCCESS_SUFFIX = "_SUCCESS";
const LIST_SOLUTIONS = "LIST_SOLUTIONS";
const GET_SOLUTION = "GET_SOLUTION";

const initialState = [];

// eslint-disable-next-line
export default (state = initialState, action) => {
  switch (action.type) {
    case LIST_SOLUTIONS + SUCCESS_SUFFIX:
      return action.payload.data;

    case GET_SOLUTION + SUCCESS_SUFFIX:
      return action.payload.data;

    default:
      return state;
  }
};

export const allSolutions = () => {
  return (dispatch) => {
    dispatch({
      type: LIST_SOLUTIONS + SUCCESS_SUFFIX,
      payload: { data: solutionsData },
    });
  };
};

export const setActiveSolution = (slug) => {
  return (dispatch) => {
    dispatch({
      type: GET_SOLUTION + SUCCESS_SUFFIX,
      payload: { data: solutionsData.filter((item) => item.slug === slug) },
    });
  };
};
