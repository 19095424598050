import React from "react";

export default function FirstSection() {
  return (
    <div className="company-page-firstSection grid-container">
      <div className="company-page-firstSection--left col-span-full lg:col-span-4 lg:col-start-1 row-start-1 row-span-4 grid grid-rows-4 grid-cols-4">
        <div className="col-span-3 lg:col-start-2"></div>
        <div className="col-span-full lg:col-span-3 lg:col-start-2 row-span-3">
          <div className="w-full h-full flex flex-col justify-between">
            <div className="bg-teal w-2 h-2 lg:w-4 lg:h-4"></div>
            <div>
              <h1>
                Automates <br /> Content <br />{" "}
                <span style={{ color: "#47ccdf" }}>Localization</span>
              </h1>
            </div>

            <h2 className="subheading">
              Advancing the practice of localization.
            </h2>
          </div>
        </div>
      </div>
      <div className="company-page-firstSection--right col-span-full lg:col-span-4 row-span-4 hidden md:grid grid-rows-8 grid-cols-8 ">
        <div className="col-span-6 row-span-6 grid grid-cols-6 grid-rows-6 row-start-2">
          <div className="col-span-2 row-span-2">
            <div className="left-top">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="100%"
                height="100%"
                viewBox="0 0 200 200"
              >
                <g>
                  <path
                    fill="rgb(255,255,255)"
                    fillRule="evenodd"
                    d="M116.50485437 116.50485437h5.82524272v5.82524272h-5.82524272v-5.82524272z"
                  />
                  <path
                    fill="rgb(255,255,255)"
                    fillRule="evenodd"
                    d="M77.66990291 116.50485437h5.82524272v5.82524272h-5.82524272v-5.82524272z"
                  />
                  <path
                    fill="rgb(255,255,255)"
                    fillRule="evenodd"
                    d="M77.66990291 77.66990291h5.82524272v5.82524272h-5.82524272v-5.82524272z"
                  />
                  <path
                    fill="rgb(255,255,255)"
                    fillRule="evenodd"
                    d="M116.50485437 77.66990291h5.82524272v5.82524272h-5.82524272v-5.82524272z"
                  />
                  <path
                    fill="rgb(255,255,255)"
                    fillRule="evenodd"
                    d="M155.33980583 77.66990291h5.82524271v5.82524272h-5.82524271v-5.82524272z"
                  />
                  <path
                    fill="rgb(255,255,255)"
                    fillRule="evenodd"
                    d="M155.33980583 116.50485437h5.82524271v5.82524272h-5.82524271v-5.82524272z"
                  />
                  <path
                    fill="rgb(255,255,255)"
                    fillRule="evenodd"
                    d="M155.33980583 155.33980583h5.82524271v5.82524271h-5.82524271v-5.82524271z"
                  />
                  <path
                    fill="rgb(255,255,255)"
                    fillRule="evenodd"
                    d="M116.50485437 155.33980583h5.82524272v5.82524271h-5.82524272v-5.82524271z"
                  />
                  <path
                    fill="rgb(255,255,255)"
                    fillRule="evenodd"
                    d="M77.66990291 155.33980583h5.82524272v5.82524271h-5.82524272v-5.82524271z"
                  />
                  <path
                    fill="rgb(255,255,255)"
                    fillRule="evenodd"
                    d="M38.83495146 155.33980583h5.82524271v5.82524271h-5.82524271v-5.82524271z"
                  />
                  <path
                    fill="rgb(255,255,255)"
                    fillRule="evenodd"
                    d="M38.83495146 116.50485437h5.82524271v5.82524272h-5.82524271v-5.82524272z"
                  />
                  <path
                    fill="rgb(255,255,255)"
                    fillRule="evenodd"
                    d="M38.83495146 77.66990291h5.82524271v5.82524272h-5.82524271v-5.82524272z"
                  />
                  <path
                    fill="rgb(255,255,255)"
                    fillRule="evenodd"
                    d="M38.83495146 38.83495146h5.82524271v5.82524271h-5.82524271v-5.82524271z"
                  />
                  <path
                    fill="rgb(255,255,255)"
                    fillRule="evenodd"
                    d="M77.66990291 38.83495146h5.82524272v5.82524271h-5.82524272v-5.82524271z"
                  />
                  <path
                    fill="rgb(255,255,255)"
                    fillRule="evenodd"
                    d="M116.50485437 38.83495146h5.82524272v5.82524271h-5.82524272v-5.82524271z"
                  />
                  <path
                    fill="rgb(255,255,255)"
                    fillRule="evenodd"
                    d="M155.33980583 38.83495146h5.82524271v5.82524271h-5.82524271v-5.82524271z"
                  />
                  <path
                    fill="rgb(255,255,255)"
                    fillRule="evenodd"
                    d="M194.17475728 38.83495146H200v5.82524271h-5.82524272v-5.82524271z"
                  />
                  <path
                    fill="rgb(255,255,255)"
                    fillRule="evenodd"
                    d="M194.17475728 77.66990291H200v5.82524272h-5.82524272v-5.82524272z"
                  />
                  <path
                    fill="rgb(255,255,255)"
                    fillRule="evenodd"
                    d="M194.17475728 116.50485437H200v5.82524272h-5.82524272v-5.82524272z"
                  />
                  <path
                    fill="rgb(255,255,255)"
                    fillRule="evenodd"
                    d="M194.17475728 155.33980583H200v5.82524271h-5.82524272v-5.82524271z"
                  />
                  <path
                    fill="rgb(255,255,255)"
                    fillRule="evenodd"
                    d="M194.17475728 194.17475728H200V200h-5.82524272v-5.82524272z"
                  />
                  <path
                    fill="rgb(255,255,255)"
                    fillRule="evenodd"
                    d="M155.33980583 194.17475728h5.82524271V200h-5.82524271v-5.82524272z"
                  />
                  <path
                    fill="rgb(255,255,255)"
                    fillRule="evenodd"
                    d="M116.50485437 194.17475728h5.82524272V200h-5.82524272v-5.82524272z"
                  />
                  <path
                    fill="rgb(255,255,255)"
                    fillRule="evenodd"
                    d="M77.66990291 194.17475728h5.82524272V200h-5.82524272v-5.82524272z"
                  />
                  <path
                    fill="rgb(255,255,255)"
                    fillRule="evenodd"
                    d="M38.83495146 194.17475728h5.82524271V200h-5.82524271v-5.82524272z"
                  />
                  <path
                    fill="rgb(255,255,255)"
                    fillRule="evenodd"
                    d="M0 194.17475728h5.82524272V200H0v-5.82524272z"
                  />
                  <path
                    fill="rgb(255,255,255)"
                    fillRule="evenodd"
                    d="M0 155.33980583h5.82524272v5.82524271H0v-5.82524271z"
                  />
                  <path
                    fill="rgb(255,255,255)"
                    fillRule="evenodd"
                    d="M0 116.50485437h5.82524272v5.82524272H0v-5.82524272z"
                  />
                  <path
                    fill="rgb(255,255,255)"
                    fillRule="evenodd"
                    d="M0 77.66990291h5.82524272v5.82524272H0v-5.82524272z"
                  />
                  <path
                    fill="rgb(255,255,255)"
                    fillRule="evenodd"
                    d="M0 38.83495146h5.82524272v5.82524271H0v-5.82524271z"
                  />
                  <path
                    fill="rgb(255,255,255)"
                    fillRule="evenodd"
                    d="M0 0h5.82524272v5.82524272H0V0z"
                  />
                  <path
                    fill="rgb(255,255,255)"
                    fillRule="evenodd"
                    d="M38.83495146 0h5.82524271v5.82524272h-5.82524271V0z"
                  />
                  <path
                    fill="rgb(255,255,255)"
                    fillRule="evenodd"
                    d="M77.66990291 0h5.82524272v5.82524272h-5.82524272V0z"
                  />
                  <path
                    fill="rgb(255,255,255)"
                    fillRule="evenodd"
                    d="M116.50485437 0h5.82524272v5.82524272h-5.82524272V0z"
                  />
                  <path
                    fill="rgb(255,255,255)"
                    fillRule="evenodd"
                    d="M155.33980583 0h5.82524271v5.82524272h-5.82524271V0z"
                  />
                  <path
                    fill="rgb(255,255,255)"
                    fillRule="evenodd"
                    d="M194.17475728 0H200v5.82524272h-5.82524272V0z"
                  />
                </g>
              </svg>
            </div>
          </div>
          <div className="col-span-2 row-span-2 col-start-5">
            <div className="right-top"></div>
          </div>
          <div className="col-start-3 row-start-3 col-span-2 row-span-2">
            <div className="logo">
              <div className="logo-filled-square"></div>
              <div className="logo-border-square"></div>
              <svg
                width="100%"
                height="100%"
                viewBox="0 0 20 20"
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
              >
                <title>logo-t</title>
                <g
                  id="Page-1"
                  stroke="none"
                  stroke-width="1"
                  fill="none"
                  fill-rule="evenodd"
                >
                  <g id="logo-t" fill="#47CCDF" fill-rule="nonzero">
                    <path
                      d="M15,0 L15,5 L20,5 L20,20 L0,20 L0,0 L15,0 Z M10,5 L5,5 L5,15 L15,15 L15,10 L10,10 L10,5 Z"
                      id="Combined-Shape"
                    ></path>
                  </g>
                </g>
              </svg>
            </div>
          </div>
          <div className=" col-span-2 row-span-2 row-start-5">
            <div className="left-bottom">
              <div className="left-bottom--filled-square"></div>
            </div>
          </div>
          <div className="col-span-2 row-span-2 row-start-5 col-start-5">
            <div className="right-bottom">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="100%"
                height="100%"
                viewBox="0 0 200 200"
              >
                <g>
                  <path
                    fill="rgb(255,255,255)"
                    fillRule="evenodd"
                    d="M116.50485437 116.50485437h5.82524272v5.82524272h-5.82524272v-5.82524272z"
                  />
                  <path
                    fill="rgb(255,255,255)"
                    fillRule="evenodd"
                    d="M77.66990291 116.50485437h5.82524272v5.82524272h-5.82524272v-5.82524272z"
                  />
                  <path
                    fill="rgb(255,255,255)"
                    fillRule="evenodd"
                    d="M77.66990291 77.66990291h5.82524272v5.82524272h-5.82524272v-5.82524272z"
                  />
                  <path
                    fill="rgb(255,255,255)"
                    fillRule="evenodd"
                    d="M116.50485437 77.66990291h5.82524272v5.82524272h-5.82524272v-5.82524272z"
                  />
                  <path
                    fill="rgb(255,255,255)"
                    fillRule="evenodd"
                    d="M155.33980583 77.66990291h5.82524271v5.82524272h-5.82524271v-5.82524272z"
                  />
                  <path
                    fill="rgb(255,255,255)"
                    fillRule="evenodd"
                    d="M155.33980583 116.50485437h5.82524271v5.82524272h-5.82524271v-5.82524272z"
                  />
                  <path
                    fill="rgb(255,255,255)"
                    fillRule="evenodd"
                    d="M155.33980583 155.33980583h5.82524271v5.82524271h-5.82524271v-5.82524271z"
                  />
                  <path
                    fill="rgb(255,255,255)"
                    fillRule="evenodd"
                    d="M116.50485437 155.33980583h5.82524272v5.82524271h-5.82524272v-5.82524271z"
                  />
                  <path
                    fill="rgb(255,255,255)"
                    fillRule="evenodd"
                    d="M77.66990291 155.33980583h5.82524272v5.82524271h-5.82524272v-5.82524271z"
                  />
                  <path
                    fill="rgb(255,255,255)"
                    fillRule="evenodd"
                    d="M38.83495146 155.33980583h5.82524271v5.82524271h-5.82524271v-5.82524271z"
                  />
                  <path
                    fill="rgb(255,255,255)"
                    fillRule="evenodd"
                    d="M38.83495146 116.50485437h5.82524271v5.82524272h-5.82524271v-5.82524272z"
                  />
                  <path
                    fill="rgb(255,255,255)"
                    fillRule="evenodd"
                    d="M38.83495146 77.66990291h5.82524271v5.82524272h-5.82524271v-5.82524272z"
                  />
                  <path
                    fill="rgb(255,255,255)"
                    fillRule="evenodd"
                    d="M38.83495146 38.83495146h5.82524271v5.82524271h-5.82524271v-5.82524271z"
                  />
                  <path
                    fill="rgb(255,255,255)"
                    fillRule="evenodd"
                    d="M77.66990291 38.83495146h5.82524272v5.82524271h-5.82524272v-5.82524271z"
                  />
                  <path
                    fill="rgb(255,255,255)"
                    fillRule="evenodd"
                    d="M116.50485437 38.83495146h5.82524272v5.82524271h-5.82524272v-5.82524271z"
                  />
                  <path
                    fill="rgb(255,255,255)"
                    fillRule="evenodd"
                    d="M155.33980583 38.83495146h5.82524271v5.82524271h-5.82524271v-5.82524271z"
                  />
                  <path
                    fill="rgb(255,255,255)"
                    fillRule="evenodd"
                    d="M194.17475728 38.83495146H200v5.82524271h-5.82524272v-5.82524271z"
                  />
                  <path
                    fill="rgb(255,255,255)"
                    fillRule="evenodd"
                    d="M194.17475728 77.66990291H200v5.82524272h-5.82524272v-5.82524272z"
                  />
                  <path
                    fill="rgb(255,255,255)"
                    fillRule="evenodd"
                    d="M194.17475728 116.50485437H200v5.82524272h-5.82524272v-5.82524272z"
                  />
                  <path
                    fill="rgb(255,255,255)"
                    fillRule="evenodd"
                    d="M194.17475728 155.33980583H200v5.82524271h-5.82524272v-5.82524271z"
                  />
                  <path
                    fill="rgb(255,255,255)"
                    fillRule="evenodd"
                    d="M194.17475728 194.17475728H200V200h-5.82524272v-5.82524272z"
                  />
                  <path
                    fill="rgb(255,255,255)"
                    fillRule="evenodd"
                    d="M155.33980583 194.17475728h5.82524271V200h-5.82524271v-5.82524272z"
                  />
                  <path
                    fill="rgb(255,255,255)"
                    fillRule="evenodd"
                    d="M116.50485437 194.17475728h5.82524272V200h-5.82524272v-5.82524272z"
                  />
                  <path
                    fill="rgb(255,255,255)"
                    fillRule="evenodd"
                    d="M77.66990291 194.17475728h5.82524272V200h-5.82524272v-5.82524272z"
                  />
                  <path
                    fill="rgb(255,255,255)"
                    fillRule="evenodd"
                    d="M38.83495146 194.17475728h5.82524271V200h-5.82524271v-5.82524272z"
                  />
                  <path
                    fill="rgb(255,255,255)"
                    fillRule="evenodd"
                    d="M0 194.17475728h5.82524272V200H0v-5.82524272z"
                  />
                  <path
                    fill="rgb(255,255,255)"
                    fillRule="evenodd"
                    d="M0 155.33980583h5.82524272v5.82524271H0v-5.82524271z"
                  />
                  <path
                    fill="rgb(255,255,255)"
                    fillRule="evenodd"
                    d="M0 116.50485437h5.82524272v5.82524272H0v-5.82524272z"
                  />
                  <path
                    fill="rgb(255,255,255)"
                    fillRule="evenodd"
                    d="M0 77.66990291h5.82524272v5.82524272H0v-5.82524272z"
                  />
                  <path
                    fill="rgb(255,255,255)"
                    fillRule="evenodd"
                    d="M0 38.83495146h5.82524272v5.82524271H0v-5.82524271z"
                  />
                  <path
                    fill="rgb(255,255,255)"
                    fillRule="evenodd"
                    d="M0 0h5.82524272v5.82524272H0V0z"
                  />
                  <path
                    fill="rgb(255,255,255)"
                    fillRule="evenodd"
                    d="M38.83495146 0h5.82524271v5.82524272h-5.82524271V0z"
                  />
                  <path
                    fill="rgb(255,255,255)"
                    fillRule="evenodd"
                    d="M77.66990291 0h5.82524272v5.82524272h-5.82524272V0z"
                  />
                  <path
                    fill="rgb(255,255,255)"
                    fillRule="evenodd"
                    d="M116.50485437 0h5.82524272v5.82524272h-5.82524272V0z"
                  />
                  <path
                    fill="rgb(255,255,255)"
                    fillRule="evenodd"
                    d="M155.33980583 0h5.82524271v5.82524272h-5.82524271V0z"
                  />
                  <path
                    fill="rgb(255,255,255)"
                    fillRule="evenodd"
                    d="M194.17475728 0H200v5.82524272h-5.82524272V0z"
                  />
                </g>
              </svg>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
