import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import logo from "../../Assets/Icons/logo-t-black.svg";
import { ReactComponent as Logo } from "../../Assets/Icons/logo-t.svg";
import "./index.scss";

export default function API() {
  useEffect(() => {
    window.location.href = "https://docs.lugath.com";
  });
  return null;
}
