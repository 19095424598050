import { v4 as uuidv4 } from "uuid";

export const videos = [
  {
    id: uuidv4(),
    categoryName: "Getting started",
    categorySlug: "Getting started",
    items: [
      {
        videoName: "3 Minutes Demo",
        videoSlug: "3 Minutes Demo",
        videoURL: "https://www.youtube.com/watch?v=P5Wkuop1ebE",
      },
    ],
  },
  {
    id: uuidv4(),
    categoryName: "Intelligent MT Hub",
    categorySlug: "Intelligent MT Hub",
    items: [
      {
        videoName: "How to translate your content with suggested MT engines?",
        videoSlug: "How to translate your content with suggested MT engines?",
        videoURL: "https://www.youtube.com/watch?v=aP2RDuOgu90",
      },
      {
        videoName: "How to translate your content with single MT Engine?",
        videoSlug: "How to translate your content with single MT Engine?",
        videoURL: "https://www.youtube.com/watch?v=RdN3e-sd5Zo",
      },
    ],
  },
  {
    id: uuidv4(),
    categoryName: "Glossary",
    categorySlug: "Glossary",
    items: [
      {
        videoName: "How to import your terms as bulk to glossary?",
        videoSlug: "How to import your terms as bulk to glossary?",
        videoURL: "https://www.youtube.com/watch?v=tqrDI7ZDFCk",
      },
      {
        videoName: "How to add a term to glossary and get export?",
        videoSlug: "How to add a term to glossary and get export?",
        videoURL: "https://www.youtube.com/watch?v=BuEpCsPAgik",
      },
      {
        videoName: "How to translate your content with glossary?",
        videoSlug: "How to translate your content with glossary?",
        videoURL: "https://www.youtube.com/watch?v=6WowdjE0qeg",
      },
    ],
  },
  {
    id: uuidv4(),
    categoryName: "Translation Memory",
    categorySlug: "Translation Memory",
    items: [
      {
        videoName: "How to create an automated translation memory?",
        videoSlug: "How to create an automated translation memory?",
        videoURL: "https://www.youtube.com/watch?v=L4R9jmHr7xg",
      },
      {
        videoName: "How to translate your content with translation memory?",
        videoSlug: "How to translate your content with translation memory?",
        videoURL: "https://www.youtube.com/watch?v=li6qYZg8KmA",
      },
    ],
  },
  {
    id: uuidv4(),
    categoryName: "Project Management",
    categorySlug: "Project Management",
    items: [
      {
        videoName: "How to manage localization workflow?",
        videoSlug: "How to manage localization workflow?",
        videoURL: "https://www.youtube.com/watch?v=JVIit3yVd2c",
      },
      {
        videoName: "How to share your content and notify contributor?",
        videoSlug: "How to share your content and notify contributor?",
        videoURL: "https://www.youtube.com/watch?v=5uMJK5UIUmY",
      },
      {
        videoName: "How to make post-edit or proofread?",
        videoSlug: "How to make post-edit or proofread?",
        videoURL: "https://www.youtube.com/watch?v=99cETQNs1bI",
      },
      {
        videoName: "How to review translated content?",
        videoSlug: "How to review translated content?",
        videoURL: "https://www.youtube.com/watch?v=hw-0ZSjKpKU",
      },
    ],
  },
  {
    id: uuidv4(),
    categoryName: "API Usages",
    categorySlug: "API Usages",
    items: [
      {
        videoName: "How to get your API keys?",
        videoSlug: "How to get your API keys?",
        videoURL: "https://www.youtube.com/watch?v=5ogZfI1xOb8",
      },
      {
        videoName: "How to test translation requests with Postman?",
        videoSlug: "How to test translation requests with Postman?",
        videoURL: "https://www.youtube.com/watch?v=XdtbMEEeqJA",
      },
      {
        videoName: "How to monitor API usages?",
        videoSlug: "How to monitor API usages?",
        videoURL: "https://www.youtube.com/watch?v=KkmTEN-HlTU",
      },
    ],
  },
  {
    id: uuidv4(),
    categoryName: "Users and Team Management",
    categorySlug: "Users and Team Management",
    items: [
      {
        videoName: "How to manage your users and team?",
        videoSlug: "How to manage your users and team?",
        videoURL: "https://www.youtube.com/watch?v=Cy3OuHueWFs",
      },
    ],
  },
  {
    id: uuidv4(),
    categoryName: "Membership Settings",
    categorySlug: "Membership Settings",
    items: [
      {
        videoName: "How to subscribe a plan?",
        videoSlug: "How to subscribe a plan?",
        videoURL: "https://www.youtube.com/watch?v=eQXiGa5tC1c",
      },
      {
        videoName: "How to update your billing settings?",
        videoSlug: "How to update your billing settings?",
        videoURL: "https://www.youtube.com/watch?v=F5nEhO8TGPA",
      },
      {
        videoName: "How to download your invoices?",
        videoSlug: "How to download your invoices?",
        videoURL: "https://www.youtube.com/watch?v=i6HD2QG-D3E",
      },
    ],
  },
  {
    id: uuidv4(),
    categoryName: "Support",
    categorySlug: "Support",
    items: [
      {
        videoName: "How to create support ticket?",
        videoSlug: "How to create support ticket?",
        videoURL: "https://www.youtube.com/watch?v=QOKkG5Hb61M",
      },
    ],
  },
  {
    id: uuidv4(),
    categoryName: "App Marketplace Integration",
    categorySlug: "App Marketplace Integration",
    items: [
      {
        videoName: "How to connect your Dropbox account?",
        videoSlug: "How to connect your Dropbox account?",
        videoURL: "https://www.youtube.com/watch?v=C2ggjOix9rw",
      },
    ],
  },
];
