import React, { useState } from "react";
import { Link } from "react-router-dom";
import { ReactComponent as ShapeCoil } from "../../Assets/Icons/Shapes/shape-coil.svg";
import { ReactComponent as ShapeDots } from "../../Assets/Icons/Shapes/shape-dots.svg";
import { ReactComponent as ShapeBulp } from "../../Assets/Icons/Shapes/shape-light-bulp.svg";
import { ReactComponent as ShapeArrowRight } from "../../Assets/Icons/Shapes/shape-arrow-right.svg";
import "./index.scss";

export default function Shaper() {
  return (
    <>
      <div className="shaper-wrapper">
        <div className="shaper-inner">
          <div className="shaper">
            <ul className="shaper__list">
              <li className="shaper__list-item bg-teal">
                <ShapeCoil />
              </li>
              <li className="shaper__list-item circle bg-yellow ">
                <ShapeBulp />
              </li>
              <li className="shaper__list-item bg-orange wf">
                <ShapeDots />
              </li>
              <li className="shaper__list-item">Intelligent</li>
              <li className="shaper__list-item circle">
                <ShapeArrowRight />
              </li>
              <li className="shaper__list-item">support</li>
              <li className="shaper__list-item hl bg-black text-white px-10">
                wih AI
              </li>
              <li className="shaper__list-item">toggle</li>
              <li className="shaper__list-item">shapes</li>
              <li className="shaper__list-item">arrows</li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
}
