import React from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Helmet } from "react-helmet";
import HeroTextToutWithList from "../../Components/TextToutWithList";

import FirstSection from "../../Components/HomePageComponents/FirstSection/";
import { ReactComponent as ArrowBackward } from "../../Assets/Icons/arrow-backward.svg";
import SecondSection from "../../Components/HomePageComponents/SecondSection";
import ThirdSection from "../../Components/HomePageComponents/ThirdSection";
import FourthSection from "../../Components/HomePageComponents/FourthSection";
import CustomerLogos from "../../Components/HomePageComponents/CustomerLogos";
import { useDispatch } from "react-redux";
import { allProducts } from "../../Reducers/products";
import { allSolutions } from "../../Reducers/solutions";
import { allNews } from "../../Reducers/news";
import Testimonials from "../../Components/Testimonials";
import Solutions from "../../Components/HomePageComponents/Solutions";
import Integrations from "../../Components/Integrations";
import Security from "../../Components/HomePageComponents/Security";
import ProductList from "../../Components/ProductList";
import { profilesData } from "../../Services/DataService";

import "./home.scss";
const solutionModuleData = {
  moduleName: "HeroTextToutWithList",
  data: {
    features: [
      {
        image: "Assets/Icons/feature-web.svg",
        title: "Website",
        description:
          "Any kind of web app. Frontend, backend, single page application, blogs, you name it.",
      },
      {
        image: "Assets/Icons/feature-mobile.svg",
        title: "Mobile App",
        description:
          "Localize your app for every platform using your preferred file format and speed up publishing with SDKs.",
      },
      {
        image: "Assets/Icons/feature-game.svg",
        title: "Game",
        description:
          "Simplify the process of bringing your game to a global audience while maintaining gameplay excellence.",
      },
      {
        image: "Assets/Icons/web_stories_black_24dp.svg",
        title: "Marketing Assets",
        description:
          "Reach and convert more prospects with translated web pages, blogs, emails, and other marketing assets.",
      },
      {
        image: "Assets/Icons/product-document-translation.svg",
        title: "Office Documents",
        description:
          "Upload, translate, and download your documents, including Word, Excel, or PowerPoint, and more.",
      },
      {
        image: "Assets/Icons/user-guide_black_24dp.svg",
        title: "User Guides",
        description:
          "Translate thousands of page user manuals of your products in seconds.",
      },
    ],
    cta: [
      {
        label: "Try For Free 14 Days",
        action: "register",
      },
    ],
  },
  options: {
    theme: "cyan",
    box: "cyan",
    listItems: 3,
    padding: "small-top", //default, large-both, small-both, large-top, large-bottom, small-both, small-top, small-bottom
  },
};

//import { useTranslation } from "react-i18next";

export default function Home(props) {
  //const { t, i18n } = useTranslation("common");
  const dispatch = useDispatch();

  React.useEffect(() => {
    Promise.all([
      dispatch(allProducts()),
      dispatch(allNews()),
      dispatch(allSolutions()),
    ]);
  }, [dispatch]);

  return (
    <>
      <div className="homepage">
        <FirstSection></FirstSection>
        <div className="shadow-anchor"></div>
        {/*<SecondSection></SecondSection>*/}

        <section className="homepage-solutions__cover js-section_nav-dark">
          <div className="container mx-auto">
            <div className="parent">
              <div className="div1">
                <div className="homepage-solutions__cover__title">
                  What you can localize with{" "}
                  <span className="shine"> lugath?</span>
                </div>
              </div>
              <div className="div2">
                <div className="homepage-solutions__cover__content">
                  <div className="homepage-solutions__cover__content-desc">
                    Create continuous localization workflow and localize your
                    content to support the global scaling of your products or
                    services with the best content fit AI tools.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <HeroTextToutWithList
          options={solutionModuleData.options}
          data={solutionModuleData.data}
        />
        <section className="homepage-solutions__cover homepage-solutions__cover--light js-section_nav-dark">
          <div className="container mx-auto">
            <div className="parent">
              <div className="div1">
                <div className="homepage-solutions__cover__title">
                  Who is for <br />
                  <span className="shine"> lugath?</span>
                </div>
              </div>
              <div className="div2">
                <div className="homepage-solutions__cover__content">
                  <div className="homepage-solutions__cover__content-desc">
                    Comprehensive suite of tools to help you manage localization
                    from end-to-end. Our solutions for developers, managers,
                    translators, designers, enterprises and customer services.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <HeroTextToutWithList
          style={{ borderTop: "0px", paddingBottom: "200px" }}
          options={{
            theme: "light",
            box: "light",
            listItems: 3,
            padding: "small-top", //default, large-both, small-both, large-top, large-bottom, small-both, small-top, small-bottom
          }}
          data={{
            features: profilesData,
          }}
        />

        <ProductList
          data={{
            title: (
              <>
                <b>All the tools you need,</b> <br />
                All-in-one place
              </>
            ),
            description: (
              <>
                Discover the latest technologies powered by AI to maximize
                efficiency in your translation and localization workflow.
              </>
            ),
          }}
          options={{ shape: "icon", imageMinWidth: "32px" }}
        />
        {/*<Security />*/}
        <Testimonials
          data={{
            testimonials: [
              {
                id: 0,
                author: "Sebastian Sandler",
                title: "Technical Documentation Manager",
                brand: "SIEMENS",
                rating: 4,
                image: "Assets/Images/Testimonials/image.png",
                testimonial:
                  "Siemens AG successfully implemented an industry 4.0-compliant automated translation workflow, leading to significant reductions in time and resources required for technical document translation.",
              },
              {
                id: 2,
                author: "Neslihan Kalafatoglu",
                title: "Senior B2B Marketing Specialist",
                brand: "VESTEL",
                rating: 5,
                image: "Assets/Images/Testimonials/image.png",
                testimonial:
                  "With the facilitation of communication between the marketing team and the software development team, the localization workflow has been further accelerated.",
              },
              {
                id: 3,
                author: "Berk Cakmak",
                title: "E-Commerce Manager",
                brand: "ETSTUR",
                rating: 5,
                image: "Assets/Images/Testimonials/image.png",
                testimonial:
                  "Lugath's Intelligent MT Hub provides a single integration point for accessing all systems. Plus, it helps identify which system performs best for specific languages and industries.",
              },
              {
                id: 1,
                author: "Maria Claudia Lo Porto",
                title: "Freelance Translator",
                brand: "TRANSLATOR",
                rating: 4,
                image: "Assets/Images/Testimonials/image.png",
                testimonial:
                  " Lugath's online CAT is definitely easy to use and quite helpful, I am using it regularly.",
              },
              {
                id: 4,
                author: "Aybars Yagiz",
                title:
                  "Assistant Managing Director – Legal and Corporate Communications",
                brand: "GAMA ENERGY",
                rating: 4,
                image: "Assets/Images/Testimonials/image.png",
                testimonial:
                  "When we use Lugath to translate our agreements beforehand, it makes translation easier, and we can focus mainly on proofreading these documents.",
              },
            ],
          }}
        />
        <Integrations />
      </div>
    </>
  );
}
