import React, { useState } from "react";
import { Link } from "react-router-dom";
import { ReactComponent as ArrowOutward } from "../../Assets/Icons/arrow_outward.svg";
import Image from "../Image";
import "./index.scss";

export default function HeroTextToutWithList({
  options = {
    theme: "dark",
    listItems: 4,
    box: "default",
    padding: "default",
    primaryTextAlign: "left",
    secondaryTextAlign: "left",
  },
  data,
  style,
}) {
  return (
    <section
      style={style}
      className={`hero-text-tout-with-list-bg hero-text-tout-with-list-bg--${
        options.padding
      } hero-text-tout-with-list-bg--${options.theme} ${
        options.theme === "light" || options.theme === "cyan"
          ? "js-section_nav-dark"
          : ""
      }`}
    >
      <div className="container mx-auto">
        <div className="hero-text-tout-with-list__wrapper">
          <div className="hero-text-tout-with-list__title">
            {data?.primaryTitle ? (
              <div
                className={`hero-text-tout-with-list__title-primary text-${options.primaryTextAlign}`}
                dangerouslySetInnerHTML={
                  typeof data.primaryTitle === "string"
                    ? { __html: data.primaryTitle }
                    : null
                }
              >
                {typeof data.primaryTitle === "object"
                  ? data.primaryTitle
                  : null}
              </div>
            ) : null}
            {data?.secondaryTitle ? (
              <div
                className={`hero-text-tout-with-list__title-secondary text-${options.primaryTextAlign}`}
                dangerouslySetInnerHTML={
                  typeof data.secondaryTitle === "string"
                    ? { __html: data.secondaryTitle }
                    : null
                }
              >
                {typeof data.secondaryTitle === "object"
                  ? data.secondaryTitle
                  : null}
              </div>
            ) : null}
          </div>
          <div className="hero-text-tout-with-list__content">
            <div className="hero-text-tout-with-list__content-list__wrapper">
              <div
                data-column={options.listItems}
                className="hero-text-tout-with-list__content-list"
              >
                {data?.features
                  ? data.features.map((f, i) => (
                      <div
                        key={`feature-key-${i}`}
                        className={`hero-text-tout-with-list__content-list__item hero-text-tout-with-list__content-list__item--${
                          options?.box ? options.box : ""
                        }`}
                      >
                        {f?.image ? (
                          <div className="hero-text-tout-with-list__content-list__item-image">
                            <Image
                              style={
                                options?.imageMinWidth
                                  ? { minWidth: options?.imageMinWidth }
                                  : {}
                              }
                              fileName={f.image}
                              width={64}
                              height={64}
                            />
                          </div>
                        ) : null}
                        <div className="hero-text-tout-with-list__content-list__item-title">
                          {f.title}
                        </div>
                        <div className="hero-text-tout-with-list__content-list__item-desc">
                          {f.description}
                        </div>
                        {f.link ? (
                          <Link
                            className="hero-text-tout-with-list__content-list__item-link"
                            to={f.link}
                          >
                            <span className="label">Discover</span>
                            <span className="icon">
                              <ArrowOutward />
                            </span>
                          </Link>
                        ) : null}
                      </div>
                    ))
                  : null}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
