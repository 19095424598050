import React, { useEffect, useState } from "react";
//import anime from "animejs/lib/anime.es.js";
//import randomColor from "randomcolor";
import { Link } from "react-router-dom";

import "./index.scss";

export default function Gridder() {
  const [gridState, setGridState] = useState({
    columns: 0,
    rows: 0,
    total: 1,
  });

  //const colors = ["#5ECB88", "#EB76B7", "#282828", "#47CCDF", "#4375F6"];
  const colors = [
    "94,203,136",
    "235,118,183",
    "40,40,40",
    "71,204,223",
    "67,117,246",
  ];

  const handleStagger = (i) => {
    const { columns, rows } = gridState;
    const el = i.target.id;
    /*anime({
      targets: ".grid-item",
      backgroundColor: `rgba(${
        colors[Math.floor(Math.random() * colors.length)]
      },0.5)`,
      delay: anime.stagger(25, { grid: [columns, rows], from: el }),
    });*/
  };

  const getGridSize = () => {
    const columns = Math.floor(document.body.clientWidth / 50);
    const rows = Math.floor(document.body.clientHeight / 50);

    setGridState({ columns, rows, total: rows * columns });
    /*anime({
      targets: ".grid-item",
      //backgroundColor: "#fff",
      duration: 0,
      easing: "linear",
    });*/
  };

  useEffect(() => {
    getGridSize();
    window.addEventListener("resize", getGridSize);

    return () => {
      window.removeEventListener("resize", getGridSize);
    };
  }, []);

  return (
    <>
      <div className="component-grid-wrapper">
        <div className="component-grid-inner">
          <div className="component-grid">
            {[...Array(gridState.total)].map((x, i) => (
              <div
                key={i}
                className="grid-item"
                id={i}
                onClick={(i) => handleStagger(i)}
              />
            ))}
          </div>
        </div>
      </div>
    </>
  );
}
