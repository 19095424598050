import React from "react";
import { useSelector } from "react-redux";
import SolutionCardNew from "../../Cards/SolutionCardNew";
import SolutionCard from "../../Cards/SolutionCard";
import "./index.scss";
export default function ThirdSection() {
  const products = useSelector((state) => state.products);

  return (
    <div className="bg-products">
      <div
        className="homepage-thirdSection container mx-auto"
        id="third-section_home"
      >
        <div className="homepage-thirdSection--heading">
          <h1 className="text-center">
            <span style={{ color: "#47ccdf" }}>All the tools you need. </span>
            All in one place!
          </h1>
          <h2 className="text-center pb-4">
            Discover the latest technologies powered by AI to maximize
            efficiency in your translation and localization workflow.
          </h2>
        </div>
        <div className="solutions-wrapper hidden md:inline-flex w-full">
          <div className="box-container">
            {/* box container */}
            {products
              .filter((item) => item.active === true)
              .map((item, index) => (
                <SolutionCardNew
                  item={item}
                  key={index + 1}
                  index={index + 1}
                />
              ))}
            {/*<SolutionCard item={{
            id: 9,
            title: "and more",
            linkTitle:"Read More",
            slug: "#",
            showNumber:false,
            disabled:true
          }} /> */}
            {/* boxes container end */}
          </div>
        </div>
        <div className="solutions-wrapper md:hidden w-full">
          <div className="box-container">
            {/* box container */}
            {products
              .filter((item) => item.active === true)
              .map((item, index) => (
                <SolutionCard item={item} key={index + 1} index={index + 1} />
              ))}
            {/* boxes container end */}
          </div>
        </div>
      </div>
    </div>
  );
}
