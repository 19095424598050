import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { ReactComponent as LugathLogo } from "../../../Assets/Icons/logo-t.svg";
import box1Image from "../../../Assets/Images/box-1.png";
import box2Image from "../../../Assets/Images/box-2.png";
import box3Image from "../../../Assets/Images/box-3.png";
import { ReactComponent as ShareIcon } from "../../../Assets/Icons/share.svg";
import { ReactComponent as ReviewIcon } from "../../../Assets/Icons/review.svg";
import { ReactComponent as TranslateIcon } from "../../../Assets/Icons/translate.svg";
import { ReactComponent as UploadIcon } from "../../../Assets/Icons/code.svg";
import { ReactComponent as PublishIcon } from "../../../Assets/Icons/publish.svg";
import featureImg2 from "../../../Assets/Images/feature-2.png";
import featureImg3 from "../../../Assets/Images/feature-3.png";
import Image from "../../Image";
import "./index.scss";
/*
alias :"share",
alias :"edit",
*/

const items = [
  {
    title: "Upload",
    desc: "Upload Your Documents on our platform.",
    index: 0,
    alias: "upload",
    background: "bg-white",
  },
  {
    title: "Create",
    desc: "Supply Your Content with a way.",
    index: 1,
    alias: "create",
    background: "bg-white",
  },
  {
    title: "Localize",
    desc: "Translate, Share, Review and do more.",
    index: 1,
    alias: "localize",
    background: "bg-cyan",
  },

  {
    title: "API",
    desc: "Supply your content with our APIs.",
    index: 1,
    alias: "api",
    background: "bg-white",
  },
  {
    title: "Apps",
    desc: "Integrate and reach files on lugath. Find and connect your application with our app marketplace.",
    index: 2,
    alias: "apps",
    background: "bg-white",
  },
  {
    title: "SDKs",
    desc: "Use our sdks and connect your application to lugath.",
    index: 3,
    alias: "sdk",
    background: "bg-white",
  },
  {
    title: "Pre-Translate with MT",
    desc: "Automatically Translate content with context for more accurate with AI.",
    index: 2,
    alias: "MT",
    background: "bg-cyan",
  },
  {
    title: "Review",
    desc: "Review the best possible results and it is now ready to use.",
    index: 2,
    alias: "review",
    background: "bg-cyan",
  },
  {
    title: "Smart Memory",
    desc: "Smart Memory will keep your content until you need.",
    index: 2,
    alias: "memory",
    background: "bg-black",
  },
  {
    title: "Publish",
    desc: "Now all you have to do is publish and continue.",
    index: 2,
    alias: "publish",
    background: "bg-black",
  },
  {
    title: "Share",
    desc: "Share with contributors if you need more with creative touches.",
    index: 2,
    alias: "share",
    background: "bg-cyan",
  },
  {
    title: "Continues Development Cycle",
    desc: "Your content in a continues loop.",
    index: 2,
    alias: "continues",
    background: "bg-black",
  },
  {
    title: "Edit",
    desc: "Localize the content provided.",
    index: 2,
    alias: "edit",
    background: "bg-cyan",
  },
];

export default function WorkflowScheme({ data, productName }) {
  const [showInfo, setShowInfo] = useState(false);
  const [activeItem, setActiveItem] = useState(false);
  const [position, setPosition] = useState({ x: 0, y: 0 });

  useEffect(() => {
    if (activeItem) {
      setShowInfo(true);
    } else {
      setShowInfo(false);
    }
  }, [activeItem]);

  const setVisibleItem = (alias) => {
    const findedItem = items.find((i) => i.alias === alias);
    if (findedItem) {
      setActiveItem(findedItem);
    }
  };

  const showVisibleItem = (alias, e) => {
    setPosition({ x: e.clientX, y: e.clientY });

    setVisibleItem(alias);
  };

  const hideVisibleItem = () => {
    setActiveItem(false);
  };

  useEffect(() => {}, [position]);

  return (
    <section className="workflow-scheme-bg js-section_nav-dark">
      <div className="container mx-auto workflow-scheme__container">
        <div className="workflow-scheme__wrapper">
          <div className="workflow-scheme__title-wrapper">
            <div className="workflow-scheme__title">
              Integrate localization into{" "}
              <span className="highlight">continuous</span> product{" "}
              <span className="highlight">development</span>
            </div>
          </div>
          <div className="workflow-scheme__inner">
            <div className="parent">
              <div
                className="box bg-white div1 image"
                onMouseEnter={(e) => showVisibleItem("upload", e)}
                onMouseLeave={() => hideVisibleItem()}
              >
                {" "}
                <span className="num">1</span>
                <Image
                  fileName="Assets/Icons/upload_file_black_24dp.svg"
                  width={64}
                  height={64}
                />{" "}
              </div>
              <div className="box bg-white div2 content no-action">
                <span className="num">2</span> Create
              </div>
              <div
                className="box bg-white div3 image"
                onMouseEnter={(e) => showVisibleItem("api", e)}
                onMouseLeave={() => hideVisibleItem()}
              >
                <span className="num">3</span>
                <Image
                  fileName="Assets/Icons/api_black_24dp.svg"
                  width={64}
                  height={64}
                />{" "}
              </div>
              <div
                className="box bg-white image div4"
                onMouseEnter={(e) => showVisibleItem("create", e)}
                onMouseLeave={() => hideVisibleItem()}
              >
                <span className="num">4</span>
                <Image
                  fileName="Assets/Icons/keyboard_double_arrow_down_black_24dp.svg"
                  width={64}
                  height={64}
                />{" "}
              </div>
              <div
                className="box bg-cyan image div5"
                onMouseEnter={(e) => showVisibleItem("localize", e)}
                onMouseLeave={() => hideVisibleItem()}
              >
                {" "}
                <span className="num">5</span>
                <Image
                  fileName="Assets/Icons/keyboard_double_arrow_down_black_24dp.svg"
                  width={64}
                  height={64}
                />{" "}
              </div>

              <div
                className="box bg-cyan image div6"
                onMouseEnter={(e) => showVisibleItem("MT", e)}
                onMouseLeave={() => hideVisibleItem()}
              >
                <span className="num">6</span>
                <Image
                  fileName="Assets/Icons/translate_black_24dp.svg"
                  width={64}
                  height={64}
                />{" "}
              </div>
              <div className="box bg-cyan div7 content no-action">
                <span className="num">7</span>localize{" "}
              </div>
              <div
                className="box bg-cyan image div8"
                onMouseEnter={(e) => showVisibleItem("review", e)}
                onMouseLeave={() => hideVisibleItem()}
              >
                <Image
                  fileName="Assets/Icons/review_eye_black_24dp.svg"
                  width={64}
                  height={64}
                />{" "}
                <span className="num">8</span>
              </div>
              <div
                className="box bg-black image inverted div9"
                onMouseEnter={(e) => showVisibleItem("memory", e)}
                onMouseLeave={() => hideVisibleItem()}
              >
                <span className="num">9</span>{" "}
                <Image
                  fileName="Assets/Icons/product-translation-memory.svg"
                  width={64}
                  height={64}
                />{" "}
              </div>

              <div className="box bg-black div10 content no-action">
                <span className="num">10</span>publish{" "}
              </div>
              <div
                className="box bg-black div11 image inverted"
                onMouseEnter={(e) => showVisibleItem("continues", e)}
                onMouseLeave={() => hideVisibleItem()}
              >
                <span className="num">11</span>
                <Image
                  fileName="Assets/Icons/keyboard_double_arrow_up_black_24dp.svg"
                  width={64}
                  height={64}
                />{" "}
              </div>
              <div
                className="box bg-white div12 image"
                onMouseEnter={(e) => showVisibleItem("sdk", e)}
                onMouseLeave={() => hideVisibleItem()}
              >
                <span className="num">12</span>{" "}
                <Image
                  fileName="Assets/Icons/code_black_24dp.svg"
                  width={64}
                  height={64}
                />{" "}
              </div>
              <div
                className="box bg-white div13 image"
                onMouseEnter={(e) => showVisibleItem("apps", e)}
                onMouseLeave={() => hideVisibleItem()}
              >
                <span className="num">13</span>
                <Image
                  fileName="Assets/Icons/apps_black_24dp.svg"
                  width={64}
                  height={64}
                />
              </div>
              <div
                className="box bg-cyan div14 image"
                onMouseEnter={(e) => showVisibleItem("share", e)}
                onMouseLeave={() => hideVisibleItem()}
              >
                <span className="num">14</span>
                <Image
                  fileName="Assets/Icons/supervisor_account_black_24dp.svg"
                  width={64}
                  height={64}
                />{" "}
              </div>
              <div
                className="box bg-cyan div15 image"
                onMouseEnter={(e) => showVisibleItem("edit", e)}
                onMouseLeave={() => hideVisibleItem()}
              >
                <span className="num">15</span>
                <Image
                  fileName="Assets/Icons/edit_black_24dp.svg"
                  width={64}
                  height={64}
                />{" "}
              </div>
              <div
                className="box bg-black image inverted div16"
                onMouseEnter={(e) => showVisibleItem("publish", e)}
                onMouseLeave={() => hideVisibleItem()}
              >
                <span className="num">16</span>
                <Image
                  fileName="Assets/Icons/keyboard_double_arrow_left_black_24dp.svg"
                  width={64}
                  height={64}
                />{" "}
              </div>
              <div className="box bg-black no-action div17">
                <span className="num">17</span>
              </div>
              <div
                style={{ left: position.x, top: position.y }}
                className={`info-box div18 ${showInfo ? "show" : ""} ${
                  activeItem?.background ? activeItem.background : ""
                }`}
              >
                <div className="info-box__inner">
                  <div className="info-box__title">{activeItem.title}</div>
                  <div className="info-box__description">{activeItem.desc}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
