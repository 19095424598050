export const news = [
  {
    "id": 1,
    "date": "21 Sep",
    "title": "Lugath accepted to Workup Incubation",
    "innerTitle": "",
    "firstBlock": "We use the perks provided within Workup program and getting new business connections. ",
    "secondBlock": "",
    "thirdBlock": "<br><strong>Throughout the program; support in trainings such as testing business models, market research and customer development, team building & management, sales and investment presentation preparation.</strong><br/><br/>",
    //"thirdBlock": "In the UBI Global index that compares and lists incubation centers, ITU ARI Teknokent's early stage incubation center ITU Çekirdek is in the top 5 in the world. <br></br> <br></br> <br></br> <strong> Lugath accepted to ITU Çekirdek; The 800m<sup>2</sup> open office located in ARI 3 building in Maslak continues to develop in its work office. It benefits from more than 300 Core mentor networks and develops its projects on canvas business model, financial models and market entry strategies with Entrepreneurship Training specially designed for entrepreneurs. </strong> <br/><br/>",
    //"ownerTitle": "<span>— Alastair Simpson, Head of UBI Global</span>",
    "lastHeading": "During the growth process of our venture, we benefit from the perks that suits our needs.",
    "lastContent": "We attend weekly meetings, where we follow the development process with Workup, and mentorship meetings that will contribute to our development as planned according to our needs. ",
    "slug":"lugath-acceptep-to-workup-incubation"
  },
  {
    "id": 2,
    "date": "06 Jan",
    "title": "Lugath is an ITU Cekirdek Pre-Incubation Initiative",
    "innerTitle": "Innovation through The Braintrust",
    "firstBlock": "Lugath is admitted to ITU Cekirdek Pre-Incubation program, launched by the partnership of Turkey’s entrepreneurship & innovation centre ITU ARI Teknokent and Let’s produce with technology platform which was founded to promote technology production in Turkey.",
    "secondBlock": "",
    "thirdBlock": "<br><strong>ITU ARI Teknokent is among the top 5 University Business Incubators according to the index published by UBI Global World Rankings of Business Incubators.</strong><br/><br/>",
    //"thirdBlock": "In the UBI Global index that compares and lists incubation centers, ITU ARI Teknokent's early stage incubation center ITU Çekirdek is in the top 5 in the world. <br></br> <br></br> <br></br> <strong> Lugath accepted to ITU Çekirdek; The 800m<sup>2</sup> open office located in ARI 3 building in Maslak continues to develop in its work office. It benefits from more than 300 Core mentor networks and develops its projects on canvas business model, financial models and market entry strategies with Entrepreneurship Training specially designed for entrepreneurs. </strong> <br/><br/>",
    //"ownerTitle": "<span>— Alastair Simpson, Head of UBI Global</span>",
    "lastHeading": "Innovation through the Mentors",
    "lastContent": "Lugath as an initiative of ITU Cekirdek continues business operations in the 800m2 co-worker hub under the supervision of more than 300 mentors specialized in diverse fields. Within the scope of ITU Cekirdek’s wide range of opportunities, Lugath is also supported by ITU education program particularly designed to improve start-up business models, financial plans and marketing strategies. ",
    "slug":"lugath-is-an-itu-cekirdek-pre-incubation-initiative"
  },
  {

    "id": 3,
    "date": "14 Dec",
    "title": "TUBITAK BIGG Program and R&D Funding",
    "innerTitle": "The ongoing BIGG journey.",
    "firstBlock": "Lugath awaits the R&D funding due in May, 2021.",
    //"firstBlock": "The application for R & D in the Lugath TUBITAK 1512 program, which was accepted to the BIGG process as a result of the BIGG Pre-Evaluation Jury, continues. ",
    "secondBlock": "",
    "thirdBlock": "<strong>TUBITAK 1512 Techno-initiative capital support program</strong> is designed to transform technology and innovation projects into companies with high added value and qualified employment creation potential. <br/><br/>It supports projects that appear internationally competitive, promote innovation, and develop high-tech products & services.<br/>",
    //"thirdBlock": "With the call of TUBITAK 1512 Techno-Entrepreneurship Capital Support Program, <br/> <strong>in order to transform our technology and innovation-oriented business idea into enterprises with high added value and qualified employment potential, supporting the activities from the idea stage to the market, internationally competitive, innovative, high technology products and services Our business idea is waiting for the results.</strong> </br></br>",
    "ownerTitle": "<br/><span>— Lugath, admitted by the BIGG3 program of METU, KWORKS and Arcelik Garage consortium, applied for R&D funding under TUBITAK 1512 program.</span>",
    "lastHeading": "Innovative Technologies",
    "lastContent": "Thanks to supervised machine learning techniques, Lugath can analyze the performance of language service providers with artificial intelligence service. <br/><br/>We are highly excited to fulfil our primary goal in improving competition among service providers through centralized, automated and cloud-based translation memory technology. In addition, we provide machine translation systems that give the best performance based on language and sector selection. All of these technologies are assessed within the scope of R&D program and supported by TUBITAK.",
    "slug":"we-applied-for-randd-fund-to-tubitak-bigg-program"
  }
]


