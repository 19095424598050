import seoData from "./seoData.json";
import { updates } from "./updates";
import _ from "lodash";
const staticItems = [
  {
    path: "/",
    seo: [
      {
        tag: "title",
        value: "Automates Content Localization",
      },
      {
        tag: "meta",
        attributes: {
          name: "title",
          content: "Automates Content Localization",
        },
      },
      {
        tag: "meta",
        attributes: {
          name: "keywords",
          content: "localization, automate, developer",
        },
      },
      {
        tag: "meta",
        attributes: {
          name: "description",
          content:
            "Lugath automates web, mobile, or game app localization for faster global growth with generative AI. ",
        },
      },
      {
        tag: "meta",
        attributes: {
          name: "robots",
          content: "index,follow",
        },
      },
      {
        tag: "link",
        attributes: {
          rel: "canonical",
          href: "https://lugath.com{{window.location.pathname}}",
        },
      },
      {
        tag: "meta",
        attributes: {
          property: "og:title",
          content: "A Localization and Translation Software Tool | Lugath",
        },
      },
      {
        tag: "meta",
        attributes: {
          property: "og:description",
          content:
            "Lugath automates web, mobile, or game app localization for faster global growth with generative AI.",
        },
      },
      {
        tag: "meta",
        attributes: {
          property: "og:url",
          content: "https://lugath.com{{window.location.pathname}}",
        },
      },
      {
        tag: "meta",
        attributes: {
          property: "og:type",
          content: "website",
        },
      },
      {
        tag: "meta",
        attributes: {
          property: "og:sitename",
          content: "lugath.com",
        },
      },
      {
        tag: "meta",
        attributes: {
          property: "og:image",
          content: "https://www.lugath.com/cover-pic.png",
        },
      },
      {
        tag: "meta",
        attributes: {
          property: "twitter:card",
          content: "summary_large_image",
        },
      },
      {
        tag: "meta",
        attributes: {
          property: "twitter:url",
          content: "https://www.lugath.com{{window.location.pathname}}",
        },
      },
      {
        tag: "meta",
        attributes: {
          property: "twitter:site",
          content: "",
        },
      },
      {
        tag: "meta",
        attributes: {
          property: "twitter:domain",
          content: "lugath.com",
        },
      },
      {
        tag: "meta",
        attributes: {
          property: "twitter:title",
          content: "Lugath, Automates Content Localization",
        },
      },
      {
        tag: "meta",
        attributes: {
          property: "twitter:description",
          content: "Automates Content Localization with the Power of AI.",
        },
      },
      {
        tag: "meta",
        attributes: {
          property: "twitter:image",
          content: "https://www.lugath.com/cover-pic.png",
        },
      },
    ],
  },
];

_.map(updates, (newsItem) => {
  const title = newsItem.title;

  const tempDiv = document.createElement("div");
  tempDiv.innerHTML = newsItem.body;

  const descriptionText = tempDiv.innerText;
  const description = descriptionText.length
    ? descriptionText.split(". ", 1)[0]
    : "";
  staticItems.push({
    path: `/news/${newsItem.slug}`,
    seo: [
      {
        tag: "title",
        value: title,
      },
      {
        tag: "meta",
        attributes: {
          name: "description",
          content: description,
        },
      },
    ],
  });
});

const items = [...seoData, ...staticItems];

export const seo = {
  default: items[0],
  paths: items,
};
