import React from "react";
import Accordion from "../../../Components/FaqPageComponents/Accordion";
import "./index.scss";

export default function ProductFAQs({ data, productName }) {
  return (
    <section
      className={`product-faqs product-faqs--${data?.options?.theme} ${
        data?.options?.theme === "dark" ? "" : "js-section_nav-dark"
      }`}
    >
      <div className="w-full">
        <div className="container mx-auto">
          <div className="product-faqs-content grid grid-cols-4 lg:grid-cols-8">
            <div className="col-span-full col-start-1 lg:col-span-6 lg:col-start-2">
              {data?.questions ? (
                <div className="category-group" id={`${data.title}`}>
                  <h2>{data.title}</h2>
                  {data?.questions.map((question) => {
                    return (
                      <Accordion
                        key={question.id}
                        question={question.question}
                        answer={question.answer}
                      />
                    );
                  })}
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
