import React, { useState, useEffect } from "react";
import ProductList from "../../Components/ProductList";

import "./index.scss";

export default function Products() {
  return (
    <div className="product-list-page">
      <section className="product-list-page-header js-section_nav-dark">
        <div className="container mx-auto">
          <div className="parent">
            <div className="div1">
              <div className="product-list-page-header__title">
                Products by <span className="shine">lugath</span>
              </div>
            </div>
            <div className="div2">
              <div className="product-list-page-header__content">
                <div className="product-list-page-header__content-desc">
                  Lugath offers a comprehensive suite of tools to help you
                  manage localization from end-to-end.
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <ProductList
        options={{
          shape: "image",
          imageMinWidth: "64px",
        }}
        data={{
          title: (
            <>
              <b>All the tools you need,</b> <br />
              All in one place
            </>
          ),
          description: (
            <>
              Discover the latest technologies powered by AI to maximize
              efficiency in your translation and localization workflow.
            </>
          ),
        }}
      />
    </div>
  );
}
