import { v4 as uuidv4 } from "uuid";
import slugify from "slugify";

const items = [
  {
    id: uuidv4(),
    hasPage: true,
    enable: true,
    status: { alias: "live", label: "Live" },
    name: "Dropbox",
    additionalInformation: {
      url: "",
    },
    category: "cloud-file-storage",
    categoryLabel: "Cloud File Storage",
    desc: "Exchange office documents with your Dropbox account.",
    image: "dropbox.svg",
    keywords: ["all", "storage", "file", "cloud"],
  },
  {
    id: uuidv4(),
    hasPage: false,
    enable: false,
    status: { alias: "upcoming", label: "Upcoming" },
    name: "Amazon S3",
    additionalInformation: {
      url: "",
    },
    category: "cloud-file-storage",
    categoryLabel: "Cloud File Storage",
    desc: "Upload localization files to your S3 bucket.",
    image: "1125-icon_int_as3-01.svg",
    keywords: ["all"],
  },
  {
    id: uuidv4(),
    hasPage: false,
    enable: false,
    status: { alias: "upcoming", label: "Upcoming" },
    name: "Google Cloud Storage",
    additionalInformation: {
      url: "",
    },
    category: "cloud-file-storage",
    categoryLabel: "Cloud File Storage",
    desc: "Upload localization files to your GCS bucket.",
    image: "1124-97-icon_int_google_storage.svg",
    keywords: ["all"],
  },
  {
    id: uuidv4(),
    hasPage: false,
    enable: true,
    status: { alias: "upcoming", label: "Upcoming" },
    name: "GitHub",
    additionalInformation: {
      url: "",
    },
    category: "code-repositories",
    categoryLabel: "Code Repositories",
    desc: "Maintain localization files with a GitHub repository.",
    image: "github.svg",
    keywords: ["all", "repo", "pipeline"],
  },
  {
    id: uuidv4(),
    hasPage: false,
    enable: true,
    status: { alias: "beta", label: "Beta" },
    name: "Bitbucket",
    additionalInformation: {
      url: "",
    },
    category: "code-repositories",
    categoryLabel: "Code Repositories",
    desc: "Maintain localization files with a Bitbucket repository.",
    image: "bitbucket.svg",
    keywords: ["all"],
  },
  {
    id: uuidv4(),
    hasPage: false,
    enable: true,
    status: { alias: "beta", label: "Beta" },
    name: "GitLab",
    additionalInformation: {
      url: "",
    },
    category: "code-repositories",
    categoryLabel: "Code Repositories",
    desc: "Maintain localization files with a GitLab repository.",
    image: "gitlab.svg",
    keywords: ["all"],
  },
  {
    id: uuidv4(),
    hasPage: false,
    enable: true,
    status: { alias: "upcoming", label: "Upcoming" },
    name: "Azure Repos",
    additionalInformation: {
      url: "",
    },
    category: "code-repositories",
    categoryLabel: "Code Repositories",
    desc: "Maintain localization files with an Azure repository.",
    image: "azure-repos.svg",
    keywords: ["all"],
  },
  {
    id: uuidv4(),
    hasPage: false,
    enable: true,
    status: { alias: "beta", label: "Beta" },
    name: "WordPress",
    additionalInformation: {
      url: "",
    },
    category: "content-management",
    categoryLabel: "Content Management",
    desc: "Translate and localize your pages and posts on WordPress",
    image: "Wordpress.svg",
    keywords: ["all"],
  },
  {
    id: uuidv4(),
    hasPage: false,
    enable: true,
    status: { alias: "upcoming", label: "Upcoming" },
    name: "Web In-context Editor",
    additionalInformation: {
      url: "",
    },
    category: "content-management",
    categoryLabel: "Content Management",
    desc: "Translate and localize your web content on LiveJS Web In-Context Editor.",
    image: "JS.svg",
    keywords: ["all"],
  },
  {
    id: uuidv4(),
    hasPage: false,
    enable: true,
    status: { alias: "beta", label: "Beta" },
    name: "HubSpot",
    additionalInformation: {
      url: "",
    },
    category: "content-management",
    categoryLabel: "Content Management",
    desc: "Translate and localize pages, blogs, and emails on HubSpot.",
    image: "hubspot.svg",
    keywords: ["all"],
  },
  {
    id: uuidv4(),
    hasPage: false,
    enable: true,
    status: { alias: "upcoming", label: "Upcoming" },
    name: "Iterable",
    additionalInformation: {
      url: "",
    },
    category: "content-management",
    categoryLabel: "Content Management",
    desc: "Translate and localize your email templates on Iterable",
    image: "iterable.svg",
    keywords: ["all"],
  },
  {
    id: uuidv4(),
    hasPage: false,
    enable: true,
    status: { alias: "upcoming", label: "Upcoming" },
    name: "Braze",
    additionalInformation: {
      url: "",
    },
    category: "content-management",
    categoryLabel: "Content Management",
    desc: "Translate and localize your campaigns on Braze.",
    image: "braze.svg",
    keywords: ["all"],
  },
  {
    id: uuidv4(),
    hasPage: false,
    enable: true,
    status: { alias: "upcoming", label: "Upcoming" },
    name: "Contentstack",
    additionalInformation: {
      url: "",
    },
    category: "content-management",
    categoryLabel: "Content Management",
    desc: "Translate and localize your content for global growth on Contentstack.",
    image: "contentstack.svg",
    keywords: ["all"],
  },
  {
    id: uuidv4(),
    hasPage: false,
    enable: true,
    status: { alias: "upcoming", label: "Upcoming" },
    name: "Contentful",
    additionalInformation: {
      url: "",
    },
    category: "content-management",
    categoryLabel: "Content Management",
    desc: "Translate and localize your content for global growth on Contentful.",
    image: "contentful.svg",
    keywords: ["all"],
  },
  {
    id: uuidv4(),
    hasPage: false,
    enable: true,
    status: { alias: "upcoming", label: "Upcoming" },
    name: "Strapi",
    additionalInformation: {
      url: "",
    },
    category: "content-management",
    categoryLabel: "Content Management",
    desc: "Translate and localize your content for global growth on Strapi.",
    image: "strapi-2.svg",
    keywords: ["all", "strapi", "cms"],
  },
  {
    id: uuidv4(),
    hasPage: false,
    enable: true,
    status: { alias: "upcoming", label: "Upcoming" },
    name: "Zendesk Guide",
    additionalInformation: {
      url: "",
    },
    category: "customer-service",
    categoryLabel: "Customer Service",
    desc: "Translate and localize your support guides on Zendesk.",
    image: "zendesk-guide.svg",
    keywords: ["all"],
  },
  {
    id: uuidv4(),
    hasPage: false,
    enable: true,
    status: { alias: "beta", label: "Beta" },
    name: "Zendesk Chat",
    additionalInformation: {
      url: "",
    },
    category: "customer-service",
    categoryLabel: "Customer Service",
    desc: "Real-time translation on Zendesk Chat.",
    image: "zendesk-chat.svg",
    keywords: ["all"],
  },
  {
    id: uuidv4(),
    hasPage: false,
    enable: true,
    status: { alias: "beta", label: "Beta" },
    name: "Zendesk Support",
    additionalInformation: {
      url: "",
    },
    category: "customer-service",
    categoryLabel: "Customer Service",
    desc: "Translate your support tickets on Zendesk Support.",
    image: "zendesk-support.svg",
    keywords: ["all"],
  },
  {
    id: uuidv4(),
    hasPage: false,
    enable: true,
    status: { alias: "beta", label: "Beta" },
    name: "Intercom Notes",
    additionalInformation: {
      url: "",
    },
    category: "customer-service",
    categoryLabel: "Customer Service",
    desc: "Real-time translation on Intercom notes.",
    image: "intercom-notes.svg",
    keywords: ["all"],
  },
  {
    id: uuidv4(),
    hasPage: false,
    enable: true,
    status: { alias: "upcoming", label: "Upcoming" },
    name: "Intercom Articles",
    additionalInformation: {
      url: "",
    },
    category: "customer-service",
    categoryLabel: "Customer Service",
    desc: "Translate and localize your support guides on Intercom.",
    image: "intercom-notes.svg",
    keywords: ["all"],
  },
  {
    id: uuidv4(),
    hasPage: false,
    enable: true,
    status: { alias: "upcoming", label: "Upcoming" },
    name: "HubSpot Inbox",
    additionalInformation: {
      url: "Real-time translation on HubSpot Inbox.",
    },
    category: "customer-service",
    categoryLabel: "Customer Service",
    desc: "",
    image: "hubspot.svg",
    keywords: ["all"],
  },
  {
    id: uuidv4(),
    hasPage: false,
    enable: true,
    status: { alias: "upcoming", label: "Upcoming" },
    name: "Salesforce CRM",
    additionalInformation: {
      url: "",
    },
    category: "customer-service",
    categoryLabel: "Customer Service",
    desc: "Translate and localize your customer data on Salesforce CRM",
    image: "salesforce.svg",
    keywords: ["all"],
  },
  {
    id: uuidv4(),
    hasPage: false,
    enable: true,
    status: { alias: "upcoming", label: "Upcoming" },
    name: "Salesforce Knowledge",
    additionalInformation: {
      url: "",
    },
    category: "customer-service",
    categoryLabel: "Customer Service",
    desc: "Translate and localize your support guides on Salesforce Knowledge.",
    image: "salesforce.svg",
    keywords: ["all"],
  },
  {
    id: uuidv4(),
    hasPage: false,
    enable: true,
    status: { alias: "beta", label: "Beta" },
    name: "Figma",
    additionalInformation: {
      url: "",
    },
    category: "design",
    categoryLabel: "Design",
    desc: "Provide screenshots and texts for localization within Figma.",
    image: "figma.svg",
    keywords: ["all"],
  },
  {
    id: uuidv4(),
    hasPage: false,
    enable: true,
    status: { alias: "upcoming", label: "Upcoming" },
    name: "Adobe XD",
    additionalInformation: {
      url: "",
    },
    category: "design",
    categoryLabel: "Design",
    desc: "Provide screenshots and texts for localization within Adobe XD.",
    image: "adobe-xd.svg",
    keywords: ["all"],
  },
  {
    id: uuidv4(),
    hasPage: false,
    enable: true,
    status: { alias: "beta", label: "Beta" },
    name: "Sketch",
    additionalInformation: {
      url: "",
    },
    category: "design",
    categoryLabel: "Design",
    desc: "Provide screenshots and texts for localization within Sketch.",
    image: "sketch.svg",
    keywords: ["all"],
  },
  {
    id: uuidv4(),
    hasPage: false,
    enable: true,
    status: { alias: "live", label: "Live" },
    name: "API",
    additionalInformation: {
      url: "",
    },
    category: "developer-tools",
    categoryLabel: "Developer Tools",
    desc: "Create your own automated localization workflow with custom integration.",
    image: "api.svg",
    keywords: ["all"],
  },
  {
    id: uuidv4(),
    hasPage: false,
    enable: true,
    status: { alias: "beta", label: "Beta" },
    name: "CLI",
    additionalInformation: {
      url: "",
    },
    category: "developer-tools",
    categoryLabel: "Developer Tools",
    desc: "Integrate your continuous development pipeline and localization workflow with our Command-line tool.",
    image: "cli-logo.svg",
    keywords: ["all"],
  },
  {
    id: uuidv4(),
    hasPage: false,
    enable: true,
    status: { alias: "beta", label: "Beta" },
    name: "Node.js SDK",
    additionalInformation: {
      url: "",
    },
    category: "developer-tools",
    categoryLabel: "Developer Tools",
    desc: "Lugath API client for Node JS.",
    image: "node-js.svg",
    keywords: ["all"],
  },
  {
    id: uuidv4(),
    hasPage: false,
    enable: true,
    status: { alias: "beta", label: "Beta" },
    name: "Java SDK",
    additionalInformation: {
      url: "",
    },
    category: "developer-tools",
    categoryLabel: "Developer Tools",
    desc: "Lugath API client for Java.",
    image: "java.svg",
    keywords: ["all"],
  },
  {
    id: uuidv4(),
    hasPage: false,
    enable: true,
    status: { alias: "upcoming", label: "Upcoming" },
    name: "Python SDK",
    additionalInformation: {
      url: "",
    },
    category: "developer-tools",
    categoryLabel: "Developer Tools",
    desc: "Lugath API client for Python.",
    image: "python.svg",
    keywords: ["all"],
  },
  {
    id: uuidv4(),
    hasPage: false,
    enable: true,
    status: { alias: "upcoming", label: "Upcoming" },
    name: "PHP SDK",
    additionalInformation: {
      url: "",
    },
    category: "developer-tools",
    categoryLabel: "Developer Tools",
    desc: "Lugath API client for PHP.",
    image: "php.svg",
    keywords: ["all"],
  },
  {
    id: uuidv4(),
    hasPage: false,
    enable: true,
    status: { alias: "upcoming", label: "Upcoming" },
    name: "Docker",
    additionalInformation: {
      url: "",
    },
    category: "developer-tools",
    categoryLabel: "Developer Tools",
    desc: "Import and export project data.",
    image: "docker.svg",
    keywords: ["all"],
  },
  {
    id: uuidv4(),
    hasPage: false,
    enable: true,
    status: { alias: "upcoming", label: "Upcoming" },
    name: "iOS SDK",
    additionalInformation: {
      url: "",
    },
    category: "developer-tools",
    categoryLabel: "Developer Tools",
    desc: "Lugath API client for IOS Platform.",
    image: "apple.svg",
    keywords: ["all"],
  },
  {
    id: uuidv4(),
    hasPage: false,
    enable: true,
    status: { alias: "upcoming", label: "Upcoming" },
    name: "Android SDK",
    additionalInformation: {
      url: "",
    },
    category: "developer-tools",
    categoryLabel: "Developer Tools",
    desc: "Lugath API client for Android Platform.",
    image: "android-2.svg",
    keywords: ["all"],
  },
  {
    id: uuidv4(),
    hasPage: false,
    enable: true,
    status: { alias: "upcoming", label: "Upcoming" },
    name: "Typeform",
    additionalInformation: {
      url: "",
    },
    category: "forms-and-submissions",
    categoryLabel: "Forms & Submissions",
    desc: "Translate and localize your content on Typeform.",
    image: "typeform.svg",
    keywords: ["all"],
  },
  {
    id: uuidv4(),
    hasPage: false,
    enable: true,
    status: { alias: "upcoming", label: "Upcoming" },
    name: "Jotform",
    additionalInformation: {
      url: "",
    },
    category: "forms-and-submissions",
    categoryLabel: "Forms & Submissions",
    desc: "Translate and localize your content on Jotform.",
    image: "jotform.svg",
    keywords: ["all"],
  },
  {
    id: uuidv4(),
    hasPage: false,
    enable: true,
    status: { alias: "upcoming", label: "Upcoming" },
    name: "Slack",
    additionalInformation: {
      url: "",
    },
    category: "notifications-and-events",
    categoryLabel: "Notifications & Events",
    desc: "Send notifications for localization progress on a Slack channel.",
    image: "slack.svg",
    keywords: ["all"],
  },
  {
    id: uuidv4(),
    hasPage: false,
    enable: true,
    status: { alias: "upcoming", label: "Upcoming" },
    name: "E-mail",
    additionalInformation: {
      url: "",
    },
    category: "notifications-and-events",
    categoryLabel: "Notifications & Events",
    desc: "Send emails for localization progress.",
    image: "gmail.svg",
    keywords: ["all"],
  },
  {
    id: uuidv4(),
    hasPage: false,
    enable: true,
    status: { alias: "upcoming", label: "Upcoming" },
    name: "Webhooks",
    additionalInformation: {
      url: "",
    },
    category: "notifications-and-events",
    categoryLabel: "Notifications & Events",
    desc: "Customized webhooks.",
    image: "webhook.svg",
    keywords: ["all"],
  },
  {
    id: uuidv4(),
    hasPage: false,
    enable: true,
    status: { alias: "upcoming", label: "Upcoming" },
    name: "Datadog",
    additionalInformation: {
      url: "",
    },
    category: "notifications-and-events",
    categoryLabel: "Notifications & Events",
    desc: "Monitor localization events on Datadog.",
    image: "datadog.svg",
    keywords: ["all"],
  },
  {
    id: uuidv4(),
    hasPage: false,
    enable: true,
    status: { alias: "beta", label: "Beta" },
    name: "Zapier",
    additionalInformation: {
      url: "",
    },
    category: "notifications-and-events",
    categoryLabel: "Notifications & Events",
    desc: "Integrate Lugath with thousands of apps by Zapier.",
    image: "zapier.svg",
    keywords: ["all"],
  },
  {
    id: uuidv4(),
    hasPage: false,
    enable: true,
    status: { alias: "beta", label: "Beta" },
    name: "Jira",
    additionalInformation: {
      url: "",
    },
    category: "project-management",
    categoryLabel: "Project Management",
    desc: "Translate and localize your stories on Jira.",
    image: "jira.svg",
    keywords: ["all"],
  },
  {
    id: uuidv4(),
    hasPage: false,
    enable: true,
    status: { alias: "upcoming", label: "Upcoming" },
    name: "Trello",
    additionalInformation: {
      url: "",
    },
    category: "project-management",
    categoryLabel: "Project Management",
    desc: "Translate and localize your stories on Trello.",
    image: "trello.svg",
    keywords: ["all"],
  },
  {
    id: uuidv4(),
    hasPage: false,
    enable: true,
    status: { alias: "upcoming", label: "Upcoming" },
    name: "Asana",
    additionalInformation: {
      url: "",
    },
    category: "project-management",
    categoryLabel: "Project Management",
    desc: "Translate and localize your stories on Asana.",
    image: "asana.svg",
    keywords: ["all"],
  },
];

export const apps = items.map((i) => {
  return { ...i, ...{ slug: slugify(i.name.toLowerCase()) } };
});
