import React, { useState } from "react";
import BrowserFrame from "../BrowserFrame";
import ScreenShotSample from "../../Assets/Images/screenshot-sample.png";
import { ReactComponent as CloseIcon } from "../../Assets/Icons/close-icon--alternative.svg";
import ReactPlayer from "react-player";
import ReactModal from "react-modal";
import "./index.scss";
import VideoPlayer from "../VideoPlayer";

export default function HeroImage({
  data,
  options = {
    theme: "dark",
    description: "right",
    title: "left",
    imageAlignment: "bottom",
  },
}) {
  const [showModal, setShowModal] = useState(false);
  const onClickAction = () => {
    setShowModal(true);
  };
  return (
    <section
      className={`hero-image-bg hero-image-bg__${options.imageAlignment}`}
    >
      <div className="container mx-auto">
        <div className="hero-image__wrapper">
          {data.title ? (
            <h1 className="hero-image__title">{data.title}</h1>
          ) : null}
          {data.description ? (
            <span className="hero-image__desc">{data.description}</span>
          ) : null}
          <div
            className={`hero-image__frame hero-image__frame__${options.imageAlignment}`}
          >
            <BrowserFrame
              url={"app.lugath.com"}
              path={data.path}
              image={data.image}
              onClickAction={onClickAction}
            />
          </div>
          {data.bottomDescription ? (
            <span className="hero-image__sub">
              lugath AI is our latest translation tool powered by artificial
              intelligence. It's super handy when you need to translate content
              with context and in large quantities.
            </span>
          ) : null}
        </div>
      </div>
    </section>
  );
}
