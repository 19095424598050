export const profiles = [
  {
    image: "Assets/Icons/for-developers.svg",
    alias: "developers",
    title: "Developers",
    description:
      "Set up an integration with your code repository or your own custom SDK via Lugath's powerful API.",
    link: "/solutions/localization-for-developers",
  },
  {
    image: "Assets/Icons/for-managers.svg",
    alias: "managers",
    title: "Managers",
    description:
      "Update existing and launch new features and products while simultaneously localizing them in multiple languages.",
    link: "/solutions/localization-for-managers",
  },
  {
    image: "Assets/Icons/for-translators.svg",
    alias: "translators",
    title: "Translators",
    description:
      "Power up your translation to next level with the help of lugath AI and Increase your productivity.",
    link: "/solutions/localization-for-translators",
  },
  {
    image: "Assets/Icons/for-designers.svg",
    alias: "designers",
    title: "Designers",
    description:
      "Full design control in your hands, Design your product or service for multi-cultural user experience dynamics.",
    link: "/solutions/localization-for-designers",
  },
  {
    image: "Assets/Icons/for-customer-service.svg",
    alias: "customer-service",
    title: "Customer Service",
    description:
      "Enhance global customer support with real-time chat translation and multilingual knowledge base articles.",
    link: "/solutions/localization-for-customer-service",
  },
  {
    image: "Assets/Icons/for-enterprise.svg",
    alias: "enterprise",
    title: "Enterprise",
    description:
      "Seamlessly become part of your workflow and automate processes to expedite the delivery of fully localized experiences.",
    link: "/solutions/localization-for-enterprise",
  },
];
