import React, { useRef, useState } from "react";
import useImage from "./useImage";
import { useInView } from "react-intersection-observer";
import "./index.scss";

const Image = ({
  fileName,
  alt,
  className,
  width = 100,
  height = 100,
  ...rest
}) => {
  const { ref, inView, entry } = useInView({
    threshold: 0.25,
  });
  const imageRef = useRef(null);
  const { loading, error, image } = useImage(fileName, inView);
  const [imageDimensions, setImageDimensions] = useState({
    width: width,
    height: height,
  });

  if (error) return <p>{alt}</p>;

  const onLoadImage = (e) => {
    /*setImageDimensions({
      height: imageRef.current.naturalHeight,
      width: imageRef.current.naturalWidth,
    });*/
  };

  return (
    <div
      ref={ref}
      style={{
        "--height": imageDimensions?.height,
        "--width": imageDimensions?.width,
      }}
      className={`image-skeleton__placeholder ${loading ? "loading" : ""}`}
    >
      {loading ? (
        <div className="image-skeleton__loading"></div>
      ) : (
        <img
          className={`image${
            className ? className.padStart(className.length + 1) : ""
          }`}
          ref={imageRef}
          onLoad={(e) => onLoadImage(e)}
          src={image}
          alt={alt}
          {...rest}
        />
      )}
    </div>
  );
};

export default Image;
