import React from "react";
import { useParams, useLocation, useNavigate } from "react-router-dom";
import { ReactComponent as ChevronRight } from "../../../Assets/Icons/chevron-right.svg";
import { useDispatch } from "react-redux";
import Image from "../../Image";
import CTAButtonWrapper from "../../CTABtnWrapper";
import CONFIG from "../../../Services/ConfigService";
import "./index.scss";

export default function SolutionDetailsCover({ data }) {
  const navigate = useNavigate();
  const onClickItem = (item) => {
    if (item.link) {
      if (CONFIG.APP.HARD_ROUTE_CHANGE) {
        window.location.href = item.link;
      } else {
        navigate(item.link);
      }
    }
  };
  return (
    <section className="solution-details-cover js-section_nav-dark">
      <div className="container mx-auto">
        <div className="solution-details-cover__wrapper">
          <div className="solution-details-cover__title-wrapper">
            <div
              className="solution-details-cover__title"
              dangerouslySetInnerHTML={
                typeof data.title === "string" ? { __html: data.title } : null
              }
            >
              {typeof data.title === "object" ? data.title : null}
            </div>
            <div
              className="solution-details-cover__title-desc"
              dangerouslySetInnerHTML={
                typeof data.description === "string"
                  ? { __html: data.description }
                  : null
              }
            >
              {typeof data.description === "object" ? data.description : null}
            </div>
          </div>
          <div className="solution-details-cover__content-wrapper">
            {data?.featureList.map((f, i) => {
              return (
                <div
                  key={`feature-list-wrapper-key-${i}`}
                  onClick={() => onClickItem(f)}
                  className={`${f.wrapper} ${f.link ? "cursor-pointer" : ""}`}
                >
                  <div className="solution-details-cover__content-feature__details">
                    <div className="solution-details-cover__content-feature__details-title">
                      {f.title}
                    </div>
                    <div className="solution-details-cover__content-feature__details-desc">
                      {f.description}
                    </div>
                  </div>
                  {f.image ? (
                    <div className="solution-details-cover__content-feature__image">
                      <Image
                        fileName={f.image.source}
                        width={f.image.width}
                        height={f.image.height}
                      />
                    </div>
                  ) : null}
                </div>
              );
            })}
          </div>
        </div>
        <div className="product-feature-list__link-wrapper">
          {data.cta.map((c, i) => (
            <CTAButtonWrapper
              key={`product-feature-list__link-wrapper-key-${i}`}
              options={{
                formPopupTheme: "light",
                utmLabel: "js_try_all_features_14_days_btn",
              }}
              children={
                <button className="product-feature-list__link">
                  <span className="product-feature-list__link-desc">
                    {c?.label}
                  </span>
                  <span className="product-feature-list__link-icon">
                    <ChevronRight />
                  </span>
                </button>
              }
              data={{ ...c }}
            ></CTAButtonWrapper>
          ))}
        </div>
      </div>
    </section>
  );
}
