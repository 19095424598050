import React from "react";
import Marquee from "react-fast-marquee";
import { ReactComponent as CustomerVestel } from "../../../Assets/Images/customer-vestel.svg";
import { ReactComponent as CustomerArcelik } from "../../../Assets/Images/customer-arcelik.svg";
import { ReactComponent as CustomerParibu } from "../../../Assets/Images/customer-paribu.svg";
import { ReactComponent as CustomerEts } from "../../../Assets/Images/customer-ets.svg";
import { ReactComponent as CustomerSabanci } from "../../../Assets/Images/customer-sabanci.svg";
import { ReactComponent as CustomerSiemens } from "../../../Assets/Images/customer-siemens.svg";
import { ReactComponent as CustomerGama } from "../../../Assets/Images/customer-gama.svg";
import { ReactComponent as CustomerFlorence } from "../../../Assets/Images/customer-florence.svg";
import "./index.scss";

const customerLogos = [
  {
    component: (customStyles) => <CustomerVestel style={{ ...customStyles }} />,
  },
  {
    component: (customStyles) => <CustomerParibu style={{ ...customStyles }} />,
  },
  {
    component: (customStyles) => <CustomerEts style={{ ...customStyles }} />,
  },
  {
    component: (customStyles) => (
      <CustomerArcelik style={{ ...customStyles }} />
    ),
  },
  {
    component: (customStyles) => (
      <CustomerSabanci style={{ ...customStyles }} />
    ),
  },
  {
    component: (customStyles) => (
      <CustomerSiemens style={{ ...customStyles }} />
    ),
  },
  {
    component: (customStyles) => <CustomerGama style={{ ...customStyles }} />,
  },
  {
    component: (customStyles) => (
      <CustomerFlorence style={{ ...customStyles }} />
    ),
  },
];

export default function CustomerLogos() {
  return (
    <div className="customer-section container mx-auto">
      <span className="customer-section__title">
        <span style={{ color: "#aaa" }}>Trusted by </span>
        <br />
        incumbents and challengers alike
      </span>
      <div className="customer-section__content">
        <Marquee
          style={{ backgroundColor: "transparent" }}
          gradientColor={"transparent"}
        >
          {customerLogos.map((c, i) => (
            <div
              key={`customer-logo-${i}`}
              className="customer-section__content-item"
            >
              {" "}
              {c.component()}
            </div>
          ))}
        </Marquee>
      </div>
    </div>
  );
}
