import slugify from "slugify";
import { v4 as uuidv4 } from "uuid";

const products = [
  {
    id: uuidv4(),
    header: "dark",
    productName: "AI Translation",
    alias: "Generative AI",
    slug: "generative-ai-translation",
    icon: "product-ai-translation.svg",
    active: true,
    status: { alias: "beta", label: "BETA", visible: true },
  },
  {
    id: uuidv4(),
    header: "dark",
    productName: "AI Quality Assurance",
    alias: "Linguistic Quality",
    slug: "ai-quality-assurance",
    icon: "product-quality-assurance.svg",
    active: true,
    status: { alias: "upcoming", label: "UPCOMING", visible: true },
  },
  {
    id: uuidv4(),
    header: "dark",
    productName: "AI Translation Memory",
    alias: "Automated TM",
    slug: "ai-translation-memory",
    icon: "product-translation-memory.svg",
    active: true,
    status: { alias: "live", label: "LIVE", visible: false },
  },
  {
    id: uuidv4(),
    header: "dark",
    productName: "Localization Workflow",
    alias: "Workflow Management",
    slug: "localization-workflow",
    icon: "product-workflow-management.svg",
    active: true,
    status: { alias: "live", label: "LIVE", visible: false },
  },
  {
    id: uuidv4(),
    header: "dark",
    productName: "Glossary",
    alias: "Terminology Management",
    slug: "glossary",
    icon: "product-glossary.svg",
    active: true,
    status: { alias: "live", label: "LIVE", visible: false },
  },
  {
    id: uuidv4(),
    header: "dark",
    productName: "Online CAT Tool",
    alias: "Editor",
    slug: "online-cat-tool",
    icon: "product-cat-tool.svg",
    active: true,
    status: { alias: "live", label: "LIVE", visible: false },
  },
  {
    id: uuidv4(),
    header: "dark",
    productName: "Collaborative Translation",
    alias: "Task Management",
    slug: "collaborative-translation",
    icon: "product-collaboration.svg",
    active: true,
    status: { alias: "live", label: "LIVE", visible: false },
  },
  {
    id: uuidv4(),
    header: "dark",
    productName: "Translation Service Hub",
    alias: "Intelligent MT Hub",
    slug: "translation-service-hub",
    icon: "product-service-hub.svg",
    active: true,
    status: { alias: "live", label: "LIVE", visible: false },
  },
  {
    id: uuidv4(),
    header: "dark",
    productName: "Document Translation",
    alias: "Preserve Layout",
    slug: "document-translation",
    icon: "product-document-translation.svg",
    active: true,
    status: { alias: "live", label: "LIVE", visible: false },
  },

  {
    id: uuidv4(),
    header: "dark",
    productName: "Real-time Translation",
    alias: "Instant Translation",
    slug: "realtime-translation",
    icon: "product-real-time.svg",
    active: true,
    status: { alias: "live", label: "LIVE", visible: false },
  },
  {
    id: uuidv4(),
    header: "dark",
    productName: "App Marketplace",
    alias: "Integrations",
    slug: "app-marketplace",
    icon: "product-marketplace.svg",
    active: true,
    status: { alias: "live", label: "LIVE", visible: false },
  },
  {
    id: uuidv4(),
    header: "dark",
    productName: "Security",
    alias: "Service Standards",
    slug: "security",
    icon: "product-security.svg",
    active: true,
    status: { alias: "live", label: "LIVE", visible: false },
  },
];

export const commons = {
  products: products.map((i) => ({
    ...i,
    ...{ keyName: slugify(i.productName.toUpperCase(), { replacement: "_" }) },
  })),
  solutions: [],
};
