import React, { useReducer, useState } from "react";
import { Link } from "react-router-dom";
import "./index.scss";

const formReducer = (state, event) => {
  return {
    ...state,
    [event.name]: event.value,
  };
};

export default function Signature() {
  const [formData, setFormData] = useReducer(formReducer, {});
  const [submitting, setSubmitting] = useState(false);
  const [statusMessage, setStatusMessage] = useState(false);
  const envMessageEN =
    "Please consider the environment before printing this message.";
  const envMessageTR = "Yazdırmadan önce çevremizi düşünelim.";
  const warningMessageEN =
    "The information contained in this e-mail (including any attachments) is confidential. It must not be disclosed to any person without our authority. If you are not the intended recipient, please delete it from your system immediately. Lugath Corp. makes no warranty as to the accuracy or completeness of any information contained in this message and hereby excludes any liability of any kind for the information contained therein or for the information transmission, reception, storage or use of such in any way whatsoever. Any opinions expressed in this message are those of the author and may not necessarily reflect the opinions of Lugath Corp.";
  const warningMessageTR =
    "Bu e-posta’nin içerdiği bilgiler (ekleri dâhil olmak üzere) gizlidir. Onayımız olmaksızın üçüncü kişilere açıklanamaz. Bu mesajın gönderilmek istendiği kişi değilseniz, lütfen mesajı derhal silin. Lugath; şirket olarak, bu mesajın içerdiği bilgilerin doğruluğu veya eksiksiz olduğu konusunda bir garanti verememektedir. Bu nedenle bilgilerin ne şekilde olursa olsun içeriğinden, iletilmesinden, alınmasından, saklanmasından sorumlu değildir. Bu mesajin içerigi yazarına ait olup, Lugath şirketinin görüşlerini içermeyebilir.";
  const handleSubmit = (event) => {
    event.preventDefault();
    setSubmitting(true);
    if (formData.name && formData.title && formData.email && formData.phone) {
      setStatusMessage("Please Wait, preparing your signature...");
      const template = templateParser(signatureTemplate(), {
        ...formData,
        envMessageEN: envMessageEN,
        envMessageTR: envMessageTR,
        warningMessageEN: warningMessageEN,
        warningMessageTR: warningMessageTR,
      });
      downloadTxtFile(template, slugify(formData.name));
      setTimeout(() => {
        setSubmitting(false);
      }, 3000);
    } else {
      setStatusMessage("You need to fill all the fields...");
    }
  };

  const downloadTxtFile = (value, name) => {
    const element = document.createElement("a");
    const file = new Blob([value], { type: "text/html; charset=UTF-8" });
    element.href = URL.createObjectURL(file);
    element.download = name + ".html";
    document.body.appendChild(element);
    element.click();
  };

  const slugify = (text) => {
    const from = "ãàáäâẽèéëêìíïîõòóöôùúüûñç·/_,:;ş";
    const to = "aaaaaeeeeeiiiiooooouuuunc------s";

    const newText = text
      .split("")
      .map((letter, i) =>
        letter.replace(new RegExp(from.charAt(i), "g"), to.charAt(i))
      );

    return newText
      .toString() // Cast to string
      .toLowerCase() // Convert the string to lowercase letters
      .trim() // Remove whitespace from both sides of a string
      .replace(/\s+/g, "-") // Replace spaces with -
      .replace(/&/g, "-y-") // Replace & with 'and'
      .replace(/[^\w\-]+/g, "") // Remove all non-word chars
      .replace(/\-\-+/g, "-"); // Replace multiple - with single -
  };

  const handleChange = (event) => {
    const isCheckbox = event.target.type === "checkbox";

    setFormData({
      name: event.target.name,
      value: isCheckbox ? event.target.checked : event.target.value,
    });
  };

  const signatureTemplate = () => {
    const templateString =
      "<div style='width:100%;min-height:300px; position:relative; float:left; height:auto; margin:0px; background-color:rgb(40,40,40)'>" +
      "<div class='bg-lines' style='position:absolute;top:0;right:0;bottom:0;left:0; background:-webkit-linear-gradient(0deg,rgba(255, 255, 255, 0.05) 1px,transparent 0px)0 0 / 10% auto; border-right: 1px solid rgba(255, 255, 255, 0.05); pointer-events:none;'></div>" +
      "<div class='bg-lines' style='position:absolute;top:0;right:0;bottom:0;left:0; background:-webkit-linear-gradient(0deg,rgba(0, 0, 0, 0.05) 1px,transparent 0px)0 0 / 10% auto; border-right: 1px solid rgba(0, 0, 0, 0.05); pointer-events:none;'></div>" +
      "  <div class='adM'>" +
      "    <div style='width:100%;float:left;min-height:50px;height:auto'>" +
      "      <div style='float:left;width:90%;padding:5% 5% 50px;height:auto;background:none repeat scroll 0% 0% rgb(255,255,255)'>" +
      "        <div style='font-family:helvetica,arial,sans-serif;font-size:24px;font-weight:bold;letter-spacing:-0.5px;text-decoration:none;color:rgb(64,72,82);float:left;width:100%'>{{name}}</div>" +
      "        <div style='float:left;width:100%;font-family:helvetica,arial,sans-serif;font-size:14px;font-weight:normal;letter-spacing:-0.5px;text-decoration:none;color:rgb(62,186,203); padding-top:5px;'>/ {{title}}</div>" +
      "        <div style='float:left;width:32px;background:#ccc; height:1px; margin:15px 0'></div>" +
      "        <div style='float:left;width:100%;font-family:helvetica,arial,sans-serif;font-size:14px;font-weight:normal;letter-spacing:-0.5px;text-decoration:none;color:#aaa; padding-top:5px;'><a style='text-decoration:none; color:#aaa;' href='mailto:{{email}}'>{{email}}</a></div>" +
      "        <div style='float:left;width:100%;font-family:helvetica,arial,sans-serif;font-size:14px;font-weight:normal;letter-spacing:-0.5px;text-decoration:none;color:#aaa; padding-top:5px;'><a style='text-decoration:none; color:#aaa;' href='tel:{{phone}}'>{{phone}}</a></div>" +
      "      </div>" +
      "      <div style='width:90%;float:left;min-height:200px;padding:5%;height:auto'>" +
      "        <div style='width:32px;height:72px;padding:24px 0px;border-bottom:1px solid rgb(55,55,55); margin-bottom:20px'>" +
      "          <a href='https://www.lugath.com' target='_blank'>" +
      "            <img src='https://www.lugath.com/cover-logo.png' width='72'>" +
      "          </a>" +
      "        </div>" +
      "        <div style='float:left;width:100%;height:auto'>" +
      "          <div style='font-family:helvetica,arial,sans-serif;font-size:20px;font-weight:bold;letter-spacing:-0.5px;text-decoration:none;color:rgb(255,255,255);float:left;width:100%; margin-bottom:10px;'>" +
      "            Lugath Corp. " +
      "          </div>" +
      "          <div style='font-family:helvetica,arial,sans-serif;font-size:14px;font-weight:normal;letter-spacing:-0.5px;text-decoration:none;color:rgb(64,72,82);margin-top:5px;float:left;width:100%; color:rgb(120,120,120)'>Istanbul Teknopark" +
      "            <br/>Cube Incubation" +
      "          </div>" +
      "          <div style='font-family:helvetica,arial,sans-serif;font-size:12px;font-weight:normal;letter-spacing:-0.5px;text-decoration:none;color:rgb(64,72,82);margin-top:15px;float:left;width:100%; color:rgb(120,120,120)'>Sanayi Mah. Teknopark Bul. No:1/4C/Z08 34906" +
      "            <br/> 34906 Pendik" +
      "            <br/> IST</div>" +
      "          </div>" +
      "        <div style='float:left;width:100%;height:auto'>" +
      "          <div style='min-height:32px;height:auto;padding:10px 0px'> <img width='32px' src='https://www.lugath.com/Tree.png'> </div>" +
      "          <div style='float:left;width:100%;min-height:200px;height:auto'>" +
      "            <div style='font-family:georgia,verdana;font-size:14px;font-weight:normal;letter-spacing:-0.5px;text-decoration:none;color:rgb(0,194,147);float:left;width:100%; margin-bottom:4px;'>{{envMessageTR}}</div>" +
      "            <div style='font-family:georgia,verdana;font-size:14px;font-weight:normal;letter-spacing:-0.5px;text-decoration:none;color:rgb(64,72,82);margin-bottom:30px;float:left;width:100%; color:rgb(120,120,120)'>{{envMessageEN}}</div>" +
      "            <div style='font-family:georgia,verdana;font-size:12px; font-weight:normal;letter-spacing:-0.5px;text-decoration:none;color:rgb(170,170,170);margin-bottom:50px;float:left;max-width:70%; line-height:16px;'>{{warningMessageTR}}" +
      "              <br>" +
      "              <br> {{warningMessageEN}} </div>" +
      "          </div>" +
      "        </div>" +
      "      </div>" +
      "    </div>" +
      "  </div>" +
      "</div>";

    return templateString;
  };

  const templateParser = (str, data) => {
    return str.replace(/\{\{(.*?)\}\}/g, (match, token) => {
      return data[token];
    });
  };

  {
  }

  return (
    <div className="signature-page bg-dark flex flex-col justify-center items-center h-screen w-full">
      <div className="logo ">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="100"
          height="100"
          viewBox="0 0 19.636 19.636"
        >
          <g>
            <rect
              width="4.909"
              height="4.909"
              x="14.727"
              y="0"
              fill="rgb(71,204,223)"
              rx="0"
              ry="0"
            />
            <path
              fill="rgb(255,255,255)"
              fill-rule="evenodd"
              d="M0 4.90908813V0h14.7272644v4.90908813h4.90908814v14.72727204H0V4.90908813zm9.81817627 0H4.90908813v9.8181839h9.81817627V9.8181839l-4.90908813-.00000763V4.90908813z"
            />
            <rect
              width="4.909"
              height="4.909"
              x="4.909"
              y="9.818"
              fill="rgb(40,40,40)"
              rx="0"
              ry="0"
            />
            <rect
              width="4.909"
              height="4.909"
              x="9.818"
              y="9.818"
              fill="rgb(40,40,40)"
              rx="0"
              ry="0"
            />
            <rect
              width="4.909"
              height="4.909"
              x="4.909"
              y="4.909"
              fill="rgb(40,40,40)"
              rx="0"
              ry="0"
            />
          </g>
        </svg>
      </div>
      <div className="content">
        <h1 className="font-eina01 font-bold pb-10">Signature Generator</h1>
        <h5 className="text-white opacity-40 font-eina02">
          Please Enter your information
        </h5>
        {
          submitting && <h6> {statusMessage} </h6>
          /* <div>
          You are submitting the following:
          <ul>
            {Object.entries(formData).map(([name, value]) => (
              <li key={name}><strong>{name}</strong>: {value.toString()}</li>
            ))}
          </ul>
        </div>*/
        }
        <form
          onSubmit={(e) => {
            handleSubmit(e);
          }}
        >
          <fieldset>
            <label>
              <input
                name="name"
                placeholder="Name Surname"
                onChange={(e) => {
                  handleChange(e);
                }}
              />
            </label>
          </fieldset>

          <fieldset>
            <label>
              <input
                name="title"
                placeholder="Your Title"
                onChange={(e) => {
                  handleChange(e);
                }}
              />
            </label>
          </fieldset>

          <fieldset>
            <label>
              <input
                name="email"
                placeholder="Email"
                onChange={(e) => {
                  handleChange(e);
                }}
              />
            </label>
          </fieldset>

          <fieldset>
            <label>
              <input
                name="phone"
                placeholder="Phone Number (With CC)"
                onChange={(e) => {
                  handleChange(e);
                }}
              />
            </label>
          </fieldset>

          <button type="submit">Submit</button>
        </form>
      </div>
    </div>
  );
}
