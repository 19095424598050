import React from "react";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import emailjs from "emailjs-com";
import "./contact.scss";

export default function Contact() {
  const { handleSubmit, register, errors, reset } = useForm();

  const sendFormActions = (data) => {
    emailjs
      .send(
        "service_ea6svqr",
        "template_bljrahb",
        data,
        "user_nEhE1W4DvHXMNnflvmRDY"
      )
      .then(
        (result) => {
          reset();
          document
            .getElementById("ea-popup")
            .scrollTo({ top: 0, behavior: "smooth" });
        },
        (error) => {}
      );
  };

  const onSubmit = (data) => {
    sendFormActions(data);
  };
  return (
    <div className="contact-page container mx-auto ">
      <div className="w-full grid grid-cols-4 lg:grid-cols-8 ">
        <div className="col-span-full lg:col-span-6 lg:col-start-2">
          <h1>Get in touch</h1>
        </div>
        <div className="col-span-full lg:col-span-3 lg:col-start-2  contact-page--left">
          <h2>We're here to help, get in touch</h2>
          <p>
            It will be our pleasure and passion to help you with any translation
            project types and size.
          </p>
          <h3>BUSINESS HOURS</h3>
          <p>
            Monday - Friday <br />
            8.am. - 6.p.m (UTC+3)
            <br />
            <br />
            <span>
              The office is closed due to Covid-19. All staff is working
              remotely until further notice.
            </span>
          </p>
          <h3>CONTACT US</h3>
          <p>info@lugath.com</p>
          <h3>ADDRESS</h3>
          <p>
            Istanbul Teknopark
            <br /> Cube Incubation
          </p>
          <p className="opacity-40">
            Sanayi Mah. Teknopark Bul. No:1/4C/Z08
            <br />
            34906 Pendik <br /> IST
          </p>
        </div>
        <div className="col-span-full lg:col-span-3  contact-page--right">
          <form
            id="ea-form"
            onSubmit={handleSubmit(onSubmit)}
            autoComplete="off"
            noValidate
          >
            <div className="input-wrapper">
              <input
                ref={register({
                  required: {
                    value: true,
                    message: "Your First Name is required.",
                  },
                })}
                id="firstname"
                name="firstName"
                type="text"
                placeholder="First Name"
                required
              />
              <label htmlFor="firstname"></label>

              {errors.firstName && errors.firstName.type === "required" && (
                <p>{errors.firstName.message}</p>
              )}
            </div>
            <div className="input-wrapper">
              <input
                ref={register({
                  required: {
                    value: true,
                    message: "Your Last Name is required.",
                  },
                })}
                name="lastName"
                id="lastname"
                type="text"
                required
                placeholder="Last Name"
              />
              <label htmlFor="lastname"></label>
              {errors.lastName && errors.lastName.type === "required" && (
                <p>{errors.lastName.message}</p>
              )}
            </div>
            <div className="input-wrapper">
              <input
                ref={register}
                name="jobTitle"
                id="jobtitle"
                type="text"
                placeholder="Job Title"
              />
              <label htmlFor="jobtitle"></label>
            </div>
            <div className="input-wrapper">
              <input
                ref={register({
                  required: {
                    value: true,
                    message: "Company Name is required.",
                  },
                })}
                name="companyName"
                id="companyname"
                type="text"
                placeholder="Company Name"
                required
              />
              <label htmlFor="companyname"></label>
              {errors.companyName && errors.companyName.type === "required" && (
                <p>{errors.companyName.message}</p>
              )}
            </div>
            <div className="input-wrapper">
              <input
                ref={register({
                  required: {
                    value: true,
                    message: "Website is required.",
                  },
                  pattern: {
                    // eslint-disable-next-line
                    value:
                      /[(http(s)?):\/\/(www\.)?a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/gi,
                  },
                })}
                name="companyWebsite"
                id="companywebsite"
                type="url"
                placeholder="Company Website"
              />
              <label htmlFor="companywebsite"></label>
              {errors.companyWebsite ? (
                <p>Must be a URL. http://www.example.com</p>
              ) : null}
            </div>
            <div className="input-wrapper">
              <input
                ref={register({
                  required: {
                    value: true,
                    message: "Your Email is required.",
                  },
                  pattern: {
                    // eslint-disable-next-line
                    value:
                      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                  },
                })}
                name="workEmail"
                id="workmemail"
                type="email"
                placeholder="Work E-Mail"
                required
              />
              <label htmlFor="workemail"></label>
              {errors.workEmail ? (
                <p>Must be valid email example@yourdomain.com.</p>
              ) : null}
            </div>
            <input
              id="toName"
              name="toName"
              type="hidden"
              value="Orcun or Burak"
            />
            <div className="input-wrapper">
              <input
                ref={register}
                name="highestRanking"
                id="highestranking"
                type="text"
                placeholder="Describe your translation needs"
              />
              <label htmlFor="highestranking"></label>
            </div>
            <button type="submit" className="bg-teal mt-12 mb-8">
              Submit
            </button>
            <span className="terms-of-use-text">
              By clicking “Submit”, I agree to Lugath’s{" "}
              <Link to="/privacy-policy">Privacy Policy</Link>
            </span>
          </form>
        </div>
      </div>
    </div>
  );
}
