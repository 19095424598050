import React, { useState, useCallback, useEffect } from "react";
import MenuSm from "../MenuSm";
import { Link, useLocation } from "react-router-dom";
import "./navbar.scss";
import { ReactComponent as LugathLogoHeader } from "../../Assets/Icons/logo-t.svg";
import { ReactComponent as PromoPlayIcon } from "../../Assets/Icons/promo-play-icon.svg";
import { menuData } from "../../Services/DataService";
import Image from "../Image";
import { ReactComponent as ChevronDown } from "../../Assets/Icons/chevron-down.svg";
import { ReactComponent as LoginRegisterIcon } from "../../Assets/Icons/login_register.svg";
import { getCookie, isInViewport, setCookie } from "../../Libs/utils";
import MarqueeComponent from "../MarqueeComponent";
import CONFIG from "../../Services/ConfigService";
const marqueeClosedByUser = getCookie(CONFIG.APP.MARQUEE_COOKIE_KEY_NAME);
export default function Navbar() {
  const { pathname } = useLocation();
  const languageOptions = CONFIG.APP.AVAILABLE_LANGUAGES;
  const [selectedLanguage, setSelectedLanguage] = useState("EN");
  const [mode, setMode] = useState("navbar-wrapper--light");
  const [showHeader, setShowHeader] = useState(true);
  const [showSmMenu, setshowSmMenu] = useState(false);
  const [showSubMenu, setShowSubMenu] = useState(false);
  const [logoAnime, setLogoAnime] = useState(true);
  const [y, setY] = useState(window?.scrollY ? window?.scrollY : 0);
  const [marqueeIsVisible, setMarqueeIsVisible] = useState(
    CONFIG.APP.MARQUEE_VISIBILITY &&
      (marqueeClosedByUser === "false" || marqueeClosedByUser === null)
      ? true
      : false
  );

  const onClickSelectItem = (item) => {
    setSelectedLanguage(item);
  };

  const getNavBarClassState = () => {
    return `navbar-wrapper ${mode} ${showHeader ? "show" : "hide"}`;
  };

  const onClickCloseMarquee = () => {
    setCookie(
      CONFIG.APP.MARQUEE_COOKIE_KEY_NAME,
      "true",
      CONFIG.APP.MARQUEE_COOKIE_EXPIRE_IN_DAYS
    );
    setMarqueeIsVisible(false);
  };

  const setHeaderMode = () => {
    const elements = [
      ...document.getElementsByClassName("js-section_nav-dark"),
    ];
    const backgroundComponents = elements.map((el) => {
      return isInViewport(el, 75);
    });
    if (backgroundComponents.includes(true)) {
      setMode("navbar-wrapper--dark");
    } else {
      setMode("navbar-wrapper--light");
    }
  };

  const onScroll = () => {
    const elements = [
      ...document.getElementsByClassName("js-section_nav-dark"),
    ];
    const test = elements.map((el) => {
      return isInViewport(el, 75);
    });
    if (test.includes(true)) {
      setMode("navbar-wrapper--dark");
    } else {
      setMode("navbar-wrapper--light");
    }
  };

  const handleNavigation = useCallback(
    (e) => {
      const window = e.currentTarget;
      if (y > window.scrollY) {
        setShowHeader(true);
      } else if (y < window.scrollY) {
        setShowHeader(false);
      }
      setY(window.scrollY);
    },
    [y]
  );

  useEffect(() => {
    document.addEventListener("scroll", onScroll);
    if (pathname) {
      setHeaderMode();
    }

    return () => {
      document.removeEventListener("scroll", onScroll);
    };
  }, [pathname]);

  useEffect(() => {
    setY(window.scrollY);
    window.addEventListener("scroll", handleNavigation);

    return () => {
      window.removeEventListener("scroll", handleNavigation);
    };
  }, [handleNavigation]);

  return (
    <>
      <MenuSm
        setshowSmMenu={setshowSmMenu}
        showSmMenu={showSmMenu ? true : false}
      ></MenuSm>
      <div className={getNavBarClassState()}>
        {marqueeIsVisible ? (
          <MarqueeComponent
            onClickCloseMarquee={onClickCloseMarquee}
            marqueeIsVisible={marqueeIsVisible}
          />
        ) : null}
        <div className="navbar container mx-auto">
          <div className="navbar-inner">
            <div className={`navbar-logo`}>
              <Link reloadDocument={CONFIG.APP.HARD_ROUTE_CHANGE} to="/">
                <LugathLogoHeader
                  className={`${logoAnime ? "animate" : ""} `}
                />
              </Link>
            </div>
            <div className="navbar-links">
              {menuData
                .filter((item) => item.showInDesktop === true)
                .map((link, index) => {
                  return (
                    <div
                      key={index}
                      className={`navbar-links__item ${
                        !showSubMenu ? "hide" : ""
                      }`}
                      onMouseEnter={() => setShowSubMenu(true)}
                      //onMouseLeave={() => hideSubMenu(link)}
                    >
                      <Link
                        reloadDocument={CONFIG.APP.HARD_ROUTE_CHANGE}
                        key={link.id}
                        to={
                          link.isExternal ? { pathname: link.slug } : link.slug
                        }
                        target={link.isExternal ? "_blank" : ""}
                      >
                        {link.showIcon ? (
                          <span className="icon-login">
                            <svg
                              width="14px"
                              height="19px"
                              viewBox="0 0 14 19"
                              version="1.1"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M7.00814901,9 C3.14361795,9.00697179 0.011208729,12.2566069 0,16.270419 L0,19 L14,19 L14,16.270419 C13.9888356,12.2631905 10.8663153,9.01626338 7.00814901,9 Z M12.2520373,17.1802793 L1.74796275,17.1802793 L1.74796275,16.270419 C1.74796275,13.2577442 4.09937992,10.8154888 7,10.8154888 C9.90062008,10.8154888 12.2520373,13.2577442 12.2520373,16.270419 L12.2520373,17.1802793 Z"
                                id="Shape"
                              ></path>
                              <path
                                d="M7,8 C9.209139,8 11,6.209139 11,4 C11,1.790861 9.209139,0 7,0 C4.790861,0 3,1.790861 3,4 C3.0051207,6.20701445 4.79298555,7.9948793 7,8 Z M7,2.00232285 C7.81071061,2.0004451 8.54255402,2.48760514 8.85367009,3.23624527 C9.16478616,3.98488539 8.9937716,4.84725057 8.42051109,5.42051109 C7.84725057,5.9937716 6.98488539,6.16478616 6.23624527,5.85367009 C5.48760514,5.54255402 5.0004451,4.81071061 5.00232285,4 C5.00742636,2.89883498 5.89883498,2.00742636 7,2.00232285 Z"
                                id="Shape"
                              ></path>
                            </svg>
                          </span>
                        ) : null}
                        <span>{link.title}</span>
                        {link.sublinks.length > 0 ? (
                          <span className="arrow-down">
                            <ChevronDown />
                          </span>
                        ) : null}
                      </Link>
                      {link.sublinks.length > 0 ? (
                        <div className={`w-full bg-white sub-menu-wrapper`}>
                          <div className={`sub-menu-inner `}>
                            <div className="info">
                              {/* title: {link.title} id : {link.id} activeParent:{" "}
                              {activeParent} hasSubs:{link.sublinks.length}
                              JSON.stringify(link)*/}
                            </div>
                            <div className="container mx-auto">
                              <div className={`sub-menu-inner__content`}>
                                <div className="sub-menu-inner__content-menu">
                                  <ul className="sub-menu-inner__content-menu__list">
                                    {link?.sublinks
                                      .filter(
                                        (item) => item.showInDesktop === true
                                      )
                                      .map((l, i) => {
                                        return (
                                          <li
                                            className="sub-menu-inner__content-menu__list-item__wrapper"
                                            key={l.id}
                                          >
                                            {l.isExternal ? (
                                              <a
                                                target="_blank"
                                                rel="noreferrer"
                                                className="sub-menu-inner__content-menu__list-item"
                                                onClick={() =>
                                                  setShowSubMenu(false)
                                                }
                                                href={l.slug}
                                              >
                                                {l?.showIcon || l?.showImage ? (
                                                  <div className="sub-menu-inner__content-menu__list-item__icon">
                                                    <>
                                                      {l?.showIcon ? (
                                                        <Image
                                                          width={24}
                                                          height={24}
                                                          fileName={`Assets/Icons/${l.icon}`}
                                                        />
                                                      ) : null}
                                                      {l?.showImage ? (
                                                        <Image
                                                          width={64}
                                                          height={64}
                                                          fileName={`Assets/Images/Products/${l.image}`}
                                                        />
                                                      ) : null}
                                                    </>
                                                  </div>
                                                ) : null}
                                                {l?.status ? (
                                                  <div
                                                    className={`sub-menu-inner__content-menu__list-item__content-status sub-menu-inner__content-menu__list-item__content-status--${l.status.alias}`}
                                                  >
                                                    {l.status.label}
                                                  </div>
                                                ) : null}
                                                <div className="sub-menu-inner__content-menu__list-item__content">
                                                  <div className="sub-menu-inner__content-menu__list-item__content-name">
                                                    {l.title}
                                                  </div>
                                                  <div className="sub-menu-inner__content-menu__list-item__content-desc">
                                                    {l.alias}
                                                  </div>
                                                </div>
                                              </a>
                                            ) : (
                                              <Link
                                                reloadDocument={
                                                  CONFIG.APP.HARD_ROUTE_CHANGE
                                                }
                                                className="sub-menu-inner__content-menu__list-item"
                                                target={
                                                  l.isExternal ? "_blank" : ""
                                                }
                                                onClick={() =>
                                                  setShowSubMenu(false)
                                                }
                                                to={{ pathname: l.slug }}
                                              >
                                                {l?.showIcon || l?.showImage ? (
                                                  <div className="sub-menu-inner__content-menu__list-item__icon">
                                                    <>
                                                      {l?.showIcon ? (
                                                        <Image
                                                          width={24}
                                                          height={24}
                                                          fileName={`Assets/Icons/${l.icon}`}
                                                        />
                                                      ) : null}
                                                      {l?.showImage ? (
                                                        <Image
                                                          width={64}
                                                          height={64}
                                                          fileName={`Assets/Images/Products/${l.image}`}
                                                        />
                                                      ) : null}
                                                    </>
                                                  </div>
                                                ) : null}
                                                {l?.status ? (
                                                  <div
                                                    className={`sub-menu-inner__content-menu__list-item__content-status sub-menu-inner__content-menu__list-item__content-status--${l.status.alias}`}
                                                  >
                                                    {l.status.label}
                                                  </div>
                                                ) : null}
                                                <div className="sub-menu-inner__content-menu__list-item__content">
                                                  <div className="sub-menu-inner__content-menu__list-item__content-name">
                                                    {l.title}
                                                  </div>
                                                  <div className="sub-menu-inner__content-menu__list-item__content-desc">
                                                    {l.alias}
                                                  </div>
                                                </div>
                                              </Link>
                                            )}
                                          </li>
                                        );
                                      })}
                                  </ul>
                                </div>
                                <div className="sub-menu-inner__content-promo">
                                  <div className="sub-menu-inner__content-promo__box">
                                    <div className="sub-menu-inner__content-promo__box-video">
                                      <Link
                                        reloadDocument={
                                          CONFIG.APP.HARD_ROUTE_CHANGE
                                        }
                                        to={"/tutorials"}
                                      >
                                        <PromoPlayIcon />
                                      </Link>
                                    </div>
                                    <div className="sub-menu-inner__content-promo__box-content">
                                      <div className="sub-menu-inner__content-promo__box-content__title">
                                        Get learn{" "}
                                        <span style={{ color: "#47ccdf" }}>
                                          lugath
                                        </span>{" "}
                                        today!
                                      </div>
                                      <div className="sub-menu-inner__content-promo__box-content__desc">
                                        Lugath automates web, mobile, or game
                                        app localization for global growth with
                                        generative AI.
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      ) : null}
                    </div>
                  );
                })}
            </div>
            <div className="navbar-app-links">
              <a
                className="focus:ring-4 focus:outline-none focus:ring-blue-300"
                href={`${
                  CONFIG.APP.REGISTER_URL
                }?utm_source=${encodeURIComponent(
                  window.location.href
                )}&utm_medium=referral&utm_content=js_header_login_btn`}
                target="_blank"
                rel={"noopener"}
              >
                Login
                <LoginRegisterIcon />
              </a>
            </div>
            <div className="navbar-lang">
              <div className="select">
                <a
                  href="#"
                  className="current focus:ring-4 focus:outline-none focus:ring-blue-300"
                >
                  <div className="option">
                    <span>{selectedLanguage}</span>
                    <span className="arrow-down">
                      <ChevronDown />
                    </span>
                  </div>
                </a>

                <div className="options">
                  <ul id="language-box">
                    {languageOptions.map((option, index) => {
                      return (
                        <li
                          key={index}
                          onClick={() => onClickSelectItem(option)}
                        >
                          <div
                            className={
                              option === selectedLanguage
                                ? `option selected`
                                : `option`
                            }
                          >
                            <span>{option}</span>
                          </div>
                        </li>
                      );
                    })}
                  </ul>
                </div>
              </div>
            </div>
            <div className="navbar-toggle  lg:hidden">
              <div
                onClick={() => setshowSmMenu(!showSmMenu)}
                className="navbar-toggle--icon cursor-pointer relative"
              >
                <div className={`menu-icon ${showSmMenu ? "active" : ""}`}>
                  <div>
                    <span></span>
                    <span></span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
