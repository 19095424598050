export const integrations = [
  {
    id: 0,
    title: "Dropbox",
    children: [
      {
        id: 1,
        question: "Which file formats are supported?",
        answer:
          "We are supporting <b>docx, doc, xlsx, xls, pptx</b> and many more. You can check the full list from  <a href='https://docs.lugath.com' target='_blank'>API Doc</a>.",
      },
      {
        id: 2,
        question: "Which languages are supported?",
        answer:
          "We are supporting <b>27 languages</b> and <b>more than 700</b> language pairs. You can check the full list from  <a href='https://docs.lugath.com' target='_blank'>API Doc</a>.",
      },
      {
        id: 3,
        question: "Can I use a Glossary?",
        answer:
          "To get a term consistent translation results, you should define your glossaries. Try cross-engine applicable glossary.  </br><a href='/products/glossary'>Learn How</a>",
      },
      {
        id: 4,
        question: "Can I use a Translation Memory?",
        answer:
          "If you need to re-use already translated or translator edited sentences again, create an Translation Memory.  </br><a href='/products/ai-translation-memory'>Learn How</a>",
      },
      {
        id: 5,
        question: "How can I share my files to get contribution?",
        answer:
          "You may want to share your files with translators or subject matter experts to get a contribution. Private shared link is here for you.  </br><a href='/products/collaborative-translation'>Learn How</a>",
      },
      {
        id: 6,
        question: "Is it possible to create an approval workflow?",
        answer:
          "You can monitor all the changes and check the quality of <b>translation, localization and contribution</b>. </br><a href='/products/workflow-management'>Learn How</a>",
      },
      {
        id: 7,
        question: "If I am faced with any problem, what should I do?",
        answer:
          "Visit our <a href='/faqs'>FAQs</a>, create a support ticket or send an email to <a\n" +
          '              className="external-link"\n' +
          '              href="mailto:support@lugath.com"\n' +
          '              target="_blank"\n' +
          '              rel="noreferrer"\n' +
          "            >\n" +
          "              support@lugath.com\n" +
          "            </a>",
      },
    ],
  },
  /*{
      id: 1,
      title: "Zendesk",
      children: [
        {
          id: 1,
          question: "How can I translate my content?",
          answer:
            "Very simple, <a href='get-early-access'>register</a> and upload your contents and translate directly or create your application from <b>Lugath portal</b>. <b>download our SDKs</b> and start to communicate with our cloud-based and scalable APIs. Here is the <a href='https://docs.lugath.com' class='inline-link'>documentation.</a>",
        },
        {
          id: 2,
          question: "Can I edit my TM content in Lugath?",
          answer:
            "No, you can not. Lugath can <b>manage</b> your <b>translation memory automatically</b>. If you make post-edit on the machine translated content, TM keeps the changes.",
        },
        {
          id: 3,
          question:
            "What if I need more assistance for machine translated contents?",
          answer:
            "Lugath support <b>private shared link</b> feature. You can <b>share your machine translated content</b> with any translator or your colleague easily for making post-edit or proofread.",
        },
        {
          id: 4,
          question: "Can I use Lugath with as a company wide platform?",
          answer:
            "Yes, Lugath support <b>team creation</b> feature. You can define user accounts for your colleagues in the same team or for different departments.",
        },
      ],
    },
    {
      id: 2,
      title: "Zapier",
      children: [
        {
          id: 1,
          question: "How is the pricing?",
          answer:
            "We are defining translation characters credit for monthly or yearly.<br/><br/>You can <a href='/contact' class='inline-link'>contact with us</a> for more details.",
        },
        {
          id: 2,
          question: "How can I start using Lugath?",
          answer: "<a href='get-early-access'>Schedule a Demo</a>",
        },
      ],
    },*/
];
