import React from "react";
import { Provider } from "react-redux";
import { syncHistoryWithStore } from "react-router-redux";
import { BrowserRouter as Router } from "react-router-dom";
import ReactDOM from "react-dom";
import ReactGA from "react-ga";
import Routes from "./Routes/";
import StoreService from "./Services/StoreService";
//import LanguageService from "./Services/LanguageService";
import { I18nextProvider } from "react-i18next";
import i18next from "i18next";
import "./Assets/Styles/main.scss";

const store = StoreService.setup();
const history = syncHistoryWithStore(StoreService.browserHistory, store);

ReactGA.initialize("G-RS3YKJ2B8Q");

store.subscribe(() => {
  localStorage.setItem(
    "lugath.website.storage",
    JSON.stringify(store.getState())
  );
});

history.listen((location, action) => {
  // location is an object like window.location
});

const renderApp = () =>
  ReactDOM.render(
    <I18nextProvider i18n={i18next}>
      <Provider store={store}>
        <Router history={history}>
          <Routes {...{ store, history }} isUserValid={true} />
        </Router>
      </Provider>
    </I18nextProvider>,
    document.getElementById("root")
  );

setTimeout(() => {
  renderApp();
}, 500);
