import React from "react";
import { Link } from "react-router-dom";
import { ReactComponent as LugathLogo } from "../../../Assets/Icons/logo-t.svg";
import box1Image from "../../../Assets/Images/box-1.png";
import box2Image from "../../../Assets/Images/box-2.png";
import box3Image from "../../../Assets/Images/box-3.png";
import { ReactComponent as ShareIcon } from "../../../Assets/Icons/share.svg";
import { ReactComponent as ReviewIcon } from "../../../Assets/Icons/review.svg";
import { ReactComponent as TranslateIcon } from "../../../Assets/Icons/translate.svg";
import { ReactComponent as UploadIcon } from "../../../Assets/Icons/code.svg";
import { ReactComponent as PublishIcon } from "../../../Assets/Icons/publish.svg";
import featureImg2 from "../../../Assets/Images/feature-2.png";
import featureImg3 from "../../../Assets/Images/feature-3.png";
import "./index.scss";

export default function ProductSpecific() {
  return (
    <section className="product-specific-bg js-section_nav-dark">
      <div className="container mx-auto product-specific__container">
        <div className="product-specific__wrapper">
          <div className="product-specific__title-wrapper">
            <div className="product-specific__title">
              Integrate localization into{" "}
              <span className="highlight">continuous</span> product{" "}
              <span className="highlight">development</span>
            </div>
          </div>
          <div className="product-specific__inner">
            <div className="parent">
              <div className="div12">
                <div className="div12__wrapper"></div>
              </div>
              <div className="div1">
                <div className="div1__wrapper">
                  <div className="div1-icon">
                    <UploadIcon />
                  </div>
                  {/*<div className="div1-image">
                    <img src={box1Image} />
                  </div> */}
                </div>
              </div>
              <div className="div2">
                <div className="div2__wrapper">
                  <div className="div2__content">
                    Supply your content with API or platform.{" "}
                  </div>
                </div>
              </div>
              <div className="div3">
                <div className="div1__wrapper">
                  {" "}
                  <div className="div1-icon">
                    <TranslateIcon />
                  </div>
                  {/*<div className="div1-image">
                    <img src={box2Image} />
                </div>*/}
                </div>
              </div>
              <div className="div4">
                <div className="div2__wrapper">
                  <div className="div2__content">
                    Automatically Translate content with context for more
                    accurate with AI.
                  </div>{" "}
                </div>
              </div>
              <div className="div5">
                <div className="div1__wrapper">
                  {" "}
                  <div className="div1-icon">
                    <ReviewIcon />
                  </div>
                  {/*<div className="div1-image">
                    <img src={box3Image} />
              </div>*/}{" "}
                </div>
              </div>
              <div className="div6">
                <div className="div2__wrapper">
                  <div className="div2__content">
                    Review the best possible results and it is now ready to use.
                  </div>{" "}
                </div>
              </div>
              <div className="div7">
                <div className="div1__wrapper">
                  {" "}
                  <div className="div1-icon">
                    <ShareIcon />
                  </div>{" "}
                </div>
              </div>
              <div className="div8">
                <div className="div2__wrapper">
                  <div className="div2__content">
                    Share with contributors if you need more with creative
                    touches.
                  </div>{" "}
                </div>
              </div>
              <div className="div9">
                <div className="div9__wrapper">
                  <div className="div2__content">
                    Now all you have to do is publish and continue.
                  </div>{" "}
                </div>
              </div>
              <div className="div10">
                <div className="div2__wrapper">
                  <div className="div2__logo">
                    <LugathLogo />
                  </div>
                </div>
              </div>
              <div className="div11">
                <div className="div11__wrapper"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
