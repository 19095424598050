import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { allNews } from "../../Reducers/news";
import { setActiveNew } from "../../Reducers/news";
import { useParams, useNavigate } from "react-router-dom";

import "./news_detail.scss";

export default function NewsDetail(props) {
  let { slug } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const activeNew = useSelector((state) => state.news);

  React.useEffect(() => {
    Promise.all([dispatch(allNews()), dispatch(setActiveNew(slug))]);
  }, [dispatch, slug]);

  if (activeNew.length === 0) {
    navigate("/error");
  }

  return activeNew.length ? (
    <div className="news-detail-page">
      <div className="container mx-auto grid grid-cols-4 lg:grid-cols-8 news-detail-page--heading">
        <div className="col-span-full lg:col-span-6 lg:col-start-2">
          <h2>News</h2>
          <h3>{activeNew[0].title}</h3>
        </div>
      </div>
      <div className="w-full bg-white news-detail-page--content">
        <div className="container mx-auto">
          <div className="grid grid-cols-4 lg:grid-cols-8">
            <div className="col-span-full lg:col-span-6  lg:col-start-2 text-realblack">
              <h1>{activeNew[0].innerTitle}</h1>
              <p>{activeNew[0].firstBlock}</p>
            </div>
          </div>
          {activeNew[0].secondBlock ? (
            <div className="grid-container">
              <div className="col-span-4 row-span-2 lg:row-span-3 row-start-1 col-start-1 grid grid-cols-4 grid-rows-2 lg:grid-rows-3">
                <div className="box col-start-3 lg:flex flex-col items-center justify-center hidden">
                  <p className="text-5xl font-eina03">300 +</p>
                  <p className="text-base font-eina03">Erişim</p>
                </div>
                <div className="box col-start-2">2</div>
                <div className="box teal col-start-4">3</div>
              </div>
              <div className="col-span-4 row-span-3 grid grid-cols-4 grid-rows-3">
                <div className="box col-start-3 lg:col-start-2">4</div>
                <div className="box col-start-4 lg:col-start-3 row-start-2">
                  5
                </div>
                <div className="box col-start-1 row-start-2 lg:row-start-3">
                  6
                </div>
                <div className="box col-start-2 row-start-3">7</div>
              </div>
              <div className="col-span-full"></div>
            </div>
          ) : (
            <div>
              {" "}
              <br /> <br /> <br />{" "}
            </div>
          )}
          <div className="grid grid-cols-4 lg:grid-cols-8">
            <div className="col-span-full lg:col-span-6  lg:col-start-2 text-realblack">
              <p
                dangerouslySetInnerHTML={{
                  __html:
                    activeNew[0].thirdBlock +
                    (activeNew[0].ownerTitle ? activeNew[0].ownerTitle : ""),
                }}
              ></p>

              <br />
              <br />
              <br />
              <p
                className="last-heading"
                dangerouslySetInnerHTML={{ __html: activeNew[0].lastHeading }}
              ></p>
              <br></br>
              <p
                dangerouslySetInnerHTML={{ __html: activeNew[0].lastContent }}
              ></p>
            </div>
          </div>
        </div>
      </div>
    </div>
  ) : null;
}
