import { Children, createElement, cloneElement } from "react";
import Scroll from "react-scroll";
import { isElement } from "react-dom/test-utils";
import { isFunction } from "lodash";

export const extendChildrenProps = (children, props) => {
  if (isFunction(children)) return createElement(children, props);
  return Children.map(children, (child) =>
    isElement(child) ? cloneElement(child, props) : child
  );
};

export const sendEvent = (ga, category, action, label) => {
  ga &&
    ga("send", "event", {
      eventCategory: category,
      eventAction: action,
      eventLabel: label,
    });
};

export async function getSenderIP() {
  var requestOptions = {
    method: "GET",
    redirect: "follow",
  };
  const response = await fetch(
    "https://ipgeolocation.abstractapi.com/v1/?api_key=824753e2d40b4c1097c4ae7e4c2f3fe5",
    requestOptions
  );

  if (!response.ok) {
    const message = `An error has occured: ${response.status}`;
    throw new Error(message);
  }

  const result = await response.json();
  return result;
}

export const setCookie = (name, value, days) => {
  var expires = "";
  if (days) {
    var date = new Date();
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
    expires = "; expires=" + date.toUTCString();
  }
  document.cookie = name + "=" + (value || "") + expires + "; path=/";
};

export const getCookie = (name) => {
  var nameEQ = name + "=";
  var ca = document.cookie.split(";");
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) == " ") c = c.substring(1, c.length);
    if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
  }
  return null;
};

export const deleteCookie = (name) => {
  document.cookie = name + "=; expires=Thu, 01 Jan 1970 00:00:01 GMT;";
};

export const arrangeChangeLogsCookieByResults = () => {
  const cookieExpirationDays = 3;
};

export async function sendToHubSpot(data) {
  const portalID = "25587952";
  const hubspotForms = {
    development: {
      registration: {
        formID: "094b61eb-fcc5-4d12-a653-c496f8b1e7eb",
        pageId: "",
        pageUri: "",
        pageName: "",
      },
      newsletter: {
        formID: "",
      },
    },
    production: {
      registration: {
        formID: "2819c19c-1bf1-45c1-a868-cf2546cbe7c1",
      },
      newsletter: {
        formID: "",
      },
    },
  };

  const envOptions =
    process.env.NODE_ENV === "development"
      ? hubspotForms.development
      : hubspotForms.production;

  const hubSpotPOSTUrl = `https://api.hsforms.com/submissions/v3/integration/submit/${portalID}/${
    envOptions[data.formType].formID
  }`;

  const myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");

  const ipAddressCall = await getSenderIP();

  const raw = JSON.stringify({
    context: {
      ...data.context,
      ...{
        ipAddress: ipAddressCall.ip_address
          ? ipAddressCall.ip_address
          : "UNKNOWN",
      },
    },
    fields: [
      {
        objectTypeId: "0-1",
        name: "email",
        value: data.workEmail,
      },
      {
        objectTypeId: "0-1",
        name: "firstname",
        value: data.firstName,
      },
      {
        objectTypeId: "0-1",
        name: "lastname",
        value: data.lastName,
      },
      {
        objectTypeId: "0-1",
        name: "title",
        value: data.jobCategory,
      },
      {
        objectTypeId: "0-1",
        name: "jobtitle",
        value: data.jobTitle,
      },
      {
        objectTypeId: "0-2",
        name: "name",
        value: data.companyName,
      },
      {
        objectTypeId: "0-2",
        name: "domain",
        value: data.companyWebsite,
      },
      {
        objectTypeId: "0-1",
        name: "describe_your_translation_needs",
        value: data.highestRanking,
      },
    ],
  });

  const requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: raw,
    redirect: "follow",
  };

  return await fetch(hubSpotPOSTUrl, requestOptions)
    .then((response) => {
      if (!response.ok) {
        throw new Error("Bad response from server");
      }
      return response;
    })
    .then((returnedResponse) => {
      return returnedResponse.text();
    })
    .catch((error) => {});
}

export const getOffsetTop = (el) => {
  let _x = 0;
  let _y = 0;

  while (el && !isNaN(el.offsetLeft) && !isNaN(el.offsetTop)) {
    _x += el.offsetLeft - el.scrollLeft;
    _y += el.offsetTop - el.scrollTop;
    el = el.offsetParent;
  }

  return { top: _y, left: _x };
};

export const scrollDirection = (lastScrollTop, pageYOffset) => {
  const st = pageYOffset;
  if (st > lastScrollTop) {
    return "down";
  } else if (st < lastScrollTop) {
    return "up";
  }
  lastScrollTop = st <= 0 ? 0 : st;
};

export const isInViewport = (element, threshold = 200) => {
  return (
    getOffsetTop(element).top - threshold < window.scrollY &&
    window.scrollY <
      element.getClientRects()[0].height +
        (getOffsetTop(element).top - threshold)
  );
};

export const scrollToPosition = (position, duration) => {
  Scroll.animateScroll.scrollTo(position, {
    delay: 200,
    duration: duration,
    smooth: true,
  });
};

export const getURLParams = (string) => {
  const search = string.substring(1);
  return search.length > 0
    ? JSON.parse(
        '{"' + search.replace(/&/g, '","').replace(/=/g, '":"') + '"}',
        function (key, value) {
          return key === "" ? value : decodeURIComponent(value);
        }
      )
    : {};
};

export const dynamicParse = (str, data) => {
  return str.replace(/\{\{(.*?)\}\}/g, (match, token) => {
    return data[token];
  });
};

export const scrollToElement = (id, tolerance, time) => {
  const offsetTolerance = tolerance || 0;
  const duration = time || 200;

  const position =
    getOffsetTop(document.getElementById(id)).top - offsetTolerance;
  Scroll.animateScroll.scrollTo(position, {
    delay: 200,
    duration: duration,
    smooth: true,
  });
};
