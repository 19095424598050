import React, { useRef, useEffect } from "react";
import { useDispatch } from "react-redux";
import { scrollToElement } from "../../../Libs/utils";
import browserImage from "../../../Assets/Images/browser-dark.svg";
import appImage from "../../../Assets/Images/dashboard.svg";
import connectImage from "../../../Assets/Images/cover.svg";
import CTABtnWrapper from "../../CTABtnWrapper";
import Gridder from "../../Gridder";
import { showFormPopUp } from "../../../Reducers/settings";
import CustomerLogos from "../../../Components/HomePageComponents/CustomerLogos";
import Marquee from "react-fast-marquee";
import VideoCover from "react-video-cover";
import { ReactComponent as PlayIcon } from "../../../Assets/Icons/play.svg";
import "./index.scss";

import { getOffsetTop } from "../../../Libs/utils";

export default function FirstSection() {
  const dispatch = useDispatch();
  const videoRef = useRef(null);
  const cta = [
    {
      label: "Get Started",
      action: "register",
      button: (label) => (
        <button
          type="button"
          className="hero-header__content-cta__get-started  text-dark focus:ring-4 focus:outline-none focus:ring-blue-300 font-bold text-lg text-center inline-flex items-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
        >
          {label}
        </button>
      ),
    },
    {
      label: "View Tutorials",
      action: "view-tutorials",
      button: (label) => (
        <button
          type="button"
          className="hero-header__content-cta__view-demo  text-white focus:ring-4 focus:outline-none focus:ring-blue-300 font-bold text-lg text-center  items-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
        >
          {label}
          <PlayIcon />
        </button>
      ),
    },
  ];
  const onClickExplore = () => {
    scrollToElement("third-section_home", 0, 375);
  };
  const onClickBtn = () => {
    dispatch(showFormPopUp());
  };

  useEffect(() => {
    if (videoRef.current) {
      setTimeout(() => {
        videoRef.current.muted = true;
        videoRef.current.loop = true;
        videoRef.current.play();
      }, 1000);
    }
  }, [videoRef]);

  const onScroll = () => {
    const st = window.pageYOffset;
    const range =
      getOffsetTop(document.getElementsByClassName("shadow-anchor")[0]).top -
      window.innerHeight;
    const opacityVal = 1 - st / (range > 0 ? range : range * -1);
    document.getElementsByClassName("shadow")[0].style.opacity = opacityVal;
    if (opacityVal < -0.2) {
      document
        .getElementsByClassName("mobile-cta--get-access")[0]
        .classList.add("light");
    } else {
      document
        .getElementsByClassName("mobile-cta--get-access")[0]
        .classList.remove("light");
    }
  };

  React.useEffect(() => {
    /*getOffsetTop(document.getElementsByClassName("shadow-anchor")[0]);
    document.addEventListener("scroll", onScroll);

    return () => {
      document.removeEventListener("scroll", onScroll);
    };*/
  }, []);

  return (
    <>
      <div className="bg-hero-header">
        <div className="hero-header">
          {/* <div className="hero-header__video">
              <VideoCover
                videoOptions={{
                  autoPlay: true,
                  loop: true,
                  muted: true,
                  playsInline: true,
                  ref: videoRef,
                  src: "https://s3.us-west-2.amazonaws.com/docs.lugath.com/static/videos/cloner-city-copy.mp4",
                }}
              />
            </div>*/}
          {/* <div className="bg-hero-header-animation">
            <Gridder />
        </div>*/}

          <div className="hero-header__content">
            <h1>
              Centralize your{" "}
              <span style={{ color: "#47ccdf" }}>localization,</span>
              <br /> Simplify your{" "}
              <span style={{ color: "#47ccdf" }}>work</span>
            </h1>
            <h2>
              Everything your localization needs to bring together people,
              tools, and work.
            </h2>
            <div className="hero-header__content-cta__wrapper">
              {cta.map((c, i) => (
                <CTABtnWrapper
                  key={`cta-btn-key-${i}`}
                  options={{
                    formPopupTheme: "light",
                  }}
                  children={c.button(c.label)}
                  data={{
                    ...c,
                  }}
                ></CTABtnWrapper>
              ))}
            </div>
          </div>
          <CustomerLogos />
        </div>
      </div>
    </>
  );
}
