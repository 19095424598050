import React from "react";
import { Link } from "react-router-dom";
import "./news.scss";

export default function New({ item }) {
  return (
    <div
      key={item.id}
      className="lg:col-start-2 col-span-full lg:col-span-6 grid grid-cols-4 lg:grid-cols-6 items-center news-item relative"
    >
      <div className="date justify-self-start">
        <p>{item.date}</p>
      </div>
      <div className="news col-span-3 lg:col-span-5">
        <h4>{item.title}</h4>
      </div>
      <Link to={"/news/" + item.slug}></Link>
    </div>
  );
}
