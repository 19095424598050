import React, { useState, useEffect } from "react";
import _ from "lodash";
import { Link } from "react-scroll";
import Image from "../../Components/Image";
import { ReactComponent as SearchIcon } from "../../Assets/Icons/search.svg";
import { ReactComponent as CheckBoxCheckedIcon } from "../../Assets/Icons/checked-icon.svg";
import { appsData } from "../../Services/DataService";
import "./index.scss";
import { useNavigate } from "react-router";

const appsDataGrouped = _.chain(appsData)
  .filter((a) => a.enable)
  .groupBy(function (item) {
    return item.category;
  })
  .map((value, key) => {
    return {
      category: key,
      categoryLabel: value[0].categoryLabel,
      items: value,
    };
  })
  .sortBy((o) => o.category)
  .value();

const defaultFilters = _.chain(appsData)
  .filter((a) => a.enable)
  .groupBy(function (item) {
    return item.status.alias;
  })
  .map((value, key) => {
    return {
      name: key,
      label: value[0].status.label,
      active: false,
    };
  })
  .value();

export default function Integrations() {
  const navigate = useNavigate();
  const [searchValue, setSearchValue] = useState("");
  const [showSmMenu, setshowSmMenu] = useState(false);
  const [activeFilters, setActiveFilters] = useState(defaultFilters);
  const [initialData] = useState(appsData);
  const [visibleItems, setVisibleItems] = useState(appsDataGrouped);

  const onClickAppItem = (item) => {
    if (item.hasPage) {
      navigate(`/integrations/${item.slug}`);
    }
  };

  const onSearchInputChange = (e) => {
    setSearchValue(e.target.value);
  };

  const transformData = (data) => {
    return _.chain(data)
      .filter((a) => a.enable)
      .groupBy((item) => {
        return item.category;
      })
      .map((value, key) => {
        return {
          category: key,
          categoryLabel: value[0].categoryLabel,
          items: value,
        };
      })
      .sortBy((o) => o.category)
      .value();
  };

  const onClickToggleMenu = () => {
    setshowSmMenu(!showSmMenu);
  };

  const onClickActiveFilter = (f) => {
    const updatedActiveFilters = activeFilters.map((filter) =>
      filter.name === f.name ? { ...f, active: !f.active } : filter
    );
    setActiveFilters(updatedActiveFilters);
  };

  const searchInApps = (value) => {
    setVisibleItems(
      transformData(
        initialData.filter((item) => {
          return (
            item.name.toLowerCase().includes(value) ||
            item.categoryLabel.toLowerCase().includes(value) ||
            item.category.toLowerCase().includes(value) ||
            item?.keywords
              ?.map((v) => v?.toLowerCase()?.includes(value))
              .includes(true)
          );
        })
      )
    );
  };
  const filterApps = () => {
    setVisibleItems(
      transformData(
        initialData.filter((item) => {
          return activeFilters
            .filter((e) => e.active)
            .map((a) => a.name)
            .includes(item.status.alias);
        })
      )
    );
  };

  useEffect(() => {
    if (searchValue.length) {
      setActiveFilters(defaultFilters);
      searchInApps(searchValue);
    } else {
      setVisibleItems(appsDataGrouped);
    }
  }, [searchValue]);

  useEffect(() => {
    if (
      activeFilters.length &&
      activeFilters?.map((a) => a.active).includes(true)
    ) {
      filterApps();
    } else {
      setVisibleItems(appsDataGrouped);
    }
  }, [activeFilters]);

  return (
    <div className="integrations-page">
      <section className="integrations-page-hero js-section_nav-dark">
        <div className="container mx-auto">
          <div className="integrations-page-heading">
            <div className="parent">
              <div className="div1">
                <div className="integrations-page-hero-title">Lugath apps</div>
                <div className="integrations-page-hero-desc">
                  Enhance your Lugath experience by connecting to your favorite
                  tools and existing workflows.
                </div>
              </div>
              <div className="div2">
                {" "}
                <div className="integrations-page-hero-tips__wrapper">
                  <div className="integrations-page-hero-tips">
                    Don't see what you're looking for? Drop us a line at{" "}
                    <a className="mailto" href="mailto:support@lugath.com">
                      support@lugath.com
                    </a>
                  </div>
                </div>{" "}
              </div>
              <div className="div3">
                <div className="integrations-page-hero__search-wrapper">
                  <div className="integrations-page-hero__search">
                    <div className="integrations-page-hero__search-icon">
                      <SearchIcon />
                    </div>
                    <div className="integrations-page-hero__search-input">
                      <input
                        type="text"
                        placeholder="Search for apps..."
                        onChange={onSearchInputChange}
                        value={searchValue}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="integrations-page-list__wrapper js-section_nav-dark">
        <div className="container mx-auto">
          <div className="parent">
            <div className="div1">
              {" "}
              <div className="integrations-page-list__menu-wrapper">
                <div
                  className="integrations-page-list__menu-toggle lg:hidden"
                  onClick={onClickToggleMenu}
                >
                  <div
                    className={`integrations-page-list__menu-toggle__icon ${
                      showSmMenu ? "active" : ""
                    }`}
                  >
                    <div>
                      <span></span>
                      <span></span>
                    </div>
                  </div>
                  <div className="integrations-page-list__menu-toggle__label">
                    Filters
                  </div>
                </div>
                <div
                  className={`integrations-page-list__menu-inner ${
                    showSmMenu ? "show" : ""
                  }`}
                >
                  <div className="integrations-page-list__menu-filters">
                    <h2 className="integrations-page-list__menu-header">
                      Filters
                    </h2>
                    {activeFilters.map((f, i) => (
                      <div
                        key={`activeFilter-key-${i}`}
                        onClick={() => onClickActiveFilter(f)}
                        className={`integrations-page-list__menu-filters__item ${
                          f.active
                            ? "integrations-page-list__menu-filters__item--checked"
                            : ""
                        }`}
                      >
                        <div className="integrations-page-list__menu-filters__item-icon">
                          <input type="checkbox" />
                          {f.active ? <CheckBoxCheckedIcon /> : null}
                        </div>
                        <div className="integrations-page-list__menu-filters__item-label">
                          {f.label}
                        </div>
                      </div>
                    ))}
                  </div>
                  <ul className="integrations-page-list__menu">
                    <li>
                      {" "}
                      <a className="always-active" href="#">
                        All Apps
                      </a>
                    </li>
                    {appsDataGrouped.map((group, index) => (
                      <li key={`appsDataGrouped-key-${index}`}>
                        <Link
                          to={group.category}
                          spy={true}
                          duration={500}
                          smooth={true}
                          activeClassName={"active"}
                        >
                          {group.categoryLabel}
                        </Link>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
            <div className="div2">
              <div className="integrations-page-list__container">
                {visibleItems.length ? (
                  visibleItems?.map((group, groupIndex) => {
                    return (
                      <div
                        key={`${group.category}-key-${groupIndex}`}
                        className="integrations-page-list"
                        id={`${group.category}`}
                      >
                        <div className="integrations-page-list__header">
                          {group.categoryLabel}
                        </div>
                        <ul className="integrations-page-list__items">
                          {group.items.map((item, itemIndex) => (
                            <li
                              key={`${group.category}-item-key-${itemIndex}`}
                              className="integrations-page-list__item"
                              onClick={() => onClickAppItem(item)}
                            >
                              {item.beta ? (
                                <span className="integrations-page-list__item-beta">
                                  {item.slug}
                                </span>
                              ) : null}
                              <div className="integrations-page-list__item-top">
                                <div className="integrations-page-list__item-image">
                                  <Image
                                    fileName={`Assets/Images/Marketplace/${item.image}`}
                                  />
                                </div>
                                <div className="integrations-page-list__item-content">
                                  <div className="integrations-page-list__item-content-name">
                                    {item.name}
                                  </div>
                                  <div className="integrations-page-list__item-content-author">
                                    by Lugath
                                  </div>
                                </div>
                              </div>
                              <div className="integrations-page-list__item-bottom">
                                <div className="integrations-page-list__item-content">
                                  <div className="integrations-page-list__item-content-desc">
                                    {item.desc}
                                  </div>
                                  <div className="integrations-page-list__item-content-keywords">
                                    <div
                                      className={`integrations-page-list__item-content-keywords__item integrations-page-list__item-content-keywords__item--${item.status.alias}`}
                                    >
                                      {item.status.label}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </li>
                          ))}
                        </ul>
                      </div>
                    );
                  })
                ) : (
                  <div className="integrations-page-list__empty-wrapper">
                    {" "}
                    <div className="integrations-page-list__empty">
                      <div className="integrations-page-list__empty-title">
                        No results match your search.
                      </div>
                      <div className="integrations-page-list__empty-tips">
                        Couldn't you find what you are looking for? Drop us a
                        line at{" "}
                        <a className="mailto" href="mailto:support@lugath.com">
                          support@lugath.com
                        </a>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
