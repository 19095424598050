import React from "react";
import { ReactComponent as CloseIcon } from "../../Assets/Icons/close-icon--alternative.svg";
import { ReactComponent as PlayIcon } from "../../Assets/Icons/icon-play-video.svg";
import Image from "../Image";
import "./index.scss";

export default function BrowserFrame({
  image,
  onClickAction,
  url = "https://app.lugath.com",
  path = "",
  action = false,
}) {
  const onClick = () => {
    if (onClickAction && typeof onClickAction === "function") {
      onClickAction();
    }
  };

  return (
    <>
      <div className="browser-frame__wrapper">
        <div className="browser-frame">
          <div className="browser-frame__top">
            <div className="browser-frame__top-content">
              <div className="browser-frame__top-btns">
                <span></span>
                <span></span>
                <span></span>
              </div>
              <div className="browser-frame__top-bar">
                {url}
                <span className="highlight">{path}</span>
              </div>
              <div className="browser-frame__top-close">
                <CloseIcon />
              </div>
            </div>
          </div>
          <div className="browser-frame__content" onClick={onClick}>
            <Image
              fileName={image.source}
              width={image.width}
              height={image.height}
            />
            {action ? (
              <div className="browser-frame__content-actions">
                <button
                  className="browser-frame__content-actions__btn"
                  onClick={onClick}
                >
                  <PlayIcon />
                  See In Action
                </button>
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </>
  );
}
