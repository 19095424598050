import React from "react";
import Accordion from "../../Components/FaqPageComponents/Accordion";
import FaqPageCategory from "../../Components/FaqPageComponents/Category";
import { questionsData } from "../../Services/DataService";
import "./faq.scss";

export default function Faq() {
  return (
    <div className="faq-page">
      <section className="reports-page-header js-section_nav-dark">
        <div className="container mx-auto">
          <div className="parent">
            <div className="div1">
              <div className="reports-page-header__title">
                We have <span className="shine">Answers.</span>
              </div>
            </div>
            <div className="div2">
              <div className="reports-page-header__content">
                <div className="reports-page-header__content-desc">
                  You have questions. We're here to help. Looking for answers
                  check below.
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="faq-page-container js-section_nav-dark">
        <div className="bg-white w-full">
          <div className="container mx-auto">
            <div className="faq-page-content grid grid-cols-4 lg:grid-cols-8">
              <div className="hidden lg:block lg:row-span-full lg:col-start-2">
                <div className="faq-page-container--categories">
                  <ul>
                    {questionsData.map((category) => {
                      return (
                        <FaqPageCategory
                          key={category.id}
                          categoryTitle={category.title}
                        ></FaqPageCategory>
                      );
                    })}
                  </ul>
                </div>
              </div>
              <div className="col-span-full col-start-1 lg:col-span-4 lg:col-start-4">
                {questionsData.map((category) => {
                  return (
                    <div
                      key={category.id}
                      className="category-group"
                      id={`${category.title}`}
                    >
                      <h2>{category.title}</h2>
                      {category.children.map((child) => {
                        return (
                          <Accordion
                            key={child.id}
                            question={child.question}
                            answer={child.answer}
                          />
                        );
                      })}
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
