import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { allNews } from "../../Reducers/news";

import NewsList from "../../Components/NewsPageComponents/List"
import "./news_page.scss";


export default function News() {
  const news = useSelector((state) => state.news);
  const dispatch = useDispatch();

  React.useEffect(() => {
    Promise.all([dispatch(allNews())]);
  }, [dispatch]);

  return (
    <div className="news-page">
      <div className="container mx-auto grid grid-cols-4 lg:grid-cols-8 news-page--heading">
        <div className="col-span-full lg:col-span-6 lg:col-start-2">
          <h1>News</h1>
        </div>
      </div>
      <div className="w-full bg-white news-page--content">
        <div className="container mx-auto grid grid-cols-4 lg:grid-cols-8">
            <NewsList news={news} />
        </div>
      </div>
    </div>
  );
}
