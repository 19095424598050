import React from "react";
import { withRouter } from "./withRouter";
import Scroll from "react-scroll";

class ScrollToTop extends React.Component {
  componentDidUpdate(prevProps) {
    if (this.props.router.location !== prevProps.router.location) {
      Scroll.animateScroll.scrollToTop({
        delay: 0,
        duration: 400,
        offset: 0,
        smooth: true,
      });
    }
  }
  render() {
    return null;
  }
}

export default withRouter(ScrollToTop);
