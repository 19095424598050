import React from "react";
import CountUp from "react-countup";
import MileStone from "../../../Components/MileStone";
import { scrollToElement } from "../../../Libs/utils";
export default function SecondSection() {
  const onClickExplore = () => {
    scrollToElement("third-section_home", 0);
  };

  return (
    <section className="w-full bg-white js-section_nav-dark">
      <div className="grid-container container mx-auto">
        <div className="homepage-secondSection col-span-full row-start-1 lg:row-start-1 grid grid-cols-4 lg:grid-cols-8 row-span-12 lg:row-span-6 grid-rows-12 lg:grid-rows-6">
          <div className="col-span-full "></div>
          <div className="row-span-2 hidden lg:grid grid-rows-2"></div>
          <div className="col-span-4 row-span-3 lg:row-span-2">
            <div className="text-wrapper lg:px-4 xl:px-16 pb-8">
              <div className="text-wrapper-inner">
                <h1>
                  There are over 4B people online behind language barriers.
                  Reach a whole new audience.
                </h1>
                <p>
                  {" "}
                  Localize any kind of digital product with continuous
                  localization enabled. Start in minutes and enjoy clean and
                  user-friendly without unnecessary clutter to get things done
                  with the best results in one platform.
                </p>
              </div>
            </div>
          </div>
          <div className="col-span-full grid lg:hidden grid-cols-4"></div>
          <div className="col-span-2 row-span-2 bg-dark lg:flex flex-col items-center justify-center hidden">
            <p className="text-5xl font-eina03">
              <MileStone end={20} /> <span>+</span>
            </p>
            <p className="text-base font-eina03">MT Engine</p>
          </div>
          <div className="row-span-6 lg:row-span-2 col-span-full grid grid-rows-6 lg:grid-rows-2 grid-cols-4 lg:grid-cols-8">
            <div className="col-span-full lg:col-span-4 row-span-2 grid grid-cols-4 grid-rows-2 homepage-secondSection--boxContainer order-3 lg:order-1">
              <div></div>
              <div className="addBox">
                <p>
                  <MileStone duration={1} end={75} /> <span>%</span>
                </p>
                <p>Cost Efficient</p>
              </div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div className="exploreBox">
                <button href="#" onClick={() => onClickExplore()}>
                  Discover
                </button>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  width="16px"
                  height="16px"
                  stroke="black"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M19 9l-7 7-7-7"
                  />
                </svg>
              </div>
            </div>
            <div className="col-span-2 row-span-2 bg-black order-2 col-start-2 flex flex-col items-center justify-center lg:hidden ">
              <p className="text-5xl font-eina03">
                <MileStone end={20} />
              </p>
              <p className="text-base font-eina03">MT Engine</p>
            </div>
            <div className="col-span-full lg:col-span-4 row-span-2 grid grid-cols-4 grid-rows-2 homepage-secondSection--boxContainer order-1 lg:order-2">
              <div className="addBox">
                <p>
                  <MileStone end={275} /> <span>+</span>
                </p>
                <p>Language Pair</p>
              </div>
              <div></div>
              <div></div>
              <div className="addBox">
                <p>
                  <MileStone end={8} /> <span>+</span>
                </p>
                <p>Industry</p>
              </div>
              <div></div>
              <div className="addBox">
                <p>
                  <MileStone end={100} /> <span>%</span>
                </p>
                <p>Secure</p>
              </div>
              <div></div>
              <div></div>
            </div>
          </div>
          <div className="col-span-full"></div>
        </div>
      </div>
    </section>
  );
}
