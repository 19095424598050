import React from 'react'
import {Link } from 'react-scroll'

export default function FaqPageCategory({categoryTitle}) {


    return (
        <li>
            <Link to={categoryTitle} spy={true} smooth={true} activeClass={"active"}>
                {categoryTitle}
            </Link>
        </li>
    )
}
