import React, { useEffect, useState } from "react";
import { useParams, useLocation, useNavigate } from "react-router-dom";
import { productsData } from "../../Services/DataService";
import { v4 as uuidv4 } from "uuid";
import Modular from "../../Components/Modular";
import "./index.scss";

export default function ProductDetailsPage() {
  const { slug } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const [activeProduct, setActiveProduct] = useState(false);

  useEffect(() => {
    const findItem = productsData.find((p) => p.slug === slug);
    findItem ? setActiveProduct(findItem) : navigate("/error");
  }, [location, slug, navigate]);

  return activeProduct?.modules?.length ? (
    <div className="product-details-page">
      {activeProduct?.modules
        .filter((m) => m.active !== false)
        .map((module) => {
          return (
            <Modular
              key={uuidv4()}
              module={{
                ...module,
                ...{
                  productName: activeProduct?.productName
                    ? activeProduct?.productName
                    : false,
                },
              }}
            />
          );
        })}
    </div>
  ) : null;
}
