import React, { useState, useEffect } from "react";
import "./index.scss";
import Image from "../../Components/Image";
import CTABtnWrapper from "../../Components/CTABtnWrapper";

const certificates = [
  {
    title: "SOC 2",
    description: "Type II Trust Services Principles",
    image: {
      source: "Assets/Images/Certificates/soc2.jpeg",
      width: 128,
      height: 128,
    },
  },
  {
    title: "SOC 3",
    description: "Trust Services Principles",
    image: {
      source: "Assets/Images/Certificates/soc3.jpeg",
      width: 128,
      height: 128,
    },
  },
  {
    title: "EU/U.S. Privacy Shield",
    description: "Data privacy practice framework",
    image: {
      source: "Assets/Images/Certificates/privacyshield.jpeg",
      width: 128,
      height: 128,
    },
  },
  {
    title: "PCI DDS",
    description: "Payment Card Industry (PCI) certified",
    image: {
      source: "Assets/Images/Certificates/pci.jpeg",
      width: 128,
      height: 128,
    },
  },
  {
    title: "ISO 2700x",
    description: "Information Security Management Standard",
    image: {
      source: "Assets/Images/Certificates/iso.jpeg",
      width: 128,
      height: 128,
    },
  },
  {
    title: "NIST",
    description: "Cybersecurity Framework",
    image: {
      source: "Assets/Images/Certificates/nist.jpeg",
      width: 128,
      height: 128,
    },
  },
  {
    title: "OWASP",
    description: "Open Web Application Security standards and best practices",
    image: {
      source: "Assets/Images/Certificates/owasp.jpeg",
      width: 128,
      height: 128,
    },
  },
  {
    title: "CSA",
    description: "Cloud Security Alliance",
    image: {
      source: "Assets/Images/Certificates/csa.jpeg",
      width: 128,
      height: 128,
    },
  },
];

const cta = [
  {
    label: "Talk us About Security",
    action: "security-session",
  },
];

export default function Security() {
  const productName = "Security";
  return (
    <div className="security-page security-page-bg">
      <section className="security-page-header js-section_nav-dark">
        <div className="container mx-auto">
          <div className="security-page-header__wrapper">
            <div className="security-page-header__inner">
              <div className="parent">
                <div className="div1 security-page-header__title__wrapper">
                  <div className="security-page-header__title">
                    Never compromise on security
                  </div>{" "}
                </div>
                <div className="div2 security-page-header__desc__wrapper">
                  <div className="security-page-header__desc">
                    Our enterprise customers trust lugath to power a
                    collaborative, secure and scalable cloud-based workflow.
                  </div>{" "}
                  <div className="security-page-header__cta">
                    {cta.map((c, i) => (
                      <CTABtnWrapper
                        options={{
                          formPopupTheme: "light",
                        }}
                        children={<button>{c?.label}</button>}
                        data={{
                          ...c,
                          ...{ productName: productName ? productName : "" },
                        }}
                      ></CTABtnWrapper>
                    ))}
                  </div>{" "}
                </div>
                <div className="div3">
                  <div className="security-page-header__card security-page-header__card--cyan ">
                    <Image
                      width={128}
                      height={128}
                      fileName={"Assets/Images/security-bg.svg"}
                    />
                  </div>
                </div>
                <div className="div4">
                  <div className="security-page-header__card">
                    <Image
                      width={64}
                      height={64}
                      fileName={"Assets/Images/customer-siemens.svg"}
                    />
                  </div>
                </div>
                <div className="div5">
                  <div className="security-page-header__card">
                    <Image
                      width={64}
                      height={64}
                      fileName={"Assets/Images/customer-vestel.svg"}
                    />
                  </div>
                </div>
                <div className="div6">
                  <div className="security-page-header__card">
                    <Image
                      width={64}
                      height={64}
                      fileName={"Assets/Images/customer-paribu.svg"}
                    />
                  </div>
                </div>
                <div className="div7">
                  <div className="security-page-header__card">
                    <Image
                      width={64}
                      height={64}
                      fileName={"Assets/Images/customer-ets.svg"}
                    />
                  </div>
                </div>
                <div className="div8">
                  <div className="security-page-header__card">
                    <Image
                      width={64}
                      height={64}
                      fileName={"Assets/Images/customer-arcelik.svg"}
                    />
                  </div>
                </div>
                <div className="div9">
                  <div className="security-page-header__card security-page-header__card--empty"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="security-page-cover js-section_nav-dark">
        <div className="container mx-auto">
          <div className="security-page-cover__wrapper">
            <div className="security-page-cover__inner">
              <div className="security-page-cover__title">
                Security that flexes to your needs
              </div>
              <div className="security-page-cover__desc">
                Lugath offers built-in security across all plans as well as
                enterprise-specific security features and customization options.
              </div>
              <div className="security-page-cover__content__wrapper">
                <div className="security-page-cover__content">
                  <div className="security-page-cover__content-title">
                    All plans include
                  </div>
                  <div className="security-page-cover__content-desc">
                    Our advanced security integrations fold seamlessly into your
                    corporate policies and workflow.
                  </div>
                  <div className="security-page-cover__content-list__wrapper">
                    <ul className="security-page-cover__content-list">
                      <li className="security-page-cover__content-list__item">
                        Data encryption in transit and at rest{" "}
                      </li>
                      <li className="security-page-cover__content-list__item">
                        Web application firewall{" "}
                      </li>
                      <li className="security-page-cover__content-list__item">
                        Container security
                      </li>
                      <li className="security-page-cover__content-list__item">
                        {" "}
                        DDoS protection
                      </li>
                      <li className="security-page-cover__content-list__item">
                        {" "}
                        Retain all data when employees leave
                      </li>
                      <li className="security-page-cover__content-list__item">
                        {" "}
                        Enhanced user audit logging
                      </li>
                      <li className="security-page-cover__content-list__item">
                        {" "}
                        Advanced Security Integrations
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="security-page-certificates js-section_nav-dark">
        <div className="container mx-auto">
          <div className="security-page-certificates__wrapper">
            <div className="security-page-certificates__inner">
              <div className="security-page-certificates__title">
              Aligned with below certifications, attestations, and frameworks
              </div>

              <div className="security-page-certificates__content__wrapper">
                <div className="security-page-certificates__content">
                  <div className="security-page-certificates__content-list__wrapper">
                    <ul className="security-page-certificates__content-list">
                      {certificates.map((c) => (
                        <li className="security-page-certificates__content-list__item">
                          <div className="security-page-certificates__content-list__item-image">
                            <Image
                              fileName={c.image.source}
                              width={c.image.width}
                              height={c.image.height}
                            />
                          </div>
                          <div className="security-page-certificates__content-list__item-title">
                            {c.title}
                          </div>
                          <div className="security-page-certificates__content-list__item-desc">
                            {c.description}
                          </div>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
