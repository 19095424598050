import { v4 as uuidv4 } from "uuid";

export const solutions = [
  {
    id: uuidv4(),
    slug: "localization-for-developers",
    alias: "For Developers",
    modules: [
      {
        moduleName: "SolutionDetailsHeader",
        data: {
          alias: "For Developers",
          title: "Easy <b>Integration</b>, Full <b>Automation</b>",
          description:
            "The continuous localization management platform that powers up your development and translation.",
          demoFrame: {
            videoLink: "https://www.youtube.com/watch?v=P5Wkuop1ebE",
            applicationPath: "/applications",
            image: {
              source: "Assets/Images/ApplicationLocaleEdit2.png",
              width: 3560,
              height: 1940,
            },
          },
          cta: [
            {
              label: "Get Started",
              action: "register",
            },
            {
              label: "Book a Demo",
              action: "book-a-demo",
            },
          ],
        },
      },
      {
        moduleName: "HeroTextToutWithList",
        data: {
          primaryTitle: "Developer-friendly",
          secondaryTitle:
            "<b>lugath</b> was created for developers, by developers. As a result, our tools, <strong>API</strong> and documentation are comprehensive and intuitive for tech-savvy teams.",
          features: [
            {
              image: "Assets/Icons/integration_instructions_black_24dp.svg",
              title: "Easy and Single Integration",
              description:
                "Integrate easily with only one solution for translation and localization services as an intelligent hub.",
            },
            {
              image: "Assets/Icons/cloud_sync_black_24dp.svg",
              title: "CI/CD Pipelines",
              description:
                "Customize CI/CD pipelines with multilingual content to automate the localization workflow.",
            },
            {
              image: "Assets/Icons/api_black_24dp.svg",
              title: "API Support",
              description:
                "Download our Postman collection and start sending sample requests to see responses. If you need more, check our documentation to see details of restful structure.",
            },
            {
              image: "Assets/Icons/for-developers.svg",
              title: "Cross-Platform",
              description:
                "Are you developing a website, mobile app, or game? Check out our SDKs and CLI.",
            },
            {
              image: "Assets/Icons/feature-cms.svg",
              title: "Sync / Async ",
              description:
                "Translate your content in real-time or schedule tasks for batch processing of large-sized content.",
            },
            {
              image: "Assets/Icons/cloud_done_black_24dp.svg",
              title: "Cloud-Based",
              description:
                "Worried about performance? Thanks to our cloud-based infrastructure, scalable localization services will address all the requests.",
            },
          ],
          cta: [
            {
              label: "Try For Free 14 Days",
              action: "register",
            },
          ],
        },
        options: {
          theme: "dark",
          box: "dark",
          listItems: 3,
          padding: "large-both", //default, large-both, small-both, large-top, large-bottom, small-both, small-top, small-bottom
        },
      },
      {
        moduleName: "HeroImage",
        data: {
          title: (
            <>
              To integrate{" "}
              <span className="highlight-gray">
                your <span style={{ color: "#2DBA4E" }}>repositories</span> with
              </span>{" "}
              <span className="highlight">lugath</span>
            </>
          ),
          path: "/projects",
          description:
            "You can pull what you need from your repositories with just a few clicks. Edit, translate, collaborate or share with your team. Once everything is done, you can push back to your repository just as easily.",
          image: {
            source: "Assets/Images/ApplicationIntegration.png",
            width: 3560,
            height: 1940,
          },
        },
        options: {
          imageAlignment: "bottom",
        },
      },
      {
        moduleName: "TextToutWithImage",
        data: {
          image: {
            source: "Assets/Images/test-7.png",
            width: 901,
            height: 600,
          },
          title: "Easy Integration",
          description: "Integrate with less development effort.",
        },
        options: {
          theme: "light",
          image: "left",
          withFrame: false,
          text: "right",
        },
      },
      { moduleName: "WorkflowScheme", data: {} },
      {
        moduleName: "TextToutWithImage",
        data: {
          image: {
            source: "Assets/Images/monitoring.png",
            width: 2688,
            height: 1480,
          },
          title: "Monitoring",
          description:
            "Monitor actively and analyze all transactions of your translation requests.",
        },
        options: {
          theme: "light",
          image: "right",
          withFrame: true,
          frameOptions: {
            url: "app.lugath.com",
            path: "/usage",
            onClickAction: false,
            action: false,
          },
          text: "left",
        },
      },
      {
        moduleName: "TextToutWithImage",
        data: {
          image: {
            source: "Assets/Images/technical-support.png",
            width: 901,
            height: 600,
          },
          title: "Technical Support",
          description:
            "To get faster technical support, create a ticket easily.",
        },
        options: {
          theme: "light",
          image: "left",
          withFrame: false,
          text: "right",
        },
      },
      {
        moduleName: "Testimonials",

        data: {
          testimonials: [
            {
              id: 0,
              author: "Sebastian Sandler",
              title: "Technical Documentation Manager",
              brand: "SIEMENS",
              rating: 4,
              image: "Assets/Images/Testimonials/image.png",
              testimonial:
                "Siemens AG successfully implemented an industry 4.0-compliant automated translation workflow, leading to significant reductions in time and resources required for technical document translation.",
            },
            {
              id: 2,
              author: "Neslihan Kalafatoglu",
              title: "Senior B2B Marketing Specialist",
              brand: "VESTEL",
              rating: 5,
              image: "Assets/Images/Testimonials/image.png",
              testimonial:
                "With the facilitation of communication between the marketing team and the software development team, the localization workflow has been further accelerated.",
            },
            {
              id: 3,
              author: "Berk Cakmak",
              title: "E-Commerce Manager",
              brand: "ETSTUR",
              rating: 5,
              image: "Assets/Images/Testimonials/image.png",
              testimonial:
                "Lugath's Intelligent MT Hub provides a single integration point for accessing all systems. Plus, it helps identify which system performs best for specific languages and industries.",
            },
            {
              id: 1,
              author: "Maria Claudia Lo Porto",
              title: "Freelance Translator",
              brand: "TRANSLATOR",
              rating: 4,
              image: "Assets/Images/Testimonials/image.png",
              testimonial:
                " Lugath's online CAT is definitely easy to use and quite helpful, I am using it regularly.",
            },
            {
              id: 4,
              author: "Aybars Yagiz",
              title:
                "Assistant Managing Director – Legal and Corporate Communications",
              brand: "GAMA ENERGY",
              rating: 4,
              image: "Assets/Images/Testimonials/image.png",
              testimonial:
                "When we use Lugath to translate our agreements beforehand, it makes translation easier, and we can focus mainly on proofreading these documents.",
            },
          ],
        },
      },
      {
        moduleName: "OtherProfiles",
        data: {
          title: "Boost your team’s productivity tenfold.",
          description:
            "To make automation technology work best for your business with time-saving tool your team has been missing.",
          cta: [
            {
              label: "Start For Free",
              action: "register",
            },
          ],
          profiles: [
            {
              profile: "designers",
            },
            {
              profile: "managers",
            },
            {
              profile: "translators",
            },
          ],
        },
      },
      {
        moduleName: "Integrations",
        data: {},
      },
    ],
  },
  {
    id: uuidv4(),
    slug: "localization-for-managers",
    alias: "For Managers",
    modules: [
      {
        moduleName: "SolutionDetailsHeader",
        data: {
          alias: "For Managers",
          title: "End-to-end <b>Localization</b> Workflow",
          description:
            "Integrate localization into your workflow in every stage from the marketing to the development stage.",
          demoFrame: {
            videoLink: "https://www.youtube.com/watch?v=P5Wkuop1ebE",
            applicationPath: "/dashboard",
            image: {
              source: "Assets/Images/Dashboard.png",
              width: 3600,
              height: 2400,
            },
          },
          cta: [
            {
              label: "Get Started",
              action: "register",
            },
            {
              label: "Book a Demo",
              action: "book-a-demo",
            },
          ],
        },
      },
      {
        moduleName: "SolutionDetailsCover",
        data: {
          title: "Full localization control in <b>your hands</b>",
          description:
            "From content adaptation to quality assurance, every aspect is in your hands, empowering you to ensure precise, error-free global content delivery.",
          featureList: [
            {
              wrapper:
                "div1 solution-details-cover__content-feature solution-details-cover__content-feature--vertical-image",
              title: "Translation Management",
              link: "/products/collaborative-translation",
              description:
                "Invite your translators to actively contribute to your content.",
              image: {
                source: "Assets/Images/test-1.png",
                width: 1748,
                height: 760,
              },
            },
            {
              wrapper:
                "div2 solution-details-cover__content-feature solution-details-cover__content-feature--horizontal-image",
              title: "Continuous Localization Lifecycle",
              link: "/products/localization-workflow",
              description:
                "Update translations anytime, anywhere. No longer depend on your development.",
              image: {
                source: "Assets/Images/test-2.png",
                width: 1780,
                height: 776,
              },
            },
            {
              wrapper:
                "div3 solution-details-cover__content-feature solution-details-cover__content-feature--horizontal-image",
              title: "Create, Edit, and Store",
              link: "/products/online-cat-tool",
              description: "Easily create your content, edit, and store.",
              image: {
                source: "Assets/Images/test-4.png",
                width: 1780,
                height: 776,
              },
            },
            {
              wrapper: "div4 solution-details-cover__content-feature",
              title: "Provide More Context",
              link: "/products/generative-ai-translation",
              description:
                "Use generative AI for more productivity by giving more context.",
            },
            {
              wrapper: "div5 solution-details-cover__content-feature",
              title: "Linguistic Quality Assurance",
              link: "/products/ai-quality-assurance",
              description: "Check the quality scores of translations with AI.",
            },
            {
              wrapper: "div6 solution-details-cover__content-feature",
              title: "Security",
              link: "/security",
              description: "Your data only belongs to you.",
            },
          ],
          cta: [
            {
              label: "Try For Free 14 Days",
              action: "register",
            },
          ],
        },
      },
      {
        moduleName: "HeroTextToutWithList",
        data: {
          primaryTitle: "What can you localize with lugath?",
          secondaryTitle:
            "A comprehensive localization solution capable of effectively adapting softwares, marketing materials, office documents, and user guides to different languages and regions, enabling broad accessibility and comprehension for diverse audiences worldwide.",
          features: [
            {
              image: "Assets/Icons/feature-web.svg",
              title: "Website",
              description:
                "Any kind of web app. Frontend, backend, single page application, blogs, you name it.",
            },
            {
              image: "Assets/Icons/feature-mobile.svg",
              title: "Mobile App",
              description:
                "Localize your app for every platform using your preferred file format and speed up publishing with SDKs.",
            },
            {
              image: "Assets/Icons/feature-game.svg",
              title: "Game",
              description:
                "Simplify the process of bringing your game to a global audience while maintaining gameplay excellence.",
            },
            {
              image: "Assets/Icons/web_stories_black_24dp.svg",
              title: "Marketing Assets",
              description:
                "Reach and convert more prospects with translated web pages, blogs, emails, and other marketing assets.",
            },
            {
              image: "Assets/Icons/product-document-translation.svg",
              title: "Office Documents",
              description:
                "Upload, translate, and download your documents, including Word, Excel, or PowerPoint, and more.",
            },
            {
              image: "Assets/Icons/user-guide_black_24dp.svg",
              title: "User Guides",
              description:
                "Translate thousands of page user manuals of your products in seconds.",
            },
          ],
          cta: [
            {
              label: "Try For Free 14 Days",
              action: "register",
            },
          ],
        },
        options: {
          theme: "dark",
          box: "dark",
          listItems: 3,
          padding: "large-both", //default, large-both, small-both, large-top, large-bottom, small-both, small-top, small-bottom
        },
      },
      {
        moduleName: "Testimonials",

        data: {
          testimonials: [
            {
              id: 0,
              author: "Sebastian Sandler",
              title: "Technical Documentation Manager",
              brand: "SIEMENS",
              rating: 4,
              image: "Assets/Images/Testimonials/image.png",
              testimonial:
                "Siemens AG successfully implemented an industry 4.0-compliant automated translation workflow, leading to significant reductions in time and resources required for technical document translation.",
            },
            {
              id: 2,
              author: "Neslihan Kalafatoglu",
              title: "Senior B2B Marketing Specialist",
              brand: "VESTEL",
              rating: 5,
              image: "Assets/Images/Testimonials/image.png",
              testimonial:
                "With the facilitation of communication between the marketing team and the software development team, the localization workflow has been further accelerated.",
            },
            {
              id: 3,
              author: "Berk Cakmak",
              title: "E-Commerce Manager",
              brand: "ETSTUR",
              rating: 5,
              image: "Assets/Images/Testimonials/image.png",
              testimonial:
                "Lugath's Intelligent MT Hub provides a single integration point for accessing all systems. Plus, it helps identify which system performs best for specific languages and industries.",
            },
            {
              id: 1,
              author: "Maria Claudia Lo Porto",
              title: "Freelance Translator",
              brand: "TRANSLATOR",
              rating: 4,
              image: "Assets/Images/Testimonials/image.png",
              testimonial:
                " Lugath's online CAT is definitely easy to use and quite helpful, I am using it regularly.",
            },
            {
              id: 4,
              author: "Aybars Yagiz",
              title:
                "Assistant Managing Director – Legal and Corporate Communications",
              brand: "GAMA ENERGY",
              rating: 4,
              image: "Assets/Images/Testimonials/image.png",
              testimonial:
                "When we use Lugath to translate our agreements beforehand, it makes translation easier, and we can focus mainly on proofreading these documents.",
            },
          ],
        },
      },
      {
        moduleName: "OtherProfiles",
        data: {
          title: "Boost your team’s productivity tenfold.",
          description:
            "To make automation technology work best for your business with time-saving tool your team has been missing.",
          cta: [
            {
              label: "Start For Free",
              action: "register",
            },
          ],
          profiles: [
            {
              profile: "developers",
            },
            {
              profile: "translators",
            },
            {
              profile: "designers",
            },
          ],
        },
      },
      {
        moduleName: "Integrations",
        data: {},
      },
    ],
  },
  {
    id: uuidv4(),
    slug: "localization-for-translators",
    alias: "For Translators",
    modules: [
      {
        moduleName: "SolutionDetailsHeader",
        data: {
          alias: "For Translators",
          title: "Power up your translation to <b>next level</b>",
          description: "We are leaving translations to true experts like you.",
          demoFrame: {
            videoLink: "https://www.youtube.com/watch?v=P5Wkuop1ebE",
            applicationPath: "/project/edit",
            image: {
              source: "Assets/Images/CATTool/CAT-tool.png",
              width: 3600,
              height: 2400,
            },
          },
          cta: [
            {
              label: "Get Started",
              action: "register",
            },
            {
              label: "Book a Demo",
              action: "book-a-demo",
            },
          ],
        },
      },
      {
        moduleName: "HeroTextToutWithList",
        data: {
          primaryTitle: "Let Lugath handle the rest",
          secondaryTitle:
            "Put your translations at the forefront and let Lugath handle the rest easily.",
          features: [
            {
              image: "Assets/Icons/product-service-hub.svg",
              title: "Intelligent MT Hub",
              description: "Multiple MT Engine integrated on a single hub.",
              //link: "/products/translation-service-hub",
            },
            {
              image: "Assets/Icons/product-translation-memory.svg",
              title: "AI Translation Memory",
              //link: "/products/ai-translation-memory",
              description:
                "Our TM automatically saves and uses segments on translations. No human touch is required.",
            },
            {
              image: "Assets/Icons/product-glossary.svg",
              //link: "/products/glossary",
              title: "Cross-Engine Glossary",
              description:
                "Create your glossary one time and use it with multiple MT Engines simultaneously.",
            },
            {
              image: "Assets/Icons/product-collaboration.svg",
              //link: "/products/collaborative-translation",
              title: "Collaborative Translation",
              description:
                "Work on the same translation with multiple translators.",
            },
            {
              image: "Assets/Icons/mark_chat_unread_black_24dp.svg",
              //link: "/products/collaborative-translation",
              title: "Comments and Attachments",
              description:
                "Check shared attachments and see comments on translations.",
            },
            {
              image: "Assets/Icons/compare_black_24dp.svg",
              title: "Preview the Source",
              description:
                "Align your translation according to media that you want to publish on.",
            },
          ],
          cta: [
            {
              label: "Try For Free 14 Days",
              action: "register",
            },
          ],
        },
        options: {
          theme: "dark",
          box: "dark",
          listItems: 3,
          padding: "large-both", //default, large-both, small-both, large-top, large-bottom, small-both, small-top, small-bottom
        },
      },
      {
        moduleName: "TextToutWithImage",
        data: {
          image: {
            source: "Assets/Images/feature-1.png",
            width: 2160,
            height: 1608,
          },
          title: "Generative AI",
          description:
            "Increase your productivity and optimize your localization content with the help of lugath AI.",
        },
        options: {
          theme: "light",
          image: "right",
          withFrame: false,
          text: "left",
        },
      },
      {
        moduleName: "TextToutWithImage",
        data: {
          image: {
            source: "Assets/Images/feature-ai-lqa.png",
            width: 1802,
            height: 1200,
          },
          title: "AI LQA",
          description: "Linguistic quality assurance powered by generative AI.",
        },
        options: {
          theme: "light",
          image: "left",
          withFrame: false,
          text: "right",
        },
      },
      {
        moduleName: "Testimonials",

        data: {
          testimonials: [
            {
              id: 0,
              author: "Sebastian Sandler",
              title: "Technical Documentation Manager",
              brand: "SIEMENS",
              rating: 4,
              image: "Assets/Images/Testimonials/image.png",
              testimonial:
                "Siemens AG successfully implemented an industry 4.0-compliant automated translation workflow, leading to significant reductions in time and resources required for technical document translation.",
            },
            {
              id: 2,
              author: "Neslihan Kalafatoglu",
              title: "Senior B2B Marketing Specialist",
              brand: "VESTEL",
              rating: 5,
              image: "Assets/Images/Testimonials/image.png",
              testimonial:
                "With the facilitation of communication between the marketing team and the software development team, the localization workflow has been further accelerated.",
            },
            {
              id: 3,
              author: "Berk Cakmak",
              title: "E-Commerce Manager",
              brand: "ETSTUR",
              rating: 5,
              image: "Assets/Images/Testimonials/image.png",
              testimonial:
                "Lugath's Intelligent MT Hub provides a single integration point for accessing all systems. Plus, it helps identify which system performs best for specific languages and industries.",
            },
            {
              id: 1,
              author: "Maria Claudia Lo Porto",
              title: "Freelance Translator",
              brand: "TRANSLATOR",
              rating: 4,
              image: "Assets/Images/Testimonials/image.png",
              testimonial:
                " Lugath's online CAT is definitely easy to use and quite helpful, I am using it regularly.",
            },
            {
              id: 4,
              author: "Aybars Yagiz",
              title:
                "Assistant Managing Director – Legal and Corporate Communications",
              brand: "GAMA ENERGY",
              rating: 4,
              image: "Assets/Images/Testimonials/image.png",
              testimonial:
                "When we use Lugath to translate our agreements beforehand, it makes translation easier, and we can focus mainly on proofreading these documents.",
            },
          ],
        },
      },
      {
        moduleName: "OtherProfiles",
        data: {
          title: "Boost your team’s productivity tenfold.",
          description:
            "To make automation technology work best for your business with time-saving tool your team has been missing.",
          cta: [
            {
              label: "Start For Free",
              action: "register",
            },
          ],
          profiles: [
            {
              profile: "developers",
            },
            {
              profile: "managers",
            },
            {
              profile: "designers",
            },
          ],
        },
      },
      {
        moduleName: "Integrations",
        data: {},
      },
    ],
  },
  {
    id: uuidv4(),
    slug: "localization-for-designers",
    alias: "For Designers",
    modules: [
      {
        moduleName: "SolutionDetailsHeader",
        data: {
          alias: "For Designers",
          title: "<b>Localization</b> Starts With <b>Design</b>",
          description:
            "Design your product or service for multi-cultural user experience dynamics.",
          demoFrame: {
            videoLink: "https://www.youtube.com/watch?v=P5Wkuop1ebE",
            applicationPath: "/file:3rn3ELuEleVPJ6HeK",
            url: "figma.com",
            action: false,
            image: {
              source: "Assets/Images/FigmaScreen.png",
              width: 1792,
              height: 979,
            },
          },
          cta: [
            {
              label: "Get Started",
              action: "register",
            },
            {
              label: "Book a Demo",
              action: "book-a-demo",
            },
          ],
        },
      },
      {
        moduleName: "SolutionDetailsCover",
        data: {
          title: "Full design control in <b>your hands</b>",
          description:
            "Instantly translate your <b>Figma, Sketch, XD</b> pages and individual frames into any language with Lugath! Our plugins will make your designs truly multilingual, allowing you to tweak and test layouts in one place, without the messiness of endless copies for every language.",
          featureList: [
            {
              wrapper:
                "div1 solution-details-cover__content-feature solution-details-cover__content-feature--vertical-image",
              title: "Translate Entire Page or Selection.",
              link: "/products/generative-ai-translation",
              description:
                "Your translations will be embedded into Figma text objects and kept intact when manipulating visuals.",
              image: {
                source: "Assets/Images/test-1.png",
                width: 1748,
                height: 760,
              },
            },
            {
              wrapper:
                "div2 solution-details-cover__content-feature solution-details-cover__content-feature--horizontal-image",
              title: "Continuous Localization",
              link: "/products/localization-workflow",
              description:
                "Push texts from Figma to Lugath, then localize with team. Once you're done pull translated texts from Lugath to Figma.",
              image: {
                source: "Assets/Images/design-1.png",
                width: 1780,
                height: 776,
              },
            },
            {
              wrapper:
                "div3 solution-details-cover__content-feature solution-details-cover__content-feature--horizontal-image",
              title: "Accessible For All Team",
              link: "/products/localization-workflow",
              description:
                "Your team can find, edit or review your design content with in ease.",
              image: {
                source: "Assets/Images/test-3.png",
                width: 1780,
                height: 776,
              },
            },
            {
              wrapper: "div4 solution-details-cover__content-feature",
              title: "Handle Text Styles",
              link: "/products/localization-workflow",
              description:
                "We’ve done our best to preserve text formatting within text objects as well.",
            },
            {
              wrapper: "div5 solution-details-cover__content-feature",
              title: "Translate with AI or Human",
              link: "/products/localization-workflow",
              description:
                "Easiest way to translate your designs, automatically or professionally.",
            },
            {
              wrapper: "div6 solution-details-cover__content-feature",
              title: "Preview a Language  ",
              link: "/products/localization-workflow",
              description:
                "Seamlessly switch languages in Figma and check if your design works in other languages.",
            },
          ],
          cta: [
            {
              label: "Try For Free 14 Days",
              action: "register",
            },
          ],
        },
      },
      {
        moduleName: "HeroTextToutWithList",
        data: {
          primaryTitle: "What can you localize with lugath?",
          secondaryTitle:
            "A comprehensive localization solution capable of effectively adapting softwares, marketing materials, office documents, and user guides to different languages and regions, enabling broad accessibility and comprehension for diverse audiences worldwide.",
          features: [
            {
              image: "Assets/Icons/feature-web.svg",
              title: "Website",
              description:
                "Any kind of web app. Frontend, backend, single page application, blogs, you name it.",
            },
            {
              image: "Assets/Icons/feature-mobile.svg",
              title: "Mobile App",
              description:
                "Localize your app for every platform using your preferred file format and speed up publishing with SDKs.",
            },
            {
              image: "Assets/Icons/feature-game.svg",
              title: "Game",
              description:
                "Simplify the process of bringing your game to a global audience while maintaining gameplay excellence.",
            },
            {
              image: "Assets/Icons/web_stories_black_24dp.svg",
              title: "Marketing Assets",
              description:
                "Reach and convert more prospects with translated web pages, blogs, emails, and other marketing assets.",
            },
            {
              image: "Assets/Icons/product-document-translation.svg",
              title: "Office Documents",
              description:
                "Upload, translate, and download your documents, including Word, Excel, or PowerPoint, and more.",
            },
            {
              image: "Assets/Icons/user-guide_black_24dp.svg",
              title: "User Guides",
              description:
                "Translate thousands of page user manuals of your products in seconds.",
            },
          ],
          cta: [
            {
              label: "Try For Free 14 Days",
              action: "register",
            },
          ],
        },
        options: {
          theme: "dark",
          box: "dark",
          listItems: 3,
          padding: "large-both", //default, large-both, small-both, large-top, large-bottom, small-both, small-top, small-bottom
        },
      },
      {
        moduleName: "TextToutWithImage",
        data: {
          image: {
            source: "Assets/Images/Editor/InitialScreen.png",
            width: 2688,
            height: 1480,
          },
          title: "Collaboration",
          description:
            "Quickly integrate your content in your designs without going back and forth with product managers, translators and developers.",
        },
        options: {
          theme: "light",
          image: "right",
          withFrame: false,
          frameOptions: {
            url: "app.lugath.com",
            path: "/usage",
            onClickAction: false,
            action: false,
          },
          text: "left",
        },
      },
      {
        moduleName: "OtherProfiles",
        data: {
          title: "Boost your team’s productivity tenfold.",
          description:
            "Your team's workflow processes can continue in parallel with each other.",
          cta: [
            {
              label: "Start For Free",
              action: "register",
            },
          ],
          profiles: [
            {
              profile: "developers",
              description:
                "Continue to development with your design content while your design process is running.",
            },
            {
              profile: "managers",
              description:
                "View and manage all localization processes while the design in progress.",
            },
            {
              profile: "translators",
              description:
                "Get contribution from translators while the design in progress, without interrupting your work.",
            },
          ],
        },
      },
      {
        moduleName: "Integrations",
        data: {},
      },
      {
        moduleName: "Testimonials",

        data: {
          testimonials: [
            {
              id: 0,
              author: "Sebastian Sandler",
              title: "Technical Documentation Manager",
              brand: "SIEMENS",
              rating: 4,
              image: "Assets/Images/Testimonials/image.png",
              testimonial:
                "Siemens AG successfully implemented an industry 4.0-compliant automated translation workflow, leading to significant reductions in time and resources required for technical document translation.",
            },
            {
              id: 2,
              author: "Neslihan Kalafatoglu",
              title: "Senior B2B Marketing Specialist",
              brand: "VESTEL",
              rating: 5,
              image: "Assets/Images/Testimonials/image.png",
              testimonial:
                "With the facilitation of communication between the marketing team and the software development team, the localization workflow has been further accelerated.",
            },
            {
              id: 3,
              author: "Berk Cakmak",
              title: "E-Commerce Manager",
              brand: "ETSTUR",
              rating: 5,
              image: "Assets/Images/Testimonials/image.png",
              testimonial:
                "Lugath's Intelligent MT Hub provides a single integration point for accessing all systems. Plus, it helps identify which system performs best for specific languages and industries.",
            },
            {
              id: 1,
              author: "Maria Claudia Lo Porto",
              title: "Freelance Translator",
              brand: "TRANSLATOR",
              rating: 4,
              image: "Assets/Images/Testimonials/image.png",
              testimonial:
                " Lugath's online CAT is definitely easy to use and quite helpful, I am using it regularly.",
            },
            {
              id: 4,
              author: "Aybars Yagiz",
              title:
                "Assistant Managing Director – Legal and Corporate Communications",
              brand: "GAMA ENERGY",
              rating: 4,
              image: "Assets/Images/Testimonials/image.png",
              testimonial:
                "When we use Lugath to translate our agreements beforehand, it makes translation easier, and we can focus mainly on proofreading these documents.",
            },
          ],
        },
      },
    ],
  },
  {
    id: uuidv4(),
    slug: "localization-for-customer-service",
    alias: "For Customer Service",
    modules: [
      {
        moduleName: "SolutionDetailsHeader",
        data: {
          alias: "For Customer Service",
          title: "Craft <b>Customer Experience</b> on Global Scale",
          description:
            "Talk same language with your customers on each after-sales support channels.",
          demoFrame: {
            videoLink: "https://www.youtube.com/watch?v=P5Wkuop1ebE",
            applicationPath: "/applications",
            image: {
              source: "Assets/Images/APIUsage/SearchResults.png",
              width: 3600,
              height: 2400,
            },
          },
          cta: [
            {
              label: "Get Started",
              action: "register",
            },
            {
              label: "Book a Demo",
              action: "book-a-demo",
            },
          ],
        },
      },
      {
        active: true,
        moduleName: "TextTout",
        data: {
          image: {
            source: "Assets/Images/test-5.png",
            width: 2688,
            height: 1480,
          },
          title: "CX Excellence",
          description:
            "Speak the same language with your customers for excellent support experience.",
        },
        options: {
          theme: "dark",
          description: "right",
          title: "left",
        },
      },
      {
        active: true,
        moduleName: "TextTout",
        data: {
          image: {
            source: "Assets/Images/test-7.png",
            width: 901,
            height: 600,
          },
          title: "Translate Support Center",
          description:
            "Translate your FAQs, newsletters, manuals, and HowTo documents.",
        },
        options: {
          theme: "light",
          description: "right",
          title: "left",
        },
      },
      {
        active: true,
        moduleName: "TextTout",
        data: {
          title: "Align with SLAs",
          description:
            "Responding on time makes you aligned with customer SLAs and increases your customer satisfaction (CSAT).",
        },
        options: {
          theme: "dark",
          description: "right",
          title: "left",
        },
      },
      {
        moduleName: "Testimonials",

        data: {
          testimonials: [
            {
              id: 0,
              author: "Sebastian Sandler",
              title: "Technical Documentation Manager",
              brand: "SIEMENS",
              rating: 4,
              image: "Assets/Images/Testimonials/image.png",
              testimonial:
                "Siemens AG successfully implemented an industry 4.0-compliant automated translation workflow, leading to significant reductions in time and resources required for technical document translation.",
            },
            {
              id: 2,
              author: "Neslihan Kalafatoglu",
              title: "Senior B2B Marketing Specialist",
              brand: "VESTEL",
              rating: 5,
              image: "Assets/Images/Testimonials/image.png",
              testimonial:
                "With the facilitation of communication between the marketing team and the software development team, the localization workflow has been further accelerated.",
            },
            {
              id: 3,
              author: "Berk Cakmak",
              title: "E-Commerce Manager",
              brand: "ETSTUR",
              rating: 5,
              image: "Assets/Images/Testimonials/image.png",
              testimonial:
                "Lugath's Intelligent MT Hub provides a single integration point for accessing all systems. Plus, it helps identify which system performs best for specific languages and industries.",
            },
            {
              id: 1,
              author: "Maria Claudia Lo Porto",
              title: "Freelance Translator",
              brand: "TRANSLATOR",
              rating: 4,
              image: "Assets/Images/Testimonials/image.png",
              testimonial:
                " Lugath's online CAT is definitely easy to use and quite helpful, I am using it regularly.",
            },
            {
              id: 4,
              author: "Aybars Yagiz",
              title:
                "Assistant Managing Director – Legal and Corporate Communications",
              brand: "GAMA ENERGY",
              rating: 4,
              image: "Assets/Images/Testimonials/image.png",
              testimonial:
                "When we use Lugath to translate our agreements beforehand, it makes translation easier, and we can focus mainly on proofreading these documents.",
            },
          ],
        },
      },
      {
        moduleName: "OtherProfiles",
        data: {
          title: "Boost your team’s productivity tenfold.",
          description:
            "To make automation technology work best for your business with time-saving tool your team has been missing.",
          cta: [
            {
              label: "Start For Free",
              action: "register",
            },
          ],
          profiles: [
            {
              profile: "developers",
            },
            {
              profile: "managers",
            },
            {
              profile: "enterprise",
            },
          ],
        },
      },
      {
        moduleName: "Integrations",
        data: {},
      },
    ],
  },
  {
    id: uuidv4(),
    slug: "localization-for-enterprise",
    alias: "For Enterprise",
    modules: [
      {
        moduleName: "SolutionDetailsHeader",
        data: {
          alias: "For Enterprises",
          title: "Think <b>Global</b>, Act <b>Local</b>",
          description: "Localization is the key to success for global growth.",
          demoFrame: {
            videoLink: "https://www.youtube.com/watch?v=P5Wkuop1ebE",
            applicationPath: "/applications",
            image: {
              source: "Assets/Images/Dashboard.png",
              width: 3600,
              height: 2400,
            },
          },
          cta: [
            {
              label: "Get Started",
              action: "register",
            },
            {
              label: "Book a Demo",
              action: "book-a-demo",
            },
          ],
        },
      },
      {
        moduleName: "HeroTextToutWithList",
        data: {
          primaryTitle:
            "What can you <strong>localize</strong> with <b>lugath?</b>",
          secondaryTitle:
            "A comprehensive <strong>localization</strong> solution capable of effectively adapting <b>softwares, marketing materials, office documents, and user guides</b> to different languages and regions, enabling broad accessibility and <strong>comprehension for diverse audiences worldwide.</strong>",
          features: [
            {
              image: "Assets/Icons/feature-web.svg",
              title: "Website",
              description:
                "Any kind of web app. Frontend, backend, single page application, blogs, you name it.",
            },
            {
              image: "Assets/Icons/feature-mobile.svg",
              title: "Mobile App",
              description:
                "Localize your app for every platform using your preferred file format and speed up publishing with SDKs.",
            },
            {
              image: "Assets/Icons/feature-game.svg",
              title: "Game",
              description:
                "Simplify the process of bringing your game to a global audience while maintaining gameplay excellence.",
            },
            {
              image: "Assets/Icons/web_stories_black_24dp.svg",
              title: "Marketing Assets",
              description:
                "Reach and convert more prospects with translated web pages, blogs, emails, and other marketing assets.",
            },
            {
              image: "Assets/Icons/product-document-translation.svg",
              title: "Office Documents",
              description:
                "Upload, translate, and download your documents, including Word, Excel, or PowerPoint, and more.",
            },
            {
              image: "Assets/Icons/user-guide_black_24dp.svg",
              title: "User Guides",
              description:
                "Translate thousands of page user manuals of your products in seconds.",
            },
          ],
          cta: [
            {
              label: "Try For Free 14 Days",
              action: "register",
            },
          ],
        },
        options: {
          theme: "dark",
          box: "dark",
          listItems: 3,
          padding: "large-both", //default, large-both, small-both, large-top, large-bottom, small-both, small-top, small-bottom
        },
      },
      {
        moduleName: "TextToutWithImage",
        data: {
          image: {
            source: "Assets/Images/feature-globally-scale.png",
            width: 2688,
            height: 1480,
          },
          title: "Globally Scale",
          description:
            "Coordinate the process of adapting and implementing localization across all aspects of your enterprise, from marketing to development and even post-sales channels, to achieve global scalability.",
        },
        options: {
          theme: "light",
          image: "right",
          withFrame: false,

          text: "left",
        },
      },
      {
        moduleName: "TextToutWithImage",
        data: {
          image: {
            source: "Assets/Images/feature-security.png",
            width: 901,
            height: 600,
          },
          title: "Security",
          description:
            "Rest assured, our platform meets top-tier security standards with SOC 2 Type 2, ISO 27001, and ISO 27017, and offers secure payments in line with PCI DSS compliance.",
        },
        options: {
          theme: "light",
          image: "left",
          withFrame: false,
          text: "right",
        },
      },
      {
        moduleName: "Testimonials",

        data: {
          testimonials: [
            {
              id: 0,
              author: "Sebastian Sandler",
              title: "Technical Documentation Manager",
              brand: "SIEMENS",
              rating: 4,
              image: "Assets/Images/Testimonials/image.png",
              testimonial:
                "Siemens AG successfully implemented an industry 4.0-compliant automated translation workflow, leading to significant reductions in time and resources required for technical document translation.",
            },
            {
              id: 2,
              author: "Neslihan Kalafatoglu",
              title: "Senior B2B Marketing Specialist",
              brand: "VESTEL",
              rating: 5,
              image: "Assets/Images/Testimonials/image.png",
              testimonial:
                "With the facilitation of communication between the marketing team and the software development team, the localization workflow has been further accelerated.",
            },
            {
              id: 3,
              author: "Berk Cakmak",
              title: "E-Commerce Manager",
              brand: "ETSTUR",
              rating: 5,
              image: "Assets/Images/Testimonials/image.png",
              testimonial:
                "Lugath's Intelligent MT Hub provides a single integration point for accessing all systems. Plus, it helps identify which system performs best for specific languages and industries.",
            },
            {
              id: 1,
              author: "Maria Claudia Lo Porto",
              title: "Freelance Translator",
              brand: "TRANSLATOR",
              rating: 4,
              image: "Assets/Images/Testimonials/image.png",
              testimonial:
                " Lugath's online CAT is definitely easy to use and quite helpful, I am using it regularly.",
            },
            {
              id: 4,
              author: "Aybars Yagiz",
              title:
                "Assistant Managing Director – Legal and Corporate Communications",
              brand: "GAMA ENERGY",
              rating: 4,
              image: "Assets/Images/Testimonials/image.png",
              testimonial:
                "When we use Lugath to translate our agreements beforehand, it makes translation easier, and we can focus mainly on proofreading these documents.",
            },
          ],
        },
      },
      {
        moduleName: "OtherProfiles",
        data: {
          title: "Boost your team’s productivity tenfold.",
          description:
            "To make automation technology work best for your business with time-saving tool your team has been missing.",
          cta: [
            {
              label: "Start For Free",
              action: "register",
            },
          ],
          profiles: [
            {
              profile: "developers",
            },
            {
              profile: "managers",
            },
            {
              profile: "translators",
            },
          ],
        },
      },
      {
        moduleName: "Integrations",
        data: {},
      },
    ],
  },
];
