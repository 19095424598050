const SET_POPUP_VISIBILITY = "SET_POPUP_VISIBILITY";

const initialState = {
  popUpVisibility: false,
  popUpTheme: "light",
  content: {
    type: "early-access",
    variables: [],
  },
};

// eslint-disable-next-line
export default (state = initialState, action) => {
  switch (action.type) {
    case SET_POPUP_VISIBILITY:
      return {
        ...state,
        popUpVisibility: action?.payload?.isVisible,
        popUpTheme: action?.payload?.options?.theme || "light",
        content: {
          type: action?.payload?.options?.content?.type || "early-access",
          variables: action?.payload?.options?.content?.variables
            ? action?.payload?.options?.content?.variables
            : [],
        },
      };

    default:
      return state;
  }
};

export const showFormPopUp = (options) => {
  return (dispatch) => {
    dispatch({
      type: SET_POPUP_VISIBILITY,
      payload: { isVisible: true, options: options },
    });
    document.body.classList.add("overflow-hidden");
  };
};

export const hideFormPopUp = () => {
  return (dispatch) => {
    dispatch({
      type: SET_POPUP_VISIBILITY,
      payload: { isVisible: false },
    });
    document.body.classList.remove("overflow-hidden");
  };
};
