import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { showFormPopUp } from "../../Reducers/settings";
import { hideFormPopUp } from "../../Reducers/settings";
import { dynamicParse, sendToHubSpot } from "../../Libs/utils";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import _ from "lodash";
import "./index.scss";

export default function FormPopUp(props) {
  const dispatch = useDispatch();
  const { handleSubmit, register, watch, errors, reset } = useForm();
  const settings = useSelector((state) => state.settings);

  const popUpTypes = [
    {
      type: "early-access",
      title: "Sign up for Beta today",

      description:
        "Are you ready for game-changing translation experience with <b>{{productName}}</b>? Fill out the form and discover.",
      hubSpotContext: {
        dynamic: true,
        pageName: "Website - Early Access",
        pageId: "Website - Early Access",
        pageUri: "https://www.lugath.com",
      },
    },
    {
      type: "book-a-demo",
      title: "Book a Demo",
      description:
        "Book a 30-minute demo to see how lugath can automate your localization and translation workflows. Just fill in your details and a lugath expert will get in touch.",
      hubSpotContext: {
        dynamic: false,
        pageName: "Website - Book A Demo",
        pageId: "Website - Book A Demo",
        pageUri: "https://www.lugath.com",
      },
    },
    {
      type: "get-report",
      title: "Get Report",
      description:
        "Check out <b>{{reportName}}</b> of lugath to look behind the numbers insights on. Find out how in our new report.",
      hubSpotContext: {
        dynamic: true,
        pageName: "Website - Get Report",
        pageId: "Website - Get Report",
        pageUri: "https://www.lugath.com",
        customSuccessResponse: (formData, popUpType) => {
          return (
            <div>
              <h2> Dear {formData.firstName} , </h2>
              <p>
                Thank you for your interest. To get the report click to download
                button.
              </p>
              <button onClick={onClickDownloadReport}> Download</button>
            </div>
          );
        },
      },
    },
    {
      type: "security-session",
      title: "Request a Security Session",
      description:
        "Just fill in a few details, Someone from our team will reach out.",
      hubSpotContext: {
        dynamic: true,
        pageName: "Website - Get Report",
        pageId: "Website - Get Report",
        pageUri: "https://www.lugath.com",
      },
    },
  ];
  const [popUpType, setPopUpType] = useState({});
  const [submitButtonStatus, setDisableSubmitButton] = useState(true);
  const [formSubmitStatus, setFormSubmit] = useState(false);
  const [isSubmitBtnVisible, setSubmitBtnVisible] = useState(true);
  const [formData, setFormData] = useState({});
  const watchAllFields = watch();

  const onClickDownloadReport = () => {
    const link = document.createElement("a");
    const url =
      "https://s3.us-west-2.amazonaws.com/docs.lugath.com/static/files/MT_ASSESSMENT_REPORT.pdf";
    link.setAttribute("href", url);
    link.setAttribute("target", "_blank");
    link.setAttribute("download", "MT_ASSESSMENT_REPORT");
    document.body.appendChild(link);
    link.click();
    link.parentNode.removeChild(link);
    onClose();
  };
  const onClose = () => {
    dispatch(hideFormPopUp());
  };

  const onOpen = () => {
    dispatch(showFormPopUp());
  };

  const onKeyPress = (e) => {
    if (e.keyCode === 76 && e.ctrlKey) {
      onOpen();
    }

    if (e.keyCode === 27) {
      onClose();
    }
  };

  const preparePopupContentWithVariables = (item, variables) => {
    const obj = item;
    if (variables?.length > 0) {
      for (const [key, value] of Object.entries(obj)) {
        if (variables.map((v) => v.section).includes(key)) {
          obj[key] = dynamicParse(
            obj[key],
            variables.find((v) => v.section == key).keys
          ).replace("undefined", "");
        } else {
          obj[key] = value;
        }
      }
      return obj;
    } else {
      return item;
    }
  };

  useEffect(() => {
    document.addEventListener("keydown", onKeyPress);
    return () => {
      document.removeEventListener("keydown", onKeyPress);
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const popUpItem = preparePopupContentWithVariables(
      popUpTypes.find((t) => t.type === settings?.content?.type),
      settings?.content?.variables
    );

    setPopUpType(popUpItem);
    return () => {
      setFormSubmit(false);
      setSubmitBtnVisible(true);
      setDisableSubmitButton(true);
    };
  }, [settings]);

  const onSubmit = (data) => {
    setDisableSubmitButton(false);
    setFormData(data);
    dataIsValid() ? sendFormActions(data) : backToFormActions();
  };

  const sendFormActions = (data) => {
    sendToHubSpot({
      ...data,
      ...{
        formType: "registration",
        context: {
          ..._.omit(popUpType.hubSpotContext, "dynamic"),
          ...{
            pageName: `${popUpType.hubSpotContext.pageName}`,
            pageId: `${popUpType.hubSpotContext.pageId}`,
            pageUri: window.location.href,
          },
        },
      },
    }).then((r) => {
      setFormSubmit(true);
      setSubmitBtnVisible(false);
      setDisableSubmitButton(true);
      reset();
      document
        .getElementById("form-popup")
        .scrollTo({ top: 0, behavior: "smooth" });
    });
  };

  const backToFormActions = () => {};

  const dataIsValid = () => {
    return true;
  };

  return (
    <>
      {
        <div
          id="form-popup"
          className={`form-popup bg-dark bg-opacity-70 overflow-y-scroll ${
            settings.popUpVisibility ? "show" : ""
          }`}
        >
          <div
            className={`w-full ${
              settings.popUpTheme === "light" ? "bg-white" : "bg-black"
            }`}
          >
            <div className="form-popup--container container mx-auto">
              <div className="col-span-full lg:col-span-6 row-start-1 col-start-1 lg:col-start-2 row-span-8 flex flex-col">
                <div className="close-icon flex w-full justify-end">
                  <svg
                    onClick={() => onClose()}
                    xmlns="http://www.w3.org/2000/svg"
                    fill="#282828"
                    width="32px"
                    height="32px"
                    viewBox="0 0 24 24"
                    stroke="#282828"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M6 18L18 6M6 6l12 12"
                    />
                  </svg>
                </div>
                <div className="heading">
                  <h1
                    className=""
                    dangerouslySetInnerHTML={{ __html: popUpType?.title }}
                  ></h1>
                  <p
                    dangerouslySetInnerHTML={{ __html: popUpType?.description }}
                  ></p>
                </div>
                <div className="form-wrapper flex-grow flex flex-col justify-between">
                  {formSubmitStatus &&
                    (popUpType?.hubSpotContext?.customSuccessResponse ? (
                      popUpType.hubSpotContext.customSuccessResponse(
                        formData,
                        popUpType
                      )
                    ) : (
                      <div>
                        <h2> Dear {formData.firstName} , </h2>
                        <p>
                          {" "}
                          Thank you for your interest. We’ll review your
                          submission and be back in touch.
                        </p>
                      </div>
                    ))}

                  {!formSubmitStatus && (
                    <form
                      id="form-form"
                      onSubmit={handleSubmit(onSubmit)}
                      autoComplete="off"
                      noValidate
                    >
                      <div className="input-wrapper">
                        <input
                          ref={register({
                            required: {
                              value: true,
                              message: "Your First Name is required.",
                            },
                          })}
                          id="firstname"
                          name="firstName"
                          type="text"
                          placeholder="First Name"
                          required
                        />
                        <label htmlFor="firstname">First Name</label>

                        {errors.firstName &&
                          errors.firstName.type === "required" && (
                            <p>{errors.firstName.message}</p>
                          )}
                      </div>
                      <div className="input-wrapper">
                        <input
                          ref={register({
                            required: {
                              value: true,
                              message: "Your Last Name is required.",
                            },
                          })}
                          name="lastName"
                          id="lastname"
                          type="text"
                          required
                          placeholder="Last Name"
                        />
                        <label htmlFor="lastname">First Name</label>
                        {errors.lastName &&
                          errors.lastName.type === "required" && (
                            <p>{errors.lastName.message}</p>
                          )}
                      </div>
                      <div className="input-wrapper">
                        <select
                          ref={register({
                            required: {
                              value: true,
                              message: "Company Name is required.",
                              validate: (value) => value.length > 0,
                            },
                          })}
                          required
                          name="jobCategory"
                          id="jobCategory"
                        >
                          <option
                            label="Please Select"
                            disabled={true}
                            defaultValue={""}
                          >
                            Please Select
                          </option>

                          <option
                            label="Business Development / Product Management"
                            value="Business Development/Product Management"
                          >
                            Business Development / Product Management
                          </option>

                          <option
                            label="Corporate Communication / Marketing"
                            value="Marketing"
                          >
                            Corporate Communication / Marketing
                          </option>

                          <option
                            label="HR / Training"
                            value="Corporate Communication/HR/Training"
                          >
                            HR / Training
                          </option>

                          <option
                            label="Innovation / R&amp;D"
                            value="Innovation"
                          >
                            Innovation / R&amp;D
                          </option>

                          <option label="Legal / Partnerships" value="Legal">
                            Legal / Partnerships
                          </option>

                          <option
                            label="Purchasing / Procurement"
                            value="Purchasing/Procurement"
                          >
                            Purchasing / Procurement
                          </option>

                          <option label="Sales" value="Sales">
                            Sales
                          </option>

                          <option label="Techincal / IT" value="Techincal">
                            Techincal / IT
                          </option>
                        </select>

                        <label htmlFor="jobCategory">
                          {errors.jobCategory && (
                            <span>Function is required</span>
                          )}
                        </label>
                      </div>
                      <div className="input-wrapper">
                        <input
                          ref={register}
                          name="jobTitle"
                          id="jobtitle"
                          type="text"
                          placeholder="Job Title"
                        />
                        <label htmlFor="jobtitle"></label>
                      </div>
                      <div className="input-wrapper">
                        <input
                          ref={register({
                            required: {
                              value: true,
                              message: "Company Name is required.",
                            },
                          })}
                          name="companyName"
                          id="companyname"
                          type="text"
                          placeholder="Company Name"
                          required
                        />
                        <label htmlFor="companyname"></label>
                        {errors.companyName &&
                          errors.companyName.type === "required" && (
                            <p>{errors.companyName.message}</p>
                          )}
                      </div>
                      <div className="input-wrapper">
                        <input
                          ref={register({
                            required: {
                              value: true,
                              message: "Website is required.",
                            },
                            pattern: {
                              // eslint-disable-next-line
                              value:
                                /[(http(s)?):\/\/(www\.)?a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/gi,
                            },
                          })}
                          name="companyWebsite"
                          id="companywebsite"
                          type="url"
                          placeholder="Company Website"
                        />
                        <label htmlFor="companywebsite"></label>
                        {errors.companyWebsite ? (
                          <p>Must be a URL. http://www.example.com</p>
                        ) : null}
                      </div>
                      <div className="input-wrapper">
                        <input
                          ref={register({
                            required: {
                              value: true,
                              message: "Your Email is required.",
                            },
                            pattern: {
                              value:
                                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                            },
                          })}
                          name="workEmail"
                          id="workmemail"
                          type="email"
                          placeholder="Work E-Mail"
                          required
                        />
                        <label htmlFor="workemail"></label>
                        {errors.workEmail ? (
                          <p>Must be valid email example@yourdomain.com.</p>
                        ) : null}
                      </div>
                      <input
                        id="toName"
                        name="toName"
                        type="hidden"
                        value="Orcun or Burak"
                      />
                      <div className="input-wrapper">
                        <input
                          ref={register}
                          name="highestRanking"
                          id="highestranking"
                          type="text"
                          placeholder="Describe your translation needs"
                        />
                        <label htmlFor="highestranking"></label>
                      </div>
                    </form>
                  )}
                  <div>
                    {isSubmitBtnVisible && (
                      <button
                        type="submit"
                        form="form-form"
                        disabled={submitButtonStatus ? false : true}
                      >
                        <span>Register</span>
                        <div className="btn-loading-wrapper">
                          <div className="btn-loading-spinner"></div>
                        </div>
                      </button>
                    )}
                    {isSubmitBtnVisible && (
                      <p className="terms-content">
                        {" "}
                        By clicking "Submit", I agree to Lugath's Terms of{" "}
                        <Link to="/privacy-policy" onClick={() => onClose()}>
                          Terms of Service{" "}
                        </Link>
                        and{" "}
                        <Link to="/privacy-policy" onClick={() => onClose()}>
                          Privacy Policy.
                        </Link>
                      </p>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      }
    </>
  );
}
