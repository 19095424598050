import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import CONFIG from "../../Services/ConfigService";
import { getCookie, setCookie } from "../../Libs/utils";
import "./index.scss";

export default function CookiePopUp() {
  const [showBox, setShowBox] = useState(false);
  const navigate = useNavigate();
  const cookieWrapper = useRef(null);
  const onClickCookieAction = (actionType) => {
    if (actionType === "close") {
      setShowBox(false);
    }

    if (actionType === "accept") {
      acceptOnAllCookieSettings();
    }

    if (actionType === "settings") {
      setShowBox(false);
      navigate("/cookie-settings");
    }
  };

  const acceptOnAllCookieSettings = () => {
    let cookieSettings;
    try {
      cookieSettings = JSON.parse(
        getCookie(CONFIG.APP.COOKIE_SETTINGS_KEY_NAME)
      );
      let cookieVal;
      if (cookieSettings === null) {
        cookieVal = {
          ...CONFIG.APP.DEFAULT_COOKIE_SETTINGS_VALUE,
          ...{ cookiePopupVisibility: false },
        };
      } else {
        cookieVal = {
          ...CONFIG.APP.DEFAULT_COOKIE_SETTINGS_VALUE,
          ...{ cookiePopupVisibility: false },
        };
      }
      setCookie(
        CONFIG.APP.COOKIE_SETTINGS_KEY_NAME,
        JSON.stringify(cookieVal),
        CONFIG.APP.COOKIE_SETTINGS_EXPIRE_IN_DAYS
      );
      setShowBox(false);
    } catch (e) {
      return console.error(e);
    }
  };

  useEffect(() => {
    let cookieSettings;
    try {
      cookieSettings = JSON.parse(
        getCookie(CONFIG.APP.COOKIE_SETTINGS_KEY_NAME)
      );
      if (cookieSettings === null) {
        setShowBox(
          CONFIG.APP.DEFAULT_COOKIE_SETTINGS_VALUE?.cookiePopupVisibility
        );
      } else {
        setShowBox(cookieSettings?.cookiePopupVisibility);
      }
    } catch (e) {
      return console.error(e);
    }
  }, []);

  return (
    <>
      <div
        ref={cookieWrapper}
        className={`cookie-wrapper bg-black ${showBox ? "show" : "hide"}`}
      >
        <div className={`cookie-inner`}>
          <div className={`cookie-container`}>
            <div
              className="cookie-close"
              onClick={() => onClickCookieAction("close")}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="#fff"
                width="32px"
                height="32px"
                viewBox="0 0 24 24"
                stroke="#fff"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </div>
            <div className="flex flex-col w-full">
              <div className="col-span-full cookie-content flex flex-col justify-between">
                <span className="order-2 lg:order-1">
                  This site uses cookies to improve your experience. Please
                  accept the use of cookies on this site. You can review our{" "}
                  <Link to="/cookie-policy">cookie policy </Link> here and our{" "}
                  <Link to="/privacy-policy">privacy policy</Link> here. If you
                  choose to refuse, functionality of this site will be limited.{" "}
                </span>
              </div>
              <div className="col-span-full flex mt-8  cookie-buttons justify-between">
                <button onClick={() => onClickCookieAction("accept")}>
                  Accept
                </button>{" "}
                <button
                  onClick={() => onClickCookieAction("settings")}
                  className="link"
                >
                  Options
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
