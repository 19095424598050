import React, { useState, useEffect } from "react";
import Image from "../../Components/Image";
import { Link, useNavigate, useParams } from "react-router-dom";
import { ReactComponent as ArrowBackward } from "../../Assets/Icons/arrow-backward.svg";
import Gridder from "../../Components/Gridder";
import CONFIG from "../../Services/ConfigService";
import ProductDetailsDemo from "../../Components/DetailsPageComponents/ProductDetailsDemoVideo";
import HeroImage from "../../Components/HeroImage";
import Integrations from "../../Components/Integrations";
import GenericFAQs from "../../Components/GenericFAQ";
import HeroTextToutWithList from "../../Components/TextToutWithList";
import { appsData } from "../../Services/DataService";
import "./index.scss";
const dropboxVideoData = {
  data: {
    title: "See How it works.",
    description:
      "Checkout the video to get an idea about what you can do with Lugath in Dropbox.",
    videoLink: "https://www.youtube.com/watch?v=C2ggjOix9rw",
    applicationPath: "/integrations/dropbox",
    image: {
      source: "Assets/Images/dropbox-cover.png",
      width: 3600,
      height: 2400,
    },
  },
};

export default function IntegrationDetails() {
  const { appName } = useParams();
  const navigate = useNavigate();
  const [activeApp, setActiveApp] = useState(false);
  useEffect(() => {
    if (
      appName &&
      appsData.filter((a) => a.hasPage).find((app) => app.slug === appName)
    ) {
      setActiveApp(appsData.find((app) => app.slug === appName));
    } else {
      navigate.push({ pathname: "/integrations" });
    }
    return () => {
      setActiveApp(false);
    };
  }, [appName]);

  return (
    <div className="integrations-details-page">
      <section className="integrations-details-page__hero">
        <div className="container mx-auto">
          <div className="integrations-details-page__hero-header__wrapper">
            {activeApp ? (
              <>
                <div className="integrations-details-page__hero-header">
                  <div className="integrations-details-page__hero-header__logo">
                    <Image
                      fileName={`Assets/Images/Marketplace/${activeApp.image}`}
                    />
                  </div>
                  <div className="integrations-details-page__hero-header__content">
                    <div className="integrations-details-page__hero-header__content-name">
                      {activeApp.name}
                    </div>

                    <div className="integrations-details-page__hero-header__content-category">
                      {activeApp.categoryLabel}
                    </div>
                  </div>
                </div>
              </>
            ) : null}
            <div className="integrations-details-page__hero-actions">
              <div
                className={`integrations-details-page__hero-actions__label integrations-details-page__hero-actions__label--${activeApp?.status?.alias}`}
              >
                {activeApp?.status?.label}
              </div>
              <div className="integrations-details-page__hero-actions-box">
                <div className="integrations-details-page__hero-actions-box__title">
                  DEVELOPER
                </div>
                <div className="integrations-details-page__hero-actions-box__content">
                  <a target="_blank" href="https://www.lugath.com">
                    Lugath
                  </a>
                </div>
              </div>
              <div className="integrations-details-page__hero-actions-box">
                <div className="integrations-details-page__hero-actions-box__title">
                  INTEGRATION SUPPORT
                </div>
                <div className="integrations-details-page__hero-actions-box__content">
                  <a target="_blank" href="mailto:support@lugath.com">
                    support@lugath.com
                  </a>
                </div>
              </div>
              <div className="integrations-details-page__hero-actions-box">
                <div className="integrations-details-page__hero-actions-box__title">
                  ADDITIONAL INFORMATION
                </div>
                <div className="integrations-details-page__hero-actions-box__content">
                  <a target="_blank" href="https://www.dropbox.com/apps/lugath">
                    Lugath for Dropbox
                  </a>
                </div>
              </div>
              <div className="integrations-details-page__hero-actions-box">
                {/*<div className="integrations-details-page__hero-actions-box__title">
                  GET START
                </div>*/}
                <div className="integrations-details-page__hero-actions-box__content">
                  <a
                    className="js_landing_page_top_cta_btn install-now-btn"
                    target="_blank"
                    href={`${CONFIG.APP.INTEGRATION_URL}?utm_source=${encodeURIComponent(
                      window.location.href
                    )}&utm_medium=referral&utm_content=js_landing_page_top_cta_btn&utm_campaign=DROPBOX`}
                  >
                    Try Now!
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="integrations-details-page__cover ">
        <div className="container mx-auto">
          <div className="parent">
            <div className="div1">
              <div className="integrations-details-page__cover__title">
                Do more with <span className="shine"> lugath </span>
                integrations.
              </div>
              <Link
                className="integrations-details-page__cover__content-cta"
                to="/integrations"
              >
                <span className="icon">
                  <ArrowBackward />
                </span>
                <span className="label">All Integrations</span>
              </Link>
            </div>
            <div className="div2">
              <div className="integrations-details-page__cover__content">
                <div className="integrations-details-page__cover__content-desc">
                  Translate your Dropbox documents with the best content fit AI
                  tools.
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <HeroTextToutWithList
        data={{
          primaryTitle: "",
          secondaryTitle: (
            <>
              <span className="highlight">lugath</span> lets you connect to{" "}
              <span className="highlight-gray">Dropbox</span> and translate your
              documents in seconds. Integrated with multiple AI translation
              tools and translates files with the power of best fit tools
              according to language and domain of your content.
            </>
          ),
          features: [
            {
              title: "Preserve your layout",
              description:
                "Translate office documents in the same file format via preserving all the design.",
            },
            {
              title: "Contribution with",
              description:
                "Option to get contributions from translators if need more creative or secure translation.",
            },
            {
              title: "Memorize Your Content",
              description:
                "Automated memory can reuse previously translated sentences, saving costs and reducing review time.",
            },
            {
              title: "Accurate Consistency",
              description:
                "Define custom glossaries with sector specific terms to increase consistency of translation results.",
            },
          ],
        }}
      />
      <ProductDetailsDemo
        data={{ ...dropboxVideoData.data }}
        options={{ ...dropboxVideoData.options }}
        productName={false}
      />
      <HeroImage
        data={{
          title: (
            <>
              To integrate{" "}
              <span className="highlight-gray">
                your <span style={{ color: "#0061fe" }}>dropbox</span> with
              </span>{" "}
              <span className="highlight">lugath AI</span>
            </>
          ),
          path: "/settings",
          description: "Connect your Dropbox account on settings page.",
          image: {
            source: "Assets/Images/DropboxIntegration-1.png",
            width: 3600,
            height: 2400,
          },
        }}
        options={{
          imageAlignment: "bottom",
        }}
      />
      <HeroImage
        data={{
          title: (
            <>
              Find your{" "}
              <span className="highlight-gray">
                <span style={{ color: "#0061fe" }}>Dropbox</span> document that
                you want to translate.
              </span>{" "}
            </>
          ),
          path: "/projects",
          description:
            "You can browse your dropbox files into lugath. Just select your file and click to translation.",
          image: {
            source: "Assets/Images/DropboxIntegration-2.png",
            width: 3600,
            height: 2400,
          },
        }}
        options={{
          imageAlignment: "bottom",
        }}
      />
      <HeroImage
        data={{
          title: (
            <>
              Customize translation settings{" "}
              <span className="highlight-gray">then start translation.</span>{" "}
            </>
          ),
          path: "/projects",
          description:
            "You can set the customizations like source and target languages, use memory option or custom mt engine.",
          image: {
            source: "Assets/Images/DropboxIntegration-3.png",
            width: 3600,
            height: 2400,
          },
        }}
        options={{
          imageAlignment: "bottom",
        }}
      />
      <HeroImage
        data={{
          title: (
            <>
              Sync translated files back to{" "}
              <span className="highlight-gray">dropbox</span>{" "}
            </>
          ),
          path: "/projects",
          description: "You can easily sync your translated files to dropbox.",
          image: {
            source: "Assets/Images/DropboxIntegration-4.png",
            width: 3600,
            height: 2400,
          },
        }}
        options={{
          imageAlignment: "bottom",
        }}
      />
      <Integrations />
      <GenericFAQs
        questions={[
          {
            id: 0,
            title: "Questions",
            children: [
              {
                id: 1,
                question: "Which file formats are supported?",
                answer:
                  "We are supporting <b>docx, doc, xlsx, xls, pptx</b> and many more. You can check the full list from  <a href='https://docs.lugath.com' target='_blank'>API Doc</a>.",
              },
              {
                id: 2,
                question: "Which languages are supported?",
                answer:
                  "We are supporting <b>27 languages</b> and <b>more than 700</b> language pairs. You can check the full list from  <a href='https://docs.lugath.com' target='_blank'>API Doc</a>.",
              },
              {
                id: 3,
                question: "Can I use a Glossary?",
                answer:
                  "To get a term consistent translation results, you should define your glossaries. Try cross-engine applicable glossary.  </br><a href='/products/glossary'>Learn How</a>",
              },
              {
                id: 4,
                question: "Can I use a Translation Memory?",
                answer:
                  "If you need to re-use already translated or translator edited sentences again, create an Translation Memory.  </br><a href='/products/ai-translation-memory'>Learn How</a>",
              },
              {
                id: 5,
                question: "How can I share my files to get contribution?",
                answer:
                  "You may want to share your files with translators or subject matter experts to get a contribution. Private shared link is here for you.  </br><a href='/products/collaborative-translation'>Learn How</a>",
              },
              {
                id: 6,
                question: "Is it possible to create an approval workflow?",
                answer:
                  "You can monitor all the changes and check the quality of <b>translation, localization and contribution</b>. </br><a href='/products/workflow-management'>Learn How</a>",
              },
              {
                id: 7,
                question: "If I am faced with any problem, what should I do?",
                answer:
                  "Visit our <a href='/faqs'>FAQs</a>, create a support ticket or send an email to <a\n" +
                  '              className="external-link"\n' +
                  '              href="mailto:support@lugath.com"\n' +
                  '              target="_blank"\n' +
                  '              rel="noreferrer"\n' +
                  "            >\n" +
                  "              support@lugath.com\n" +
                  "            </a>",
              },
            ],
          },
        ]}
      />
    </div>
  );
}
