import React from 'react'
import New from '../New';
export default function NewsList(props) {
    return (<>
      {
        props.news.map(item=> {
        return (
          <New item={item}></New>
        )
      }
    )}
      </>)
}








