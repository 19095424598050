import { combineReducers } from "redux";
import { routerReducer } from "react-router-redux";
import products from "./products";
import solutions from "./solutions";
import news from "./news";
import settings from "./settings";

export default combineReducers({
  products,
  solutions,
  news,
  settings,
  routing: routerReducer,
});
