import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import emailjs from "emailjs-com";
import { ReactComponent as LugathFooterLogo } from "../../Assets/Icons/logo-footer-black.svg";
import { ReactComponent as FacebookLogo } from "../../Assets/Icons/social/facebook.svg";
import { ReactComponent as XLogo } from "../../Assets/Icons/social/x.svg";
import { ReactComponent as LinkedinLogo } from "../../Assets/Icons/social/linkedin.svg";
import { ReactComponent as InstagramLogo } from "../../Assets/Icons/social/instagram.svg";
import { ReactComponent as GithubLogo } from "../../Assets/Icons/social/github.svg";
import "./index.scss";

export default function Footer() {
  const { handleSubmit, register, errors, reset } = useForm();
  const [formSubmitStatus, setFormSubmit] = useState(false);
  const onSubmit = (data) => {
    dataIsValid() ? sendFormActions(data) : backToFormActions();
  };

  const backToFormActions = () => {};

  const dataIsValid = () => {
    return true;
  };

  const sendFormActions = (data) => {
    emailjs
      .send(
        "service_ea6svqr",
        "template_yknwxve ",
        data,
        "user_nEhE1W4DvHXMNnflvmRDY"
      )
      .then(
        (result) => {
          setFormSubmit(true);
          reset();
        },
        (error) => {}
      );
  };
  return (
    <>
      <section className="bg-footer js-section_nav-dark">
        <div className="container mx-auto">
          <div className="footer">
            <div className="parent">
              <div className="div1 footer-logo__wrapper">
                <div className="div1 footer-logo">
                  <LugathFooterLogo />
                </div>
              </div>
              <div className="div2 footer-cta-slogan__wrapper">
                <div className="footer-cta-slogan">
                  <div className="footer-cta-slogan__content">
                    Ready to invest in growth?{" "}
                    <a
                      href="mailto:support@lugath.com"
                      style={{ textDecoration: "underline", color: "#202020" }}
                    >
                      Let’s talk.
                    </a>
                  </div>
                </div>
              </div>
              <div className="div3 footer-newsletter-block__wrapper">
                <div
                  style={{ display: "none" }}
                  className="footer-newsletter-block"
                >
                  <div className="footer-newsletter-block__title">
                    Subscribe to Our Newsletter
                  </div>
                  <div className="footer-newsletter-block__component">
                    <form
                      id="newsletter-form"
                      onSubmit={handleSubmit(onSubmit)}
                      autoComplete="off"
                      noValidate
                    >
                      <div className="input-wrapper">
                        <input
                          ref={register({
                            required: {
                              value: true,
                              message: "Your Email is required.",
                            },
                            pattern: {
                              value:
                                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                            },
                          })}
                          name="workEmail"
                          id="workemail"
                          type="email"
                          placeholder="yourmail@example.com"
                          required
                        />
                        <label htmlFor="workemail"></label>

                        <button
                          type="submit"
                          disabled={formSubmitStatus ? true : false}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="8"
                            height="14"
                            viewBox="0 0 8 14"
                          >
                            <path
                              fill="rgb(255,255,255)"
                              fillRule="evenodd"
                              d="M5.58578644 7L.29289322 12.29289322c-.3905243.3905243-.3905243 1.02368927 0 1.41421356.3905243.3905243 1.02368927.3905243 1.41421356 0l6-6c.3905243-.3905243.3905243-1.02368927 0-1.41421356l-6-6c-.3905243-.3905243-1.02368927-.3905243-1.41421356 0-.3905243.3905243-.3905243 1.02368927 0 1.41421356L5.58578644 7z"
                            />
                          </svg>
                        </button>
                      </div>

                      {errors.workEmail ? (
                        <p className="error-message">
                          Must be valid email example@yourdomain.com.
                        </p>
                      ) : null}
                      {formSubmitStatus ? (
                        <p className="response-message">
                          Thank you for your subscription.
                        </p>
                      ) : null}
                    </form>
                  </div>
                </div>
              </div>
              <div className="div4 footer-stay-in-touch__wrapper">
                <div className="footer-stay-in-touch">
                  <div className="footer-stay-in-touch__title">
                    Lugath automates web, mobile, or game app localization for
                    global growth with generative AI.
                  </div>
                </div>
              </div>
              <div className="div5 footer-navigate-menu__wrapper">
                {" "}
                <div className="footer-navigate-menu">
                  <div className="footer-navigate-menu__title">Navigate</div>
                  <div className="footer-navigate-menu__items-wrapper">
                    <ul className="footer-navigate-menu__items">
                      <li className="footer-navigate-menu__item">
                        <Link to="/solutions">Solutions</Link>
                      </li>
                      <li className="footer-navigate-menu__item">
                        {" "}
                        <Link to="/products">Products</Link>
                      </li>

                      <li className="footer-navigate-menu__item">
                        <Link to="/pricing">Pricing</Link>
                      </li>
                      <li className="footer-navigate-menu__item">
                        {" "}
                        <a
                          className="cursor-pointer"
                          target="_blank"
                          rel="noreferrer"
                          href={"https://docs.lugath.com"}
                        >
                          API
                        </a>
                      </li>
                      <li className="footer-navigate-menu__item">
                        <Link to="/security">Security</Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="div6 footer-resource-menu__wrapper">
                <div className="footer-navigate-menu">
                  <div className="footer-navigate-menu__title">Resources</div>
                  <div className="footer-navigate-menu__items-wrapper">
                    <ul className="footer-navigate-menu__items">
                      <li className="footer-navigate-menu__item">
                        <Link to="/reports">Reports</Link>
                      </li>

                      <li className="footer-navigate-menu__item">
                        <Link to="/integrations">Integrations</Link>
                      </li>
                      <li className="footer-navigate-menu__item">
                        <Link to="/news">Updates</Link>
                      </li>
                      <li className="footer-navigate-menu__item">
                        <Link to="/tutorials">Tutorials</Link>
                      </li>

                      <li className="footer-navigate-menu__item">
                        <Link to="/faqs">FAQs</Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="div7 footer-terms-menu__wrapper">
                <div className="footer-terms">
                  <div className="footer-terms__title">Legal</div>
                  <ul className="footer-terms-menu__list">
                    <li className="footer-terms-menu__list-item">
                      <Link to="/privacy-policy">Privacy Policy</Link>
                    </li>
                    <li className="footer-terms-menu__list-item">
                      <Link to="/terms-of-use">Terms of Use</Link>
                    </li>
                    <li className="footer-terms-menu__list-item">
                      <Link to="/cookie-settings">Cookies</Link>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="div8 footer-social-media__wrapper">
                <div className="footer-social-media">
                  <div className="div8 footer-social-media__title">
                    Follow us
                  </div>
                  <ul className="footer-social-media__list">
                    <li className="footer-social-media__list-item">
                      <Link
                        target="_blank"
                        to={"https://www.facebook.com/Lugathcom"}
                      >
                        <FacebookLogo />
                      </Link>
                    </li>
                    <li className="footer-social-media__list-item">
                      <Link
                        target="_blank"
                        to={"https://www.twitter.com/lugathcom"}
                      >
                        <XLogo />
                      </Link>
                    </li>
                    <li className="footer-social-media__list-item">
                      <Link
                        target="_blank"
                        to={"https://www.linkedin.com/company/lugath/"}
                      >
                        <LinkedinLogo />
                      </Link>
                    </li>
                    <li className="footer-social-media__list-item">
                      <Link target="_blank" to={"https://github.com/lugath"}>
                        {" "}
                        <GithubLogo />
                      </Link>
                    </li>
                    <li className="footer-social-media__list-item">
                      <Link
                        target="_blank"
                        to={"https://www.instagram.com/lugathcom/"}
                      >
                        <InstagramLogo />
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/*<div className="footer-bottom__wrapper">
        <div className="container mx-auto">
          Hi 👋 Want to ask our team member to see if our product could help
          your team?
        </div>{" "}
                      </div>*/}
    </>
  );
}
