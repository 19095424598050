import React, { useState, useEffect } from "react";
import { v4 as uuidv4 } from "uuid";
import { Link } from "react-router-dom";
import { ReactComponent as ArrowOutward } from "../../Assets/Icons/arrow_outward.svg";
import { profilesData } from "../../Services/DataService";
import "./index.scss";
import CTAButton from "../CTABtnWrapper";

export default function OtherProfiles({ data, productName }) {
  const [visibleProfiles, setVisibleProfiles] = useState([]);
  const [availableProfiles] = useState(profilesData);
  const getVisibleProfiles = () => {
    const visibleProfiles = availableProfiles.filter((a) =>
      data.profiles.map((p) => p.profile).includes(a.alias)
    );

    setVisibleProfiles(
      visibleProfiles.map((p) => {
        const incomingProfile = data.profiles.find(
          (f) => f.profile === p.alias
        );
        return incomingProfile?.description
          ? { ...p, ...{ description: incomingProfile.description } }
          : p;
      })
    );
  };

  useEffect(() => {
    if (data?.profiles?.length) {
      getVisibleProfiles();
    }
  }, [data, availableProfiles]);
  return (
    <section className="product-other-solutions-bg ">
      <div className="container mx-auto product-other-solutions__container">
        <div className="product-other-solutions__wrapper">
          <div className="product-other-solutions__inner">
            <div className="parent">
              <div className="div1">
                <div className="product-other-solutions__title">
                  {" "}
                  {data?.title}
                </div>
              </div>
              <div className="div2">
                {" "}
                <div className="product-other-solutions__desc-wrapper">
                  <div className="product-other-solutions__desc-title">
                    {data?.description}
                  </div>
                  {data.cta.map((c, i) => (
                    <CTAButton
                      key={uuidv4()}
                      options={{
                        formPopupTheme: "light",
                        utmLabel: "js_other_profiles__cta_btn",
                      }}
                      children={
                        <div
                          key={uuidv4()}
                          className="product-other-solutions__desc-link"
                        >
                          <span className="label">{c?.label}</span>
                          <span className="icon">
                            {" "}
                            <ArrowOutward />
                          </span>
                        </div>
                      }
                      data={{
                        ...c,
                        ...{ productName: productName ? productName : "" },
                      }}
                    ></CTAButton>
                  ))}
                </div>
              </div>
              {visibleProfiles?.length
                ? visibleProfiles.map((p, index) => (
                    <div key={index} className={`div${index + 3}`}>
                      <Link to={p.link}>
                        <div className="product-other-solutions__card-wrapper">
                          <div className="product-other-solutions__card">
                            <div className="product-other-solutions__card-content">
                              <div className="product-other-solutions__card-icon">
                                <ArrowOutward />
                              </div>
                              <div className="product-other-solutions__card-title">
                                {p.title}
                              </div>
                              <div className="product-other-solutions__card-desc">
                                {p.description}
                              </div>
                            </div>
                          </div>
                        </div>
                      </Link>
                    </div>
                  ))
                : null}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
