import React from "react";
import { useDispatch } from "react-redux";
import { showFormPopUp } from "../../Reducers/settings";
import "./index.scss";

export default function FifthSection(props) {
  const dispatch = useDispatch();
  const onClickBtn = () => {
    dispatch(
      showFormPopUp({
        content: {
          type: "book-a-demo",
        },
        theme: "light",
      })
    );
  };

  return (
    <section className="get-started-bg js-section_nav-dark">
      <div className="grid-container container mx-auto">
        <div className="col-span-full lg:col-span-6 hidden lg:block"></div>
        <div className="lg:col-start-2 col-span-full lg:col-span-6 flex items-center justify-center">
          <div className="get-started-title">
            <h1 className="text-center pb-4 w-full get-started-title">
              Join <span className="highlight">lugath</span> to enjoy remarkably
              faster and smart translation experience
            </h1>
          </div>
        </div>
        <div className="col-span-full text-center justify-center">
          <button
            className="get-started-btn"
            onClick={() => {
              onClickBtn();
            }}
          >
            {" "}
            Book a Demo{" "}
          </button>
        </div>
      </div>
    </section>
  );
}
