import { v4 as uuidv4 } from "uuid";

export const products = [
  {
    id: uuidv4(),
    header: "dark",
    title: "AI <br/> Translation ",
    desc: "Elevate your content to new heights with the brilliance of AI. Achieve flawless, context-aware translations within seconds. Translate, condense, rephrase, enhance for SEO, and much more.",
    linkTitle: "Read More",
    slug: "generative-ai-translation",
    showNumber: true,
    icon: "product-ai-translation.svg",
    active: true,
    enable: true,
    productName: "AI Translation",
    status: { alias: "beta", label: "BETA" },
    modules: [
      {
        moduleName: "ProductDetailsHeader",
        data: {
          status: { alias: "beta", label: "BETA" },
          index: "01",
          header: "AI <br/> Translation",
          alias: "Lugath AI",
          icon: "product-1.svg",
          shortText: "Power up your localization with generative AI",
          description:
            "Artificial intelligence feature that helps to create high-quality content faster. Stop chasing word-count and start pursuing ideas.",
          cta: [
            {
              label: "Sign Up for Early Access",
              action: "early-access",
            },
          ],
        },
      },
      {
        moduleName: "ProductFeatureList",
        data: {
          title:
            "Increase your productivity and performance, optimize your localization content with helps of <b>lugath AI</b>",
          featureList: [
            {
              wrapper: "div2",
              title: "One click",
              description:
                "Scale Your Content Translation with Ease, Even with Thousands, and Stay within Budget.",
              image: {
                source: "Assets/Images/feature-1.png",
                width: 1080,
                height: 804,
              },
            },
            {
              wrapper: "div3",
              title: "Sounds Human",
              description:
                "Enhance Translation Accuracy with Contextual Guidance. Instruct lugath AI on tone, style, industry, audience, and other factors for translations that resonate naturally.",
              image: {
                source: "Assets/Images/feature-2.png",
                width: 1124,
                height: 448,
              },
            },
            {
              wrapper: "div4",
              title: "Rephrase",
              description:
                "Create unique content in less time with lugath AI state-of-the-art paraphrasing tool.",
            },
            {
              wrapper: "div5",
              title: "Shorten",
              description:
                "Less is more! Create shorter alternatives for your message.",
            },
            {
              wrapper: "div6",
              title: "SEO friendly Optimization",
              description:
                "Uncovers valuable keywords and produces optimized content via proprietary algorithms and Google SERP analysis.",
            },
            {
              wrapper: "div7",
              title: "Suggest Variants",
              description:
                "Ability to suggest variant prediction with deep generative models of localization data.",
            },
            {
              wrapper: "div8",
              title: "High Quality",
              description:
                "Evaluates multiple translation engines and chooses the best one for your content type and language pair, so you get AI translations that are the perfect match.",
              image: {
                source: "Assets/Images/feature-3.png",
                width: 1576,
                height: 712,
              },
            },
          ],
          cta: [
            {
              label: "Sign Up for Early Access",
              action: "early-access",
            },
          ],
        },
      },
      {
        moduleName: "OtherProfiles",
        data: {
          title: "Boost your team’s productivity tenfold",
          description:
            "To make automation technology work best for your business with time-saving tool your team has been missing.",
          cta: [
            {
              label: "Sign Up for Early Access",
              action: "early-access",
            },
          ],
          profiles: [
            {
              profile: "managers",
              description:
                "Update existing and launch new features and products while simultaneously localizing them in multiple languages.",
            },
            {
              profile: "translators",
              description:
                "Eliminate double-work and neverending revisions with visual context (screenshots) and live previews.",
            },
            {
              profile: "developers",
              description:
                "Set up an integration with your code repository or your own custom SDK via Lugath's powerful API.",
            },
          ],
        },
      },
      {
        moduleName: "ProductFAQs",
        data: {
          title: "Questions",
          questions: [
            {
              id: 1,
              question: "What is lugath AI?",
              answer:
                "Our latest translation tool, <b>lugath AI</b>, utilizes advanced generative AI technology. It's a valuable asset for high-volume content translation with contextual precision.",
            },
            {
              id: 2,
              question: "How does lugath AI work?",
              answer:
                "<b>lugath AI</b> is powered by <b>OpenAI</b> models. We customized the large language models to improve translation quality. You can get more accurate translations with adding context, such as character limits, comments, glossary terms, and even style guides.",
            },
            {
              id: 3,
              question: "What are the benefits of lugath AI for translation?",
              answer:
                "<b>lugath AI</b> can enhance your productivity by customizing your content, making it shorter, SEO-friendly, or even rephrasing it.",
            },
            {
              id: 4,
              question:
                "What types of content can be translated with lugath AI?",
              answer:
                "<b>lugath AI</b> translation showcases its adaptability by expertly managing diverse content types, spanning websites, documents, emails, marketing materials, user-generated content, and beyond.",
            },
            {
              id: 5,
              question: "Is lugath AI secure and private?",
              answer:
                "Certainly! We take data security and privacy seriously. <b>lugath AI</b> translation follows strict security protocols to protect your data and comply with privacy regulations. Our partnership with OpenAI involves secure data transfer, with industry-standard encryption for data in transit and at rest. OpenAI adheres to ISO 27001, SOC 2 Type II, and GDPR standards. Data is retained for 30 days for monitoring, after which it's deleted. Neither Lugath nor OpenAI uses your data for training without your consent.",
            },
            {
              id: 6,
              question: "What about the accuracy of translations?",
              answer:
                "<b>lugath AI</b> translations can be around 80% accurate, but this can vary depending on the languages and how complex the content is. Most of the time, you'll just need to make some minor tweaks to get it exactly right for your needs.",
            },
          ],
        },
      },
      {
        moduleName: "BetaForm",
        data: {
          subscriberCount: 106,
          cta: [
            {
              label: "Get Early Access",
              action: "early-access",
            },
          ],
        },
      },
      { moduleName: "Integrations", data: {} },
    ],
  },
  {
    id: uuidv4(),
    title: "AI <br/> Quality <br/>Assurance",
    desc: "Work with Lugath MT Engine that delivers the most compatible service based on language pair and sector.",
    linkTitle: "Read More",
    slug: "ai-quality-assurance",
    showNumber: true,
    icon: "product-quality-assurance.svg",
    productName: "AI Quality Assurance",
    active: true,
    modules: [
      {
        moduleName: "ProductDetailsHeader",
        data: {
          status: { alias: "upcoming", label: "Upcoming" },
          index: "02",
          header: "AI <br/> Translation",
          alias: "Linguistic Quality",
          icon: "product-2.svg",
          shortText: "Linguistic quality assurance powered by generative AI",
          description:
            "LQA powered by AI: Your express route to enhanced linguistic quality assurance, faster and more efficient than ever.",
          cta: [
            {
              label: "Sign Up for Early Access",
              action: "early-access",
            },
          ],
        },
      },
      {
        moduleName: "ProductFeatureList",
        data: {
          title:
            "AI-powered assistant for automated issue detection, comprehensive reports, and cost-effective quality improvement",
          featureList: [
            {
              wrapper: "div2",
              title: "Execute Automatically",
              description: "Auto-detect problems and recommend corrections.",
              image: {
                source: "Assets/Images/feature-ai-lqa.png",
                width: 1802,
                height: 1200,
              },
            },
            {
              wrapper: "div3",
              title: "Comprehensive LQA Reporting",
              description:
                "Get LQA reports with language-specific scores to quickly assess their compliance with your quality criteria.",
              //image: "feature-2.png",
            },
            {
              wrapper: "div4",
              title: "Cost-Effective",
              description:
                "Elevate your translation quality in a short time with unbeatable AI power.",
            },
          ],
          cta: [
            {
              label: "Sign Up for Early Access",
              action: "early-access",
            },
          ],
        },
      },
      {
        moduleName: "OtherProfiles",
        data: {
          productName: "AI Quality Assurance",
          title: "Unlock expertise in linguistic quality assurance",
          description:
            "Register on the waiting list to secure your spot. Once accepted, watch for a confirmation email.",
          cta: [
            {
              label: "Sign Up for Early Access",
              action: "early-access",
            },
          ],
          profiles: [
            {
              profile: "managers",
              description:
                "Uplift your quality standards through diligent oversight.",
            },
            {
              profile: "translators",
              description:
                "Make final checks before finishing your work to address translation quality criteria.",
            },
            {
              profile: "enterprise",
              description:
                "Keep the tone of voice of your brand for each content type.",
            },
          ],
        },
      },
      {
        moduleName: "ProductFAQs",
        data: {
          title: "Questions",
          questions: [
            {
              id: 1,
              question: "How can I access to lugath AI LQA?",
              answer:
                "Please sign up for our waiting list to secure your access. Upon approval, we will be sure to send you a confirmation email.",
            },
            {
              id: 2,
              question:
                "What features are available in beta for lugath AI LQA?",
              answer:
                "In the beta program, you can use <b>lugath AI LQA</b> for automated quality assessments, language-specific reports, and pinpointing translation issues.",
            },
            {
              id: 3,
              question: "Why is lugath AI LQA in beta?",
              answer:
                "To gain precious insight and feedback to improve <b>lugath AI LQA</b>.",
            },
          ],
        },
      },
      {
        moduleName: "BetaForm",
        data: {
          productName: "AI Quality Assurance",
          subscriberCount: 243,
          cta: [
            {
              label: "Sign Up for Early Access",
              action: "early-access",
            },
          ],
        },
      },
      { moduleName: "Integrations", data: {} },
    ],
  },
  {
    id: uuidv4(),
    title: "AI <br/> Translation <br/> Memory",
    desc: "Use your cost efficiently with AI-powered Translation Memory!",
    linkTitle: "Read More",
    slug: "ai-translation-memory",
    showNumber: true,
    icon: "product-translation-memory.svg",
    productName: "AI Translation Memory",
    active: true,
    modules: [
      {
        moduleName: "ProductDetailsHeader",
        data: {
          status: { alias: "live", label: "Live" },
          index: "03",
          header: "AI <br/> Translation",
          alias: "Automated TM",
          icon: "product-3.svg",
          shortText:
            "Save and Reuse Machine or Human Translated Content Effortlessly",
          description:
            "AI TM automatically keeps machine translated or translator contributed content for you and gives the flexibility to reuse it with no human touch.",
          cta: [
            {
              label: "Free Trial",
              action: "register",
            },
            {
              label: "Get a Demo",
              action: "book-a-demo",
            },
          ],
        },
      },
      {
        moduleName: "ProductDetailsDemo",
        data: {
          title:
            "See How <b>AI Translation Memory</b> Can Easily <strong>Minimize Rework</strong>",
          description:
            "Discover the ease of a cloud-based, plug-and-play AI Translation Memory that seamlessly integrates with multiple engines.",
          videoLink: "https://www.youtube.com/watch?v=li6qYZg8KmA",
          applicationPath: "/memories",
          image: {
            source: "Assets/Images/Memory/CreateNewMemory.png",
            width: 3600,
            height: 2400,
          },
        },
      },
      {
        moduleName: "ProductFeatureList",
        data: {
          title:
            "Supercharge your <b>brand voice.</b> Store all translation efforts for each piece of content",
          featureList: [
            {
              wrapper: "div2",
              title: "Create Easily",
              description:
                "Simply provide a name and description for your Translation Memory; it's that easy.",
            },
            {
              wrapper: "div4",
              title: "Plug & Play",
              description:
                "Before you start translating, pick your Translation Memory, and watch as AI seamlessly integrates previously translated segments into your translation process.",
            },
            {
              wrapper: "div3",
              title: "Intelligent Suggestion",
              description:
                "The Translation Memory will consist of four segment types: Approved, Post-edited, Glossary-applied, and Raw MT Results. AI will intelligently store and recommend them based on priority with no human touch.",
            },
            {
              wrapper: "div5",
              title: "Cloud-Based",
              description:
                "Expand your options for alternative services with centralized translation memory in the cloud.",
            },
            {
              wrapper: "div6",
              title: "Cost Effective",
              description:
                "Save on costs up to 75% by having access to previously translated content, eliminating the need for overusing resources for repeated services.",
            },
            {
              wrapper: "div7",
              title: "Cross Engine Applicable",
              description:
                "Craft your Translation Memory once and unlock its full potential across all integrated MT services for endless reuse.",
            },
            {
              wrapper: "div8",
              title: "Minimize Reviews",
              description:
                "The segment status indicates whether a review is required or not.",
            },
          ],
          cta: [
            {
              label: "Try all features for 14 days",
              action: "register",
            },
          ],
        },
      },
      {
        moduleName: "Testimonials",
        data: {
          testimonials: [
            {
              id: 0,
              author: "Sebastian Sandler",
              title: "Technical Documentation Manager",
              brand: "SIEMENS",
              rating: 4,
              image: "Assets/Images/Testimonials/image.png",
              testimonial:
                "Siemens AG successfully implemented an industry 4.0-compliant automated translation workflow, leading to significant reductions in time and resources required for technical document translation.",
            },
          ],
        },
      },
      {
        moduleName: "OtherProfiles",
        data: {
          title: "Save your time and money",
          description:
            "Maintain your brand's unique tone of voice across all content types.",
          cta: [
            {
              label: "Start For Free",
              action: "register",
            },
          ],
          profiles: [
            {
              profile: "managers",
              description:
                "Cut your costs by up to 75% with access to pre-translated content.",
            },
            {
              profile: "translators",
              description:
                "Perform post-editing or proofreading once, and let AI handle the rest.",
            },
            {
              profile: "developers",
              description:
                "Simply send your translation requests, without the need to construct a caching mechanism.",
            },
          ],
        },
      },
      {
        moduleName: "ProductFAQs",
        data: {
          title: "Questions",
          questions: [
            {
              id: 1,
              question: "What is lugath AI Translation Memory?",
              answer:
                "<b>lugath AI TM</b> automatically keeps translated content for you and gives the flexibility to reuse it on following translations with no human touch.",
            },
            {
              id: 2,
              question: "How does lugath AI TM work?",
              answer:
                "<b>lugath AI TM</b> stores and suggests segments across various translated versions, using a priority-based approach to automatically select the best-fitting translation for your content.",
            },
            {
              id: 3,
              question:
                "What are the benefits of lugath AI TM for translation?",
              answer:
                "You can store all translation efforts for each piece of content. Make translation, post-edit or proofread one time and <b>re-use it</b> again and again to <b>keep brand voice consistent</b>.",
            },
            {
              id: 4,
              question: "Is lugath AI TM private?",
              answer:
                "Our customers' data is kept in isolated, private environments, with no mixing of usage.",
            },
          ],
        },
      },
      { moduleName: "Integrations", data: {} },
    ],
  },
  {
    id: uuidv4(),
    title: "Workflow <br/> Management",
    desc: "Use your cost efficiently with AI-powered Translation Memory!",
    linkTitle: "Read More",
    slug: "localization-workflow",
    showNumber: true,
    icon: "product-workflow-management.svg",
    productName: "Workflow Management",
    active: true,
    modules: [
      {
        moduleName: "ProductDetailsHeader",
        data: {
          status: { alias: "live", label: "Live" },
          index: "03",
          productName: "Workflow Management",
          header: "AI <br/> Translation",
          alias: "Workflow Management",
          icon: "product-4.svg",
          shortText: "Lead your entire localization workflow",
          description:
            "Easily automate the creation, localization, and publication of your content with excellent team communication. ",
          cta: [
            {
              label: "Free Trial",
              action: "register",
            },
            {
              label: "Get a Demo",
              action: "book-a-demo",
            },
          ],
        },
      },
      { moduleName: "WorkflowScheme", data: {} },
      {
        moduleName: "ProductFeatureList",
        data: {
          title:
            "Easily <b>create</b>, <b>localize</b>, and <b>publish</b> content for a global audience",
          featureList: [
            {
              wrapper: "div2",
              title: "Start with Design",
              description:
                "Let your imagination run wild and design the user experience with complete creativity.",
            },
            {
              wrapper: "div3",
              title: "Develop the Product",
              description:
                "Implement proper internationalization (i18n) practices to make your software support multiple languages.",
            },
            {
              wrapper: "div4",
              title: "Create the Content",
              description:
                "Websites, blogs, marketing materials – you can create a wide range of content to enhance global visibility.",
            },
            {
              wrapper: "div5",
              title: "Translate and Localize",
              description:
                "Ensure that all your content communicates in the same language and culture as your users or customers.",
            },
            {
              wrapper: "div6",
              title: "Review the Quality",
              description:
                "Ensure the quality of translation meet your criteria.",
            },
            {
              wrapper: "div7",
              title: "Publish the Content",
              description:
                "Share your localized content on appropriate platforms.",
            },
            {
              wrapper: "div8",
              title: "Give a Support",
              description:
                "Adapt all support channels to match the language of your customers.",
            },
          ],
          cta: [
            {
              label: "Try all features for 14 days",
              action: "register",
            },
          ],
        },
      },
      {
        moduleName: "OtherProfiles",
        data: {
          title: "Customize your workflow to suit specific needs",
          description:
            "Simplify the automation of content creation, localization, and publication through effective team communication.",
          cta: [
            {
              label: "Start For Free",
              action: "register",
            },
          ],
          profiles: [
            {
              profile: "managers",
              description:
                "Manage the communication bridge between content creators and translators.",
            },
            {
              profile: "developers",
              description: "Integrate your CI/CD process with localization.",
            },
            {
              profile: "enterprise",
              description:
                "Working with translation agencies has never been easier.",
            },
          ],
        },
      },
      { moduleName: "Integrations", data: {} },
    ],
  },
  {
    id: uuidv4(),
    title: "Smart <br/> Glossary",
    desc: "Use your cost efficiently with AI-powered Translation Memory!",
    linkTitle: "Read More",
    slug: "glossary",
    showNumber: true,
    icon: "product-glossary.svg",
    active: true,
    productName: "Glossary",
    modules: [
      {
        moduleName: "ProductDetailsHeader",
        data: {
          status: { alias: "live", label: "Live" },
          index: "03",
          productName: "Glossary",
          header: "AI <br/> Translation",
          alias: "Terminology Management",
          icon: "product-5.svg",
          shortText: "Boost translation consistency",
          description:
            "Easily achieve accurate and consistent translations across different platforms and tools with our cross-engine glossary. Regardless of your preferred MT engine, our solution ensures a streamlined and efficient localization process.",
          cta: [
            {
              label: "Free Trial",
              action: "register",
            },
            {
              label: "Get a Demo",
              action: "book-a-demo",
            },
          ],
        },
      },
      {
        moduleName: "ProductDetailsDemo",
        data: {
          title:
            "See How To <b>Speak Same Language</b> As Your Industry With <strong>Glossary</strong>",
          description:
            "Discover the key to effective industry-specific communication as you master the art of aligning your language with your profession through our comprehensive glossary.",
          videoLink: "https://www.youtube.com/watch?v=tqrDI7ZDFCk",
          applicationPath: "/glossaries",
          image: {
            source: "Assets/Images/Glossary/SelectedTerms.png",
            width: 3600,
            height: 2400,
          },
        },
      },
      {
        moduleName: "ProductFeatureList",
        data: {
          title:
            "Increase your <b>industry-specific term consistency</b> with the help of <b>lugath</b> glossary",
          featureList: [
            {
              wrapper: "div2",
              title: "Cross Engine Applicable",
              description:
                "Craft your Glossary once and unlock its full potential across all integrated MT services.",
            },
            {
              wrapper: "div3",
              title: "Terminology Consistency",
              description:
                "Ensure the accurate use of industry-specific terminology in your content.",
            },
            {
              wrapper: "div4",
              title: "Bulk Import or Export",
              description:
                "Begin translating now by uploading your own glossaries.",
            },
          ],
          cta: [
            {
              label: "Try all features for 14 days",
              action: "register",
            },
          ],
        },
      },
      {
        moduleName: "Testimonials",
        data: {
          testimonials: [
            {
              id: 0,
              author: "Sebastian Sandler",
              title: "Technical Documentation Manager",
              brand: "SIEMENS",
              rating: 4,
              image: "Assets/Images/Testimonials/image.png",
              testimonial:
                "Within technical documentation, numerous industry-specific terms are employed across thousands of pages. The glossary feature perfectly addresses the need for accurate and consistent terminology application.",
            },
          ],
        },
      },
      {
        moduleName: "OtherProfiles",
        data: {
          title:
            "Ensure the accurate use of industry-specific terminology in your content",
          description:
            "Show your domain knowledge with consistent terminology.",
          cta: [
            {
              label: "Start For Free",
              action: "register",
            },
          ],
          profiles: [
            {
              profile: "managers",
              description:
                "Verify the precision of industry-specific terminology within your content.",
            },
            {
              profile: "translators",
              description: "Speak the same language as your industry.",
            },
            {
              profile: "enterprise",
              description: "Display your expertise in multiple languages.",
            },
          ],
        },
      },
      {
        moduleName: "ProductFAQs",
        data: {
          title: "Questions",
          questions: [
            {
              id: 1,
              question: "What is Smart Glossary?",
              answer:
                "<b>Smart Glossary</b> is designed to increase your industry-specific term consistency. You can customize the translation behavior of all MT engines that we have integrated.",
            },
            {
              id: 2,
              question: "How does Smart Glossary work?",
              answer:
                "<b>Smart Glossary</b> terms work in two ways: one for <b>replacing</b> the source term with the target term and another for <b>protecting</b> the source term in each translation. We are applying glossaries in the machine translation stage.",
            },
            {
              id: 3,
              question:
                "What are the benefits of Smart Glossary for translation?",
              answer:
                "Increasing translation consistency and keeping the brand voice on each content.",
            },
            {
              id: 4,
              question:
                "What are the main differences between regular glossary and Smart Glossary?",
              answer:
                "<b>Smart Glossary</b> is <b>cross engine applicable</b>. Create one time and use it with multiple MT engines.",
            },
          ],
        },
      },
      { moduleName: "Integrations", data: {} },
    ],
  },
  {
    id: uuidv4(),
    title: "Online <br/> CAT Tool",
    desc: "Use your cost efficiently with AI-powered Translation Memory!",
    linkTitle: "Read More",
    slug: "online-cat-tool",
    showNumber: true,
    icon: "product-cat-tool.svg",
    active: true,
    productName: "Online CAT Tool",
    modules: [
      {
        moduleName: "ProductDetailsHeader",
        data: {
          status: { alias: "live", label: "Live" },
          index: "03",
          productName: "Online CAT Tool",
          header: "AI <br/> Translation",
          alias: "Editor",
          icon: "product-6.svg",
          shortText: "Be a Master on Translation and Localization",
          description:
            "Translate your content and fine-tune segment by segment for top-notch quality. Lugath's online CAT tool is at your service.",
          cta: [
            {
              label: "Free Trial",
              action: "register",
            },
            {
              label: "Get a Demo",
              action: "book-a-demo",
            },
          ],
        },
      },
      {
        moduleName: "ProductDetailsDemo",
        data: {
          title:
            "See How Easy To <b>Translate and Localize</b> With <strong>Online CAT Tool</strong>",
          description:
            "Discover a new level of translation and localization productivity with lugath’s online CAT which is designed for everyone.",
          videoLink: "https://www.youtube.com/watch?v=99cETQNs1bI",
          applicationPath: "/project/view",
          image: {
            source: "Assets/Images/CATTool/CAT-tool.png",
            width: 3600,
            height: 2400,
          },
        },
      },
      {
        moduleName: "ProductFeatureList",
        data: {
          title:
            "Refresh your confidence with the <b>lugath CAT</b>, designed for everyone",
          featureList: [
            {
              wrapper: "div2",
              title: "Generative AI Assistance",
              description:
                "lugath AI helps you tailor your content to align with your desired specifications.",
            },
            {
              wrapper: "div3",
              title: "Integrated Workflow",
              description:
                "Simplify the localization workflow by creating, sharing, and inviting contributions for your content.",
            },
            {
              wrapper: "div4",
              title: "Ease-of-use",
              description:
                "Don't know how to use a CAT tool? Lugath CAT is designed for everyone.",
            },
            {
              wrapper: "div5",
              title: "Automated LQA",
              description: "Get the quality scores in seconds with AI LQA.",
            },
            {
              wrapper: "div6",
              title: "Collaborative",
              description:
                "Allowing Several Translators to Work Together in Real-Time.",
            },
            {
              wrapper: "div7",
              title: "Online",
              description:
                "Accessible from anywhere, providing convenience and flexibility for users.",
            },
            {
              wrapper: "div8",
              title: "Shared Assets",
              description:
                "Use your shared translation memories and glossaries with ease.",
              /*image: {
                source: "Assets/Images/feature-3.png",
                width: 1576,
                height: 712,
              },*/
            },
          ],
          cta: [
            {
              label: "Try all features for 14 days",
              action: "register",
            },
          ],
        },
      },
      {
        moduleName: "SupportedFormats",
        data: {
          title: "Upload your <b>file</b> and start <b>working</b>",
          description: "",
          supportedFormatList: [
            {
              categoryName: "software",
              categoryLabel: "Software Development",
              items: [".xml", ".json", ".md"],
            },
            {
              categoryName: "office-documents",
              categoryLabel: "Office Documents",
              items: [".docx", ".doc", ".xlsx", ".xls", ".pptx", ".pdf"],
            },
            {
              categoryName: "scanned-files",
              categoryLabel: "Scanned Files",
              items: [".pdf", ".jpeg", ".png", ".jpg"],
            },
            {
              categoryName: "sub-titles",
              categoryLabel: "Subtitles",
              items: [".srt", ".vtt"],
            },
            {
              categoryName: "desktop-publishing",
              categoryLabel: "Desktop Publishing",
              items: [".idml", ".xml", ".json"],
            },
          ],
        },
      },
      {
        moduleName: "Testimonials",
        data: {
          testimonials: [
            {
              id: 0,
              author: "Maria Claudia Lo Porto",
              title: "Freelance Translator",
              brand: "TRANSLATOR",
              rating: 4,
              image: "Assets/Images/Testimonials/image.png",
              testimonial:
                " Lugath's online CAT is definitely easy to use and quite helpful, I am using it regularly.",
            },
          ],
        },
      },
      {
        moduleName: "OtherProfiles",
        data: {
          title: "Productive and stress-free translation environment",
          description:
            "New level of translation and localization productivity.",
          cta: [
            {
              label: "Start For Free",
              action: "register",
            },
          ],
          profiles: [
            {
              profile: "developers",
              description:
                "Easily manage software localization in bulk with a single editor.",
            },
            {
              profile: "managers",
              description: "Monitor the localization progress step by step.",
            },
            {
              profile: "translators",
              description:
                "Enjoy the neat user experience, you can use it blindfolded.",
            },
          ],
        },
      },
      { moduleName: "Integrations", data: {} },
    ],
  },
  {
    id: uuidv4(),
    title: "Collaborative  <br/> Translation",
    desc: "Use your cost efficiently with AI-powered Translation Memory!",
    linkTitle: "Read More",
    slug: "collaborative-translation",
    showNumber: true,
    icon: "product-collaboration.svg",
    active: true,
    productName: "Collaborative Translation",
    modules: [
      {
        moduleName: "ProductDetailsHeader",
        data: {
          status: { alias: "live", label: "Live" },
          index: "03",
          productName: "Collaborative Translation",
          header: "AI <br/> Translation",
          alias: "Task Management",
          icon: "product-7.svg",
          shortText:
            "Collaborate on translations, all within a single platform",
          description:
            "Get a contribution from your entire team for continuous localization of your digital content.",
          cta: [
            {
              label: "Free Trial",
              action: "register",
            },
            {
              label: "Get a Demo",
              action: "book-a-demo",
            },
          ],
        },
      },
      {
        moduleName: "ProductDetailsDemo",
        data: {
          title:
            "See How <b>Easy</b> To Get A <strong>Contribution</strong> From Experts",
          description:
            "Simplify project translation and task management in single platform. Ease developers' workload. Equip translators with online CAT features.",
          videoLink: "https://www.youtube.com/watch?v=JVIit3yVd2c",
          applicationPath: "/project/view",
          image: {
            source: "Assets/Images/Editor/InitialScreen.png",
            width: 3600,
            height: 2400,
          },
        },
      },
      {
        moduleName: "ProductFeatureList",
        data: {
          title:
            "Getting <b>contributions</b> from experts has never been easier",
          featureList: [
            {
              wrapper: "div2",
              title: "Get a Contribution",
              description:
                "Share the content with anyone to get the fastest contribution.",
              /*image: {
                source: "Assets/Images/feature-1.png",
                width: 1080,
                height: 804,
              },*/
            },
            {
              wrapper: "div3",
              title: "Approve or Reject",
              description:
                "Review the contribution, approve it, or send it back to the translator.",
              /*image: {
                source: "Assets/Images/feature-2.png",
                width: 1124,
                height: 448,
              },*/
            },
            {
              wrapper: "div4",
              title: "Be Notified",
              description: "Be notified of each step of translation.",
            },
            {
              wrapper: "div5",
              title: "Handle the Terminology",
              description:
                "Be sure to use the correct terminology after post-editing.",
            },
            {
              wrapper: "div6",
              title: "Make a Comment",
              description: "Share your thoughts about the translation.",
            },
            {
              wrapper: "div7",
              title: "Check the Attachments",
              description:
                "Add attachments to share more context with translators.",
            },
            {
              wrapper: "div8",
              title: "Preview the Source",
              description:
                "Preview the source media, which is crucial to fine-tune translation.",
              /*image: {
                source: "Assets/Images/feature-3.png",
                width: 1576,
                height: 712,
              },*/
            },
          ],
          cta: [
            {
              label: "Try all features for 14 days",
              action: "register",
            },
          ],
        },
      },
      {
        moduleName: "Testimonials",
        data: {
          testimonials: [
            {
              id: 0,
              author: "Neslihan Kalafatoglu",
              title: "Senior B2B Marketing Specialist",
              brand: "VESTEL",
              rating: 5,
              image: "Assets/Images/Testimonials/image.png",
              testimonial:
                "With the facilitation of communication between the marketing team and the software development team, the localization workflow has been further accelerated.",
            },
          ],
        },
      },
      {
        moduleName: "OtherProfiles",
        data: {
          title: "Collaboration is the key to success",
          description:
            "New level of translation and localization productivity.",
          cta: [
            {
              label: "Start For Free",
              action: "register",
            },
          ],
          profiles: [
            {
              profile: "developers",
              description:
                "Share software localization bundles easily with translators.",
            },
            {
              profile: "enterprise",
              description:
                "Get a fast contribution from your colleagues who know the language.",
            },
            {
              profile: "translators",
              description:
                "Enjoy the neat user experience, you can use it blindfolded.",
            },
          ],
        },
      },
      { moduleName: "Integrations", data: {} },
    ],
  },
  {
    id: uuidv4(),
    title: "Intelligent <br/> MT Hub",
    desc: "Streamline translation excellence with our platform: one contract, multiple machine translation providers,delivering the finest, context-specific translations, and an ever-ready AI that adapts to your needs.",
    linkTitle: "Read More",
    slug: "translation-service-hub",
    showNumber: true,
    icon: "product-service-hub.svg",
    active: true,
    productName: "Intelligent MT Hub",
    modules: [
      {
        moduleName: "ProductDetailsHeader",
        data: {
          status: { alias: "live", label: "Live" },
          index: "03",
          productName: "Intelligent MT Hub",
          header: "AI <br/> Translation",
          alias: "Intelligent MT Hub",
          icon: "product-8.svg",
          shortText: "Translate your content with the best fit MT Engine",
          description:
            "Optimize your content translation with the most suitable MT Engine. Lugath's intelligent MT Hub recommends the ideal engine based on the content's language and domain.",
          cta: [
            {
              label: "Free Trial",
              action: "register",
            },
            {
              label: "Get a Demo",
              action: "book-a-demo",
            },
          ],
        },
      },
      {
        moduleName: "ProductDetailsDemo",
        data: {
          title:
            "See How Easy To <b>Manage Localization</b> With <strong>Intelligent MT Hub</strong>",
          description:
            "Unlock lightning-fast global growth with Lugath MT Hub. Experience on-brand, pinpoint-accurate, and context-rich translations at your fingertips with just one effortless click.",
          videoLink: "https://www.youtube.com/watch?v=aP2RDuOgu90",
          applicationPath: "/translate",
          image: {
            source: "Assets/Images/Suggestions/Suggestions.png",
            width: 3600,
            height: 2400,
          },
        },
      },
      {
        moduleName: "ProductFeatureList",
        data: {
          title: "All-in-One <b>Machine Translation Hub</b>",
          featureList: [
            {
              wrapper: "div2",
              title: "Multiple MT Access",
              description:
                "Connect to all MT Engines through a single integration.",
            },
            {
              wrapper: "div3",
              title: "Domain Benchmarks",
              description:
                "Simply choose your content's domain, and lugath AI will recommend the most suitable MT solution for you.",
            },
            {
              wrapper: "div4",
              title: "Language Benchmarks",
              description:
                "Use the appropriate MT for your content's language pairs, as lugath AI conducts regular translation quality assessments of integrated MT Engines.",
            },
            {
              wrapper: "div5",
              title: "Maximized Performance",
              description:
                "Get ready for lightning-fast translations as we push the MT limits to the max.",
            },
            {
              wrapper: "div6",
              title: "Aligned Capabilities",
              description:
                "Access unique features not available in some MT engines.",
            },
            {
              wrapper: "div7",
              title: "Aligned Technical Capacities",
              description:
                "Translate your text and documents without being constrained by the limitations of MT Engines.",
            },
            {
              wrapper: "div8",
              title: "Sync or Async",
              description:
                "Translate in real-time or wait to finish the bulk jobs.",
              /*image: {
                source: "Assets/Images/feature-3.png",
                width: 1576,
                height: 712,
              },*/
            },
          ],
          cta: [
            {
              label: "Try all features for 14 days",
              action: "register",
            },
          ],
        },
      },
      {
        moduleName: "Testimonials",
        data: {
          testimonials: [
            {
              id: 0,
              author: "Berk Cakmak",
              title: "E-Commerce Manager",
              brand: "ETSTUR",
              rating: 5,
              image: "Assets/Images/Testimonials/image.png",
              testimonial:
                "Lugath's Intelligent MT Hub provides a single integration point for accessing all systems. Plus, it helps identify which system performs best for specific languages and industries.",
            },
          ],
        },
      },
      {
        moduleName: "OtherProfiles",
        data: {
          title: "Push the limits in machine translation",
          description: "Discover the full potential of MT Engines.",
          cta: [
            {
              label: "Start For Free",
              action: "register",
            },
          ],
          profiles: [
            {
              profile: "developers",
              description: "Access to all MT Engines from single integration.",
            },
            {
              profile: "customer-service",
              description: "Get a real-time translation for customer support.",
            },
            {
              profile: "translators",
              description:
                "Choose the right MT Engine for your content translation.",
            },
          ],
        },
      },
      {
        moduleName: "ProductFAQs",
        data: {
          title: "Questions",
          questions: [
            {
              id: 1,
              question: "What is Intelligent MT Hub?",
              answer:
                "Lugath integrates with multiple machine translation engines and generative AI services, all accessible via a single integration. A significant distinction is that, <b>lugath AI</b> intelligently selects the best system for your content's language and domain.",
            },
            {
              id: 2,
              question:
                "How do you evaluate the translation quality of each MT engines?",
              answer:
                "We utilize human-translated sentences across various languages and domains to help <b>lugath AI</b> analyze the similarity between machine translation results and human-translated sentences. For additional information, check our <a href='/reports'>MT Assessment Reports</a>.",
            },
            {
              id: 3,
              question:
                "What are the benefits of Intelligent MT Hub for translation?",
              answer:
                "The translation quality of all machine translation engines can fluctuate over time. To ensure consistently high-quality machine translation results, rely on Lugath's intelligent MT Hub.",
            },
          ],
        },
      },
      { moduleName: "Integrations", data: {} },
    ],
  },
  {
    id: uuidv4(),
    title: "Document <br/> Translation",
    desc: "Use your cost efficiently with AI-powered Translation Memory!",
    linkTitle: "Read More",
    slug: "document-translation",
    showNumber: true,
    icon: "product-document-translation.svg",
    active: true,
    productName: "Document Translation",
    modules: [
      {
        moduleName: "ProductDetailsHeader",
        data: {
          status: { alias: "live", label: "Live" },
          index: "03",
          productName: "Document Translation",
          header: "Document Translation",
          alias: "Preserve Layout",
          icon: "product-10.svg",
          shortText:
            "Enjoy the format-friendly translation with document level translation",
          description:
            "Submit your documents and utilize the most suitable machine translation engine while preserving the original document design and file format in seconds.",
          cta: [
            {
              label: "Free Trial",
              action: "register",
            },
            {
              label: "Get a Demo",
              action: "book-a-demo",
            },
          ],
        },
      },
      {
        moduleName: "ProductDetailsDemo",
        data: {
          title:
            "See How Easy To <b>Translate Documents</b> With <strong>Intelligent MT Hub</strong>",
          description:
            "Unlock lightning-fast global growth with Lugath MT Hub. Experience on-brand, pinpoint-accurate, and context-rich translations at your fingertips with just one effortless click.",
          videoLink: "https://www.youtube.com/watch?v=aP2RDuOgu90",
          applicationPath: "/translate",
          image: {
            source: "Assets/Images/Suggestions/Suggestions.png",
            width: 3600,
            height: 2400,
          },
        },
      },
      {
        moduleName: "SupportedFormats",
        data: {
          title: "Translate your <b>documents</b> to multiple languages.",
          description: "",
          supportedFormatList: [
            {
              categoryName: "software",
              categoryLabel: "Software Development",
              items: [".xml", ".json", ".md"],
            },
            {
              categoryName: "office-documents",
              categoryLabel: "Office Documents",
              items: [".docx", ".doc", ".xlsx", ".xls", ".pptx", ".pdf"],
            },
            {
              categoryName: "scanned-files",
              categoryLabel: "Scanned Files",
              items: [".pdf", ".jpeg", ".png", ".jpg"],
            },
            {
              categoryName: "sub-titles",
              categoryLabel: "Subtitles",
              items: [".srt", ".vtt"],
            },
            {
              categoryName: "desktop-publishing",
              categoryLabel: "Desktop Publishing",
              items: [".idml", ".xml", ".json"],
            },
          ],
        },
      },
      {
        moduleName: "ProductFeatureList",
        data: {
          title:
            "Instantly <b>translate documents</b> using <strong>AI-powered</strong> translation services",
          featureList: [
            {
              wrapper: "div2",
              title: "Format Friendly",
              description:
                "Keep the original file format even after translation.",
            },
            {
              wrapper: "div3",
              title: "Preserve Design",
              description: "Preserve design and layout of the document.",
            },
            {
              wrapper: "div4",
              title: "Office Documents",
              description:
                "Support office file formats like Word, Excel, or PowerPoint.",
            },
            {
              wrapper: "div5",
              title: "Subtitles",
              description: "Translate video subtitle files like .srt and .vtt",
            },
            {
              wrapper: "div6",
              title: "Scanned Files",
              description:
                "Translate scanned files in .jpg, .jpeg or .png format with OCR technology.",
            },
            {
              wrapper: "div7",
              title: "Desktop Publishing Tools",
              description:
                "Localization starts with design. Translate Adobe InDesign, Sketch, or Figma files.",
            },
            {
              wrapper: "div8",
              title: "Software Documents",
              description:
                "Automatically translate web, mobile, or game localization files.",
            },
          ],
          cta: [
            {
              label: "Try all features for 14 days",
              action: "register",
            },
          ],
        },
      },
      {
        moduleName: "Testimonials",
        data: {
          testimonials: [
            {
              id: 0,
              author: "Aybars Yagiz",
              title:
                "Assistant Managing Director – Legal and Corporate Communications",
              brand: "GAMA ENERGY",
              rating: 4,
              image: "Assets/Images/Testimonials/image.png",
              testimonial:
                "When we use Lugath to translate our agreements beforehand, it makes translation easier, and we can focus mainly on proofreading these documents.",
            },
          ],
        },
      },
      {
        moduleName: "OtherProfiles",
        data: {
          title:
            "Keep your document's file format throughout the translation process",
          description: "Give a chance to lugath AI and make less paperwork.",
          cta: [
            {
              label: "Start For Free",
              action: "register",
            },
          ],
          profiles: [
            {
              profile: "managers",
              description:
                "Once you upload the file, it will be ready for your translators to get to work.",
            },
            {
              profile: "enterprise",
              description:
                "Translate legal agreements, HR documents, or marketing materials within seconds.",
            },
            {
              profile: "translators",
              description:
                "Uploading the file will make it ready to start working.",
            },
          ],
        },
      },
      { moduleName: "Integrations", data: {} },
    ],
  },
  {
    id: uuidv4(),
    title: "Real-time <br/> Translation",
    desc: "Use your cost efficiently with AI-powered Translation Memory!",
    linkTitle: "Read More",
    slug: "realtime-translation",
    showNumber: true,
    icon: "product-real-time.svg",
    active: true,
    productName: "Real-time Translation",
    modules: [
      {
        moduleName: "ProductDetailsHeader",
        data: {
          status: { alias: "live", label: "Live" },
          index: "03",
          productName: "Real-time Translation",
          header: "Real-time Translation",
          alias: "Instant Translation",
          icon: "product-13.svg",
          shortText:
            "Engage with your customers instantly in their native language.",
          description:
            "Engage your customers effectively. Employ real-time translation to instantly convert live chat and support tickets.",
          cta: [
            {
              label: "Free Trial",
              action: "register",
            },
            {
              label: "Get a Demo",
              action: "book-a-demo",
            },
          ],
        },
      },
      {
        moduleName: "ProductFeatureList",
        data: {
          title:
            "Speak <b>same language</b> with your customers in <b>real time applications</b>",
          featureList: [
            {
              wrapper: "div2",
              title: "Real Time Web Chat",
              description:
                "Speak the same language with your customers for excellent support experience.",
            },
            {
              wrapper: "div3",
              title: "Global Customer Support",
              description:
                "Maintain scalability in your operations and recruit based on expertise. Utilize a machine translation plug-in to assist your global customers, irrespective of agent availability.",
            },
            {
              wrapper: "div4",
              title: "Align with SLAs",
              description:
                "Responding on time makes you aligned with customer SLAs and increases your customer satisfaction (CSAT).",
            },
            {
              wrapper: "div5",
              title: "Glossary",
              description:
                "Precise translation of industry-specific and company-specific terminology through the use of tailored glossary terms.",
            },
            {
              wrapper: "div6",
              title: "Translation Memory",
              description:
                "Save on costs up to 75% by having access to previously translated content, eliminating the need for overusing resources for repeated services.",
            },
            {
              wrapper: "div7",
              title: "Minimize Agent Disruption",
              description:
                "Keep your current processes and tools intact. Lugath directly integrates with your agents' preferred tools.",
            },
            {
              wrapper: "div8",
              title: "High-Speed Machine Translation",
              description:
                "Feel the power of intelligent MT Hub. Get the fastest translation from world-class MT Engines.",
            },
          ],
          cta: [
            {
              label: "Try all features for 14 days",
              action: "register",
            },
          ],
        },
      },
      {
        moduleName: "OtherProfiles",
        data: {
          title: "Boost engagement in your support channels",
          description: "Discover the full potential of MT Engines.",
          cta: [
            {
              label: "Start For Free",
              action: "register",
            },
          ],
          profiles: [
            {
              profile: "developers",
              description:
                "Push the translation limits on real-time applications.",
            },
            {
              profile: "enterprise",
              description:
                "Global growth comes from a support team that speaks every language.",
            },
            {
              profile: "customer-service",
              description: "Get back to your customers at the fastest time.",
            },
          ],
        },
      },
      { moduleName: "Integrations", data: {} },
    ],
  },
  {
    id: uuidv4(),
    title: "App <br/> Marketplace",
    desc: "Use your cost efficiently with AI-powered Translation Memory!",
    linkTitle: "Read More",
    slug: "app-marketplace",
    showNumber: true,
    icon: "product-marketplace.svg",
    productName: "App Marketplace",
    active: true,
    modules: [],
  },
  {
    id: uuidv4(),
    title: "Security",
    desc: "Use your cost efficiently with AI-powered Translation Memory!",
    linkTitle: "Read More",
    slug: "security",
    showNumber: true,
    icon: "product-security.svg",
    productName: "Security",
    active: true,
    modules: [],
  },
];
