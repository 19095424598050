import { useEffect, useState } from "react";

const useImage = (
  fileName = "Assets/Images/Marketplace/dropbox.svg",
  inView = false
) => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [image, setImage] = useState(null);

  useEffect(() => {
    const fetchImage = async () => {
      try {
        const response = await import(`../../${fileName}`);
        setImage(response.default);
      } catch (err) {
        setError(err);
      } finally {
        setTimeout(() => {
          setLoading(false);
        }, 100);
      }
    };

    inView && fetchImage();
  }, [fileName, inView]);

  return {
    loading,
    error,
    image,
  };
};

export default useImage;
