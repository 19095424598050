import slugify from "slugify";
export const items = [
  {
    id: "637b33db014dcd534a0db0a6",
    createDate: 1669018587000,
    updateDate: 0,
    createdBy: "",
    updatedBy: "",
    title: "New Languages",
    type: "NEW",
    body: "<p>New languages were added to the language pairs in the system by scoring them on the basis of language and sector.</p><p>The added languages are as follows:</p><ul><li>Swedish</li><li>Hungarian</li><li>Ukranian</li><li>Norwegian</li></ul> <p>With adding these languages, Lugath supports <strong>552</strong> language pairs.",
    releaseVersion: "v0.0.2",
    releaseDate: 1669015538000,
    published: true,
  },
  {
    id: "637b33db014dcd534a0db0a7",
    createDate: 1669018587000,
    updateDate: 0,
    createdBy: "",
    updatedBy: "",
    title: "Support Ticket Feature",
    type: "NEW",
    body: "<p>Users could not able to contact immediately when they faced any issue on our application. </p><p> We developed a basic contact module to get user feedbacks, improvement or new feature requests or bug reports, etc.</p>",
    releaseVersion: "v0.0.2",
    releaseDate: 1669015538000,
    published: true,
  },
  {
    id: "637b33db014dcd534a0db0a8",
    createDate: 1669018587000,
    updateDate: 0,
    createdBy: "",
    updatedBy: "",
    title: "Change Logs News",
    type: "NEW",
    body: "<p>Users could not able to be awared whats new in lugath and our team develop a new module to notify users according developments in product as related releases in product lifecycle.</p>",
    releaseVersion: "v0.0.2",
    releaseDate: 1669015538000,
    published: true,
  },
  {
    id: "637b33db014dcd534a0db0a9",
    createDate: 1669018587000,
    updateDate: 0,
    createdBy: "",
    updatedBy: "",
    title: "Editor Performance",
    type: "FIX",
    body: "<p>When users want to post-edit, errors in the editor that cause performance problems have been fixed.</p>",
    releaseVersion: "v0.0.2",
    releaseDate: 1669015538000,
    published: true,
  },
  {
    id: "637b33db014dcd534a0db0aa",
    createDate: 1669018587000,
    updateDate: 0,
    createdBy: "",
    updatedBy: "",
    title: "Reset Segment Feature",
    type: "IMPROVEMENT",
    body: "<p>When users want to undo after post-editing, they can get back to the RAW MT result by clicking the reset segment button in the editor.</p><p>In the upcoming version, they will be able to return to any state of the segment in history.</p>",
    releaseVersion: "v0.0.2",
    releaseDate: 1669015538000,
    published: true,
  },
  {
    id: "637b33db014dcd534a0db0ab",
    createDate: 1669018587000,
    updateDate: 0,
    createdBy: "",
    updatedBy: "",
    title: "Glossary Owner",
    type: "IMPROVEMENT",
    body: "<p>Now users able to see who created glossary and who added the related terms in exist screens.</p>",
    releaseVersion: "v0.0.2",
    releaseDate: 1669015538000,
    published: true,
  },
  {
    id: "637b33db014dcd534a0db0ac",
    createDate: 1669018587000,
    updateDate: 0,
    createdBy: "",
    updatedBy: "",
    title: "Included Terms",
    type: "IMPROVEMENT",
    body: "<p>Users can now see terms in a relevant segment on their editor screen.</p><p>In this way, they have the opportunity to make healthier post-editing as they can see which terms are used in the glossary and which sentences are used during post-editing.</p>",
    releaseVersion: "v0.0.2",
    releaseDate: 1669015538000,
    published: true,
  },
  {
    id: "637f349c961eeb31a98a5229",
    createDate: 1669280924000,
    updateDate: 0,
    createdBy: "",
    updatedBy: "",
    title: "JSON Export File names",
    type: "FIX",
    body: "<p>The problem of exporting the translated JSON extension file types with the relevant language prefix as filename has been resolved.</p>",
    releaseVersion: "v0.0.2",
    releaseDate: 1669015538000,
    published: true,
  },
  {
    id: "63871ce2961eeb31a98a670b",
    createDate: 1669799138000,
    updateDate: 0,
    createdBy: "",
    updatedBy: "",
    title: "New Languages",
    type: "NEW",
    body: "<p>New languages were added to the language pairs in the system without score.</p><p>The added languages are as follows:</p><ul><li>Bulgarian</li><li>Hebrew</li></ul> <p>With adding these languages, Lugath supports <strong>702</strong> language pairs.",
    releaseVersion: "v0.0.2.1",
    releaseDate: 1669015538000,
    published: true,
  },
  {
    id: "638e148005b026378557890a",
    createDate: 1670255744000,
    updateDate: 0,
    createdBy: "",
    updatedBy: "",
    title: "Support New Formats",
    type: "NEW",
    body: "<p>New file formats were added to the app.</p><p>The added formats are as follows:</p><ul><li>XLSX</li><li>XLS</li><li>XML</li></ul> <p>With adding these formats.",
    releaseVersion: "v0.0.2.2",
    releaseDate: 1669015538000,
    published: true,
  },
  {
    id: "638e14c805b026378557890b",
    createDate: 1670255816000,
    updateDate: 0,
    createdBy: "",
    updatedBy: "",
    title: "New Engines",
    type: "NEW",
    body: "<p>IBM Watson is added to supported engines.",
    releaseVersion: "v0.0.2.2",
    releaseDate: 1669015538000,
    published: true,
  },
  {
    id: "638e152f05b026378557890c",
    createDate: 1670255919000,
    updateDate: 0,
    createdBy: "",
    updatedBy: "",
    title: "Document Parser Empty String Issue",
    type: "FIX",
    body: "<p>In some cases, there was empty string when translation engines failed during the translation operation, this issue has been fixed and covered with backup flow.",
    releaseVersion: "v0.0.2.2",
    releaseDate: 1669015538000,
    published: true,
  },
  {
    id: "63c57fa1af43f641c70775d4",
    createDate: 1673887649000,
    updateDate: 0,
    createdBy: "",
    updatedBy: "",
    title: "Translate v2",
    type: "IMPROVEMENT",
    body: "<p>We're excited to announce the launch of Translate v2 - the latest version of our module! With a host of new features and performance improvements, Translate v2 is faster, more reliable, and more intuitive than ever before. It includes powerful translation technology to quickly and accurately translate between languages, an enhanced user interface for improved usability, and improved performance for faster results. If you're looking for an easy way to communicate with people in different languages, Translate v2 is the perfect solution. </p>",
    releaseVersion: "v0.0.4",
    releaseDate: 1673886964000,
    published: true,
  },
  {
    id: "63c57fa1af43f641c70775d5",
    createDate: 1673887649000,
    updateDate: 0,
    createdBy: "",
    updatedBy: "",
    title: "Performance Improvements",
    type: "IMPROVEMENT",
    body: "<p>We're excited to announce the launch of the latest version of our Lugath application, with improved performance and bug fixes. This update brings even faster results and better overall performance, improving the usability and reliability of the service. With the latest improvements, Lugath users can enjoy a faster, more intuitive experience with fewer errors.</p>",
    releaseVersion: "v0.0.4",
    releaseDate: 1673886964000,
    published: true,
  },
  {
    id: "63c57fa1af43f641c70775d6",
    createDate: 1673887649000,
    updateDate: 0,
    createdBy: "",
    updatedBy: "",
    title: "Approval Workflow",
    type: "NEW",
    body: "<p>We're thrilled to announce the release of our new approval workflow for content owners and 3rd party contributors! This workflow allows content owners to quickly and easily share their content with contributors, and then easily approve changes before they are applied. With this new workflow, content owners have more control over their content while providing contributors with the opportunity to make revisions quickly and accurately. If you're looking for an efficient and reliable way to manage content approval, our approval workflow is the perfect solution.</p>",
    releaseVersion: "v0.0.4",
    releaseDate: 1673886964000,
    published: true,
  },
  {
    id: "63ca4a22cc0ff94a411ad4fa",
    createDate: 1674201634000,
    updateDate: 0,
    createdBy: "",
    updatedBy: "",
    title: "New Engines",
    type: "NEW",
    body: "<p>We are excited to announce that our application has been enhanced with the integration of multiple state-of-the-art translation engines <strong>- DeepL, Systran, Lingvanex, and RWS.</strong></p><p> This integration makes our application more powerful than ever before, providing users with access to a wider range of languages and more accurate translations. Whether you're looking to communicate with international colleagues or expand your business to new markets, our application has you covered. </p><p>Try it out today and experience the difference!</p>",
    releaseVersion: "v0.0.4",
    releaseDate: 1673886964000,
    published: true,
  },
  {
    id: "642edcef0b52cb2f55c1d32f",
    createDate: 1680792815870,
    updateDate: 1680792815870,
    createdBy: "",
    updatedBy: "",
    title: "New Supported File Formats",
    type: "NEW",
    body: "<div><p>We're excited to announce that the latest update includes support for several new file types, including:<br/><ul><li> PDF </li> <li> MD </li> <li> IDML </li> </ul>This means that users can now <b>upload and edit these file types directly in our platform, without the need for any external tools or converters.</b><br/> <br/> This update will make it easier for users to collaborate and work with a wider range of file types, and help streamline workflows.</p></div>",
    releaseVersion: "v1.0.0",
    releaseDate: 0,
    published: true,
  },
  {
    id: "642edcef0b52cb2f55c1d330",
    createDate: 1680792815871,
    updateDate: 1680792815871,
    createdBy: "",
    updatedBy: "",
    title: "API Capabilities Improvements",
    type: "IMPROVEMENT",
    body: "<div> <p> We are excited to announce the latest update to our API, which includes several improvements to enhance its usefulness. <br/><br/>One of the <b> major changes </b> is the implementation of a<b> flexible query system </b> that provides <b>more control</b> to users, enabling them to access the data they need more <b> easily </b>. <br/><br/>Additionally, several <b> performance issues have been fixed </b>, resulting in a <b> faster </b> and <b>more reliable</b> API experience. With these updates, our API is now even more powerful and <b> user-friendly </b>, providing users with the tools they need to streamline their workflows and achieve their goals. <br/> <br/><br/>For more details, you can visit the documentation:<br/> <br/> <a style='color:#477cdf; text-decoration:underline' href='https://docs.lugath.com'> API Documentation </a></p> </div>",
    releaseVersion: "v1.0.0",
    releaseDate: 0,
    published: true,
  },
  {
    id: "642edcef0b52cb2f55c1d331",
    createDate: 1680792815872,
    updateDate: 1680792815872,
    createdBy: "",
    updatedBy: "",
    title: "Projects UI Enhancements",
    type: "NEW",
    body: "<div> <p> We are pleased to announce that the latest update includes several improvements to our translation projects management capabilities. <br/> <br/>With the addition of a <b>folder structure and management system</b> , users can now <b>easily organize</b> and manage their translation projects, making it <b>faster</b> and <b>more efficient</b> to access and work on their content. <br/> <br/>The new folder structure allows for better organization and <b>easier navigation</b>, which <b>saves users valuable time and improves productivity</b>. <br/> <br/>With these updates, our translation projects management capabilities are now even more <b>robust</b> and <b>user-friendly</b>, making it easier to manage translation projects and collaborate with team members. </p> </div>",
    releaseVersion: "v1.0.0",
    releaseDate: 0,
    published: true,
  },
  {
    id: "642edcef0b52cb2f55c1d332",
    createDate: 1680792815873,
    updateDate: 1680792815873,
    createdBy: "",
    updatedBy: "",
    title: "Multiple Automated Translation Memory",
    type: "NEW",
    body: "<div> <p> We 're excited to announce the addition of multiple automated translation memory <b> (TM) </b> services to our platform. <br/> <br/>With this update, users can now take advantage of these services to <b> save costs </b> and <b>improve the accuracy</b> of their translations. <br/> <br/>Automated translation memory technology allows for the <b> efficient storage and retrieval of previously translated content </b>, which can be<b>re-used in future translations</b>, <b>reducing the time and effort </b> required to translate similar content without human touches. <br/> <br/>With the addition of these services, our platform is now even more powerful and flexible, making it easier for users to achieve their translation goals.</p> </div>",
    releaseVersion: "v1.0.0",
    releaseDate: 0,
    published: true,
  },
  {
    id: "642edcef0b52cb2f55c1d333",
    createDate: 1680792815874,
    updateDate: 1680792815874,
    createdBy: "",
    updatedBy: "",
    title: "New Editor UI Enhancements",
    type: "NEW",
    body: "<p>The editor has received a <b> major </b> update, with a new and intuitive user interface that promises to make the writing experience more streamlined and efficient. <br/> <br/>Users can expect an improved editing experience with a host of <b> new features and a clean, modern look </b>. <br/> <br/>The updated editor also includes<b> bug fixes and stability improvements </b> for a smoother workflow.</p>",
    releaseVersion: "v1.0.0",
    releaseDate: 0,
    published: true,
  },
  {
    id: "642edcef0b52cb2f55c1d111",
    createDate: 1697040094000,
    updateDate: 1697040094000,
    createdBy: "",
    updatedBy: "",
    title: "Listed on Nimdzi Language Technology Atlas",
    type: "NEW",
    body: "<p>🌟 Big News! 🌟 <br/> <br/> Lugath is thrilled to be recognized by NimdziInsights, a leader in translation and localization research. We're making waves on the Nimdzi Language Technology Atlas, earning a spot in not one, but THREE categories! 🚀🏆 <br/> <br/>Kudos to our dedicated team and heartfelt thanks to our clients and partners for their trust and support. We couldn't have done it without you! <br/> <br/>Stay tuned as we continue to innovate and deliver top-notch language technology solutions. 🌐 <br/><br/> <a target='_blank' href='https://www.nimdzi.com/language-technology-atlas/'><b>Nimdzi Technology Language Atlas</b></a></p>",
    releaseVersion: "v1.0.0",
    releaseDate: 1697040094000,
    published: true,
  },
];

export const updates = items.map((i) => {
  return { ...i, ...{ slug: slugify(i.title.toLowerCase()) } };
});
