import { products } from "./Data/products";
import { commons } from "./Data/commons";
import { solutions } from "./Data/solutions";
import { news } from "./Data/news";
import { updates } from "./Data/updates";
import { menu } from "./Data/menu";
import { questions } from "./Data/questions";
import { integrations } from "./Data/integrations";
import { apps } from "./Data/apps";
import { glossary } from "./Data/glossary";
import { videos } from "./Data/videos";
import { profiles } from "./Data/profiles";

export const menuData = menu;
export const commonsData = commons;
export const newsData = news;
export const updatesData = updates;
export const productsData = products;
export const solutionsData = solutions;
export const videosData = videos;
export const profilesData = profiles;
export const questionsData = questions;
export const integrationsData = integrations;
export const appsData = apps;
export const glossaryData = glossary;
