import React from "react";
import { Link } from "react-router-dom";
import "./SolutionCardNew.scss";
import intelligentWorkflowIcon from "../../Assets/Images/Solutions/intelligent-workflow.svg";
import assetManagementIcon from "../../Assets/Images/Solutions/asset-management.svg";
import budgetManagementIcon from "../../Assets/Images/Solutions/budget-management.svg";
import collaborativeEditorsIcon from "../../Assets/Images/Solutions/collaborative-editors.svg";
import contentLabsIcon from "../../Assets/Images/Solutions/content-labs.svg";
import customizationWorkflowIcon from "../../Assets/Images/Solutions/customisation-workflow.svg";
import dataSecurityIcon from "../../Assets/Images/Solutions/data-security.svg";
import supportIcon from "../../Assets/Images/Solutions/support.svg";
import translationLoadBalancerIcon from "../../Assets/Images/Solutions/translation-load-balancer.svg";
import mtEngineAnalyticsIcon from "../../Assets/Images/Solutions/mt-engine-analytics.svg";
import lspAnalyticsIcon from "../../Assets/Images/Solutions/lsp-analytics.svg";
import timeSheetIcon from "../../Assets/Images/Solutions/time-sheet-management.svg";
import connectedHub from "../../Assets/Icons/connected-hub.svg";
import smartRouter from "../../Assets/Icons/smart-router.svg";
import dynamicMemory from "../../Assets/Icons/dynamic-memory.svg";
import { ReactComponent as ChevronRight } from "../../Assets/Icons/chevron-right.svg";
import { ReactComponent as AiTranslation } from "../../Assets/Icons/product-ai-translation-2.svg";
import { ReactComponent as QualityAssurance } from "../../Assets/Icons/product-quality-assurance.svg";
import { ReactComponent as TranslationMemory } from "../../Assets/Icons/product-translation-memory.svg";
import { ReactComponent as WorkflowManagement } from "../../Assets/Icons/product-workflow-management.svg";
import { ReactComponent as Glossary } from "../../Assets/Icons/product-glossary.svg";
import { ReactComponent as CatTool } from "../../Assets/Icons/product-cat-tool.svg";
import { ReactComponent as Collaboration } from "../../Assets/Icons/product-collaboration.svg";
import { ReactComponent as ServiceHub } from "../../Assets/Icons/product-service-hub.svg";
import { ReactComponent as DocumentTranslation } from "../../Assets/Icons/product-document-translation.svg";
import { ReactComponent as RealTime } from "../../Assets/Icons/product-real-time.svg";
import { ReactComponent as MarketPlace } from "../../Assets/Icons/product-marketplace.svg";
import { ReactComponent as Security } from "../../Assets/Icons/product-security.svg";
import { ReactComponent as ApiDocs } from "../../Assets/Icons/resources-api-docs.svg";
import { ReactComponent as Faqs } from "../../Assets/Icons/resources-faq.svg";
import { ReactComponent as Updates } from "../../Assets/Icons/resources-updates.svg";

export default function SolutionCard({ item, index }) {
  const icons = {
    intelligentWorkflow: intelligentWorkflowIcon,
    assetManagement: assetManagementIcon,
    budgetManagement: budgetManagementIcon,
    collaborativeEditors: collaborativeEditorsIcon,
    contentLabs: contentLabsIcon,
    connectedHub: connectedHub,
    customizationWorkflow: customizationWorkflowIcon,
    dataSecurity: dataSecurityIcon,
    support: supportIcon,
    translationLoadBalancer: translationLoadBalancerIcon,
    smartRouter: smartRouter,
    dynamicMemory: dynamicMemory,
    mtEngineAnalytics: mtEngineAnalyticsIcon,
    lspAnalytics: lspAnalyticsIcon,
    timeSheet: timeSheetIcon,
    aiTranslation: (customStyles) => (
      <AiTranslation style={{ ...customStyles }} />
    ),
    qualityAssurance: (customStyles) => (
      <QualityAssurance style={{ ...customStyles }} />
    ),
    translationMemory: (customStyles) => (
      <TranslationMemory style={{ ...customStyles }} />
    ),
    workflowManagement: (customStyles) => (
      <WorkflowManagement style={{ ...customStyles }} />
    ),
    glossary: (customStyles) => <Glossary style={{ ...customStyles }} />,
    catTool: (customStyles) => <CatTool style={{ ...customStyles }} />,
    collaboration: (customStyles) => (
      <Collaboration style={{ ...customStyles }} />
    ),
    serviceHub: (customStyles) => <ServiceHub style={{ ...customStyles }} />,
    documentTranslation: (customStyles) => (
      <DocumentTranslation style={{ ...customStyles }} />
    ),
    realTime: (customStyles) => <RealTime style={{ ...customStyles }} />,
    marketPlace: (customStyles) => <MarketPlace style={{ ...customStyles }} />,
    security: (customStyles) => <Security style={{ ...customStyles }} />,
    apiDocs: (customStyles) => <ApiDocs style={{ ...customStyles }} />,
    faqs: (customStyles) => <Faqs style={{ ...customStyles }} />,
    updates: (customStyles) => <Updates style={{ ...customStyles }} />,
  };

  return (
    <div
      key={item.index}
      className={`col-span-full lg:col-span-2 grid-rows-2 row-span-2  grid grid-cols-4 information-box ${
        item.disabled ? "disabled" : ""
      }`}
    >
      <div
        className={`${
          index % 2 === 0
            ? "col-span-2 lg:col-span-full row-span-full bg-dot"
            : "col-span-2 lg:col-span-full row-span-full grid col-start-3 bg-dot"
        }`}
      >
        <div
          className="card-square-wrapper"
          data-content-num={index < 10 ? "0" + index : index}
        >
          <div className="card-square">
            <div
              className="card-square-initial-content"
              style={
                {
                  //backgroundColor: `rgba(71,204,223,${(10 - (index + 1)) / 50})`,
                }
              }
            >
              <div className="card-square-initial-content-wrapper">
                <span
                  className="title"
                  dangerouslySetInnerHTML={{ __html: item.title }}
                />
                {item.disabled ? null : (
                  <Link className="readmore" to={"/solutions/" + item.slug}>
                    Discover{" "}
                    <span className="readmore-icon">
                      <ChevronRight />
                    </span>
                  </Link>
                )}
                <div className="icon">
                  {typeof icons?.[item?.icon] === "function"
                    ? icons?.[item?.icon](
                        item?.customStyles ? item?.customStyles : {}
                      )
                    : null}
                </div>
              </div>
            </div>
            <div className="card-square-inner card-square-inner--left-top">
              {" "}
            </div>
            <div className="card-square-inner card-square-inner--left-bottom">
              {" "}
            </div>
            <div className="card-square-inner card-square-inner--right-top">
              {" "}
            </div>
            <div className="card-square-inner card-square-inner--right-bottom">
              {" "}
            </div>
            <div className="card-square-hover-content">
              <Link
                className="solution-cta"
                to={"/solutions/" + item.slug}
              ></Link>
              <div className="card-square-hover-content-wrapper">
                <span className="title">{item.desc}</span>
                <Link className="readmore" to={"/solutions/" + item.slug}>
                  More{" "}
                  <span className="readmore-icon">
                    <ChevronRight />
                  </span>
                </Link>
                <div className="icon">
                  {typeof icons?.[item?.icon] === "function"
                    ? icons?.[item?.icon](
                        item?.customStyles ? item?.customStyles : {}
                      )
                    : null}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
