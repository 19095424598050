import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { ReactComponent as ArrowOutward } from "../../Assets/Icons/arrow_outward.svg";
import "./index.scss";
import { updatesData } from "../../Services/DataService";

export default function News() {
  const navigate = useNavigate();
  const onClickUpdateItem = (item) => {
    navigate(`/news/${item.slug}`);
  };
  return (
    <div className="updates-page updates-page-bg">
      <section className="updates-page-header js-section_nav-dark">
        <div className="container mx-auto">
          <div className="parent">
            <div className="div1">
              <div className="updates-page-header__title">
                Updates by <span className="shine">lugath</span>
              </div>
            </div>
            <div className="div2">
              <div className="updates-page-header__content">
                <div className="updates-page-header__content-desc">
                  Learn what’s new in Lugath and how to get the most out of the
                  software with useful tips and tricks.
                </div>
                {/*<div className="updates-page-header__content-cta">
                  <button>See the Latest</button>
  </div>*/}
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="updates-page-content js-section_nav-dark">
        <div className="updates-page-content__list">
          {updatesData
            .sort((a, b) => (a.createDate < b.createDate ? 1 : -1))
            .map((n) => (
              <div className="container mx-auto">
                <div className="updates-page-content__list-item__wrapper">
                  <div
                    className="updates-page-content__list-item"
                    onClick={() => onClickUpdateItem(n)}
                  >
                    <div className="updates-page-content__list-item__content">
                      <div className="updates-page-content__list-item__content-title">
                        {n.title}
                      </div>

                      <div className="updates-page-content__list-item__content-cta">
                        <Link
                          to={`/news/${n.id}`}
                          className="updates-page-content__list-item__content-cta-link"
                        >
                          <span className="label">Discover More</span>
                          <span className="icon">
                            <ArrowOutward />
                          </span>
                        </Link>
                      </div>
                    </div>
                    <div className="updates-page-content__list-item__date">
                      <div className="updates-page-content__list-item__date-content">
                        {Intl.DateTimeFormat("en-US", {
                          month: "long",
                          day: "numeric",
                          year: "numeric",
                        }).format(new Date(n.createDate))}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
        </div>
      </section>
    </div>
  );
}
