import { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import _ from "lodash";
import Accordion from "../../Components/FaqPageComponents/Accordion";
import { ReactComponent as CheckIcon } from "../../Assets/Icons/check-icon.svg";
import { ReactComponent as CloseIcon } from "../../Assets/Icons/close-icon--alternative.svg";
import { dynamicParse, sendEvent } from "../../Libs/utils";
import "./index.scss";
import SegmentedControl from "../../Components/SegmentedControl";
import MileStone from "../../Components/MileStone";
import CONFIG from "../../Services/ConfigService";
import ReactGA from "react-ga";
const USD = "USD";
const TRY = "TRY";
const MONTHLY = "MONTHLY";
const YEARLY = "YEARLY";
const DEFAULT_PACKAGE = "BUSINESS";
const DEFAULT_PERIOD = MONTHLY;
const DEFAULT_CURRENCY = USD;
const RESTRICTED_PACKAGES = ["FREEMIUM"];
const memberships = [
  {
    id: "6458e13ac4486803ca8d5af0",
    createDate: 1683546426297,
    updateDate: 1683546426297,
    createdBy: "",
    updatedBy: "",
    charLimit: 10000,
    tmCharLimit: 100000,
    userLimit: "Unlimited",
    translationUnitPrice: {
      amount: 0,
      currency: "USD",
    },
    memoryUnitPrice: {
      amount: 0,
      currency: "USD",
    },
    userUnitPrice: {
      amount: 0,
      currency: "USD",
    },
    packagePrice: {
      amount: 0,
      currency: "USD",
    },
    usdTryCurrency: 26.9066,
    packagePriceTRY: {
      amount: 0.0,
      currency: "TRY",
    },
    packagePriceTRYWithTax: {
      amount: 0.0,
      currency: "TRY",
    },
    membershipType: "FREEMIUM",
    membershipPeriod: "MONTHLY",
    paymentRequired: false,
    extendable: false,
    maxCharLimit: 0,
    maxTmCharLimit: 0,
    maxUserLimit: 0,
    order: 0,
    enabled: true,
  },
  {
    id: "645a64fe1409af6890a60dba",
    createDate: 1683645694111,
    updateDate: 1691675712883,
    createdBy: "",
    updatedBy: "",
    charLimit: 100000,
    tmCharLimit: 1000000,
    userLimit: "Unlimited",
    translationUnitPrice: {
      amount: 0.35,
      currency: "USD",
    },
    memoryUnitPrice: {
      amount: 0.005,
      currency: "USD",
    },
    userUnitPrice: {
      amount: 10,
      currency: "USD",
    },
    packagePrice: {
      amount: 35,
      currency: "USD",
    },
    usdTryCurrency: 26.9066,
    packagePriceTRY: {
      amount: 941.74,
      currency: "TRY",
    },
    packagePriceTRYWithTax: {
      amount: 1130.09,
      currency: "TRY",
    },
    membershipType: "BASIC",
    membershipPeriod: "MONTHLY",
    paymentRequired: true,
    extendable: false,
    maxCharLimit: 0,
    maxTmCharLimit: 0,
    maxUserLimit: 0,
    order: 1,
    enabled: true,
  },
  {
    id: "645b2fdacff29444d3247aad",
    createDate: 1683697626985,
    updateDate: 1683697626985,
    createdBy: "",
    updatedBy: "",
    charLimit: 300000,
    tmCharLimit: 3000000,
    userLimit: "Unlimited",
    translationUnitPrice: {
      amount: 0.297,
      currency: "USD",
    },
    memoryUnitPrice: {
      amount: 0.005,
      currency: "USD",
    },
    userUnitPrice: {
      amount: 10,
      currency: "USD",
    },
    packagePrice: {
      amount: 89,
      currency: "USD",
    },
    usdTryCurrency: 26.9066,
    packagePriceTRY: {
      amount: 2394.69,
      currency: "TRY",
    },
    packagePriceTRYWithTax: {
      amount: 2873.63,
      currency: "TRY",
    },
    membershipType: "PREMIUM",
    membershipPeriod: "MONTHLY",
    paymentRequired: true,
    extendable: true,
    maxCharLimit: 1000000,
    maxTmCharLimit: 10000000,
    maxUserLimit: 3,
    order: 2,
    enabled: true,
  },
  {
    id: "645b3000cff29444d3247aae",
    createDate: 1683697664026,
    updateDate: 1683697664026,
    createdBy: "",
    updatedBy: "",
    charLimit: 1000000,
    tmCharLimit: 10000000,
    userLimit: "Unlimited",
    translationUnitPrice: {
      amount: 0.219,
      currency: "USD",
    },
    memoryUnitPrice: {
      amount: 0.005,
      currency: "USD",
    },
    userUnitPrice: {
      amount: 10,
      currency: "USD",
    },
    packagePrice: {
      amount: 219,
      currency: "USD",
    },
    usdTryCurrency: 26.9066,
    packagePriceTRY: {
      amount: 5892.55,
      currency: "TRY",
    },
    packagePriceTRYWithTax: {
      amount: 7071.06,
      currency: "TRY",
    },
    membershipType: "BUSINESS",
    membershipPeriod: "MONTHLY",
    paymentRequired: true,
    extendable: true,
    maxCharLimit: 1500000,
    maxTmCharLimit: 15000000,
    maxUserLimit: 10,
    order: 3,
    enabled: true,
  },
  {
    id: "645b3026cff29444d3247aaf",
    createDate: 1683697702517,
    updateDate: 1683697702517,
    createdBy: "",
    updatedBy: "",
    charLimit: 2500000,
    tmCharLimit: 25000000,
    userLimit: "Unlimited",
    translationUnitPrice: {
      amount: 0.16,
      currency: "USD",
    },
    memoryUnitPrice: {
      amount: 0.005,
      currency: "USD",
    },
    userUnitPrice: {
      amount: 10,
      currency: "USD",
    },
    packagePrice: {
      amount: 399,
      currency: "USD",
    },
    usdTryCurrency: 26.9066,
    packagePriceTRY: {
      amount: 10735.74,
      currency: "TRY",
    },
    packagePriceTRYWithTax: {
      amount: 12882.89,
      currency: "TRY",
    },
    membershipType: "ENTERPRISE",
    membershipPeriod: "MONTHLY",
    paymentRequired: true,
    extendable: true,
    maxCharLimit: 5000000,
    maxTmCharLimit: 50000000,
    maxUserLimit: 20,
    order: 4,
    enabled: true,
  },
  {
    id: "645b30c2cff29444d3247ab0",
    createDate: 1683697858446,
    updateDate: 1683697858446,
    createdBy: "",
    updatedBy: "",
    charLimit: 30000000,
    tmCharLimit: 300000000,
    userLimit: "Unlimited",
    translationUnitPrice: {
      amount: 0.133,
      currency: "USD",
    },
    memoryUnitPrice: {
      amount: 0.005,
      currency: "USD",
    },
    userUnitPrice: {
      amount: 10,
      currency: "USD",
    },
    packagePrice: {
      amount: 3990,
      currency: "USD",
    },
    usdTryCurrency: 26.9066,
    packagePriceTRY: {
      amount: 107357.34,
      currency: "TRY",
    },
    packagePriceTRYWithTax: {
      amount: 128828.81,
      currency: "TRY",
    },
    membershipType: "ENTERPRISE",
    membershipPeriod: "YEARLY",
    paymentRequired: true,
    extendable: true,
    maxCharLimit: 100000000,
    maxTmCharLimit: 1000000000,
    maxUserLimit: 20,
    order: 3,
    enabled: true,
  },
  {
    id: "645b30fccff29444d3247ab1",
    createDate: 1683697916919,
    updateDate: 1683697916919,
    createdBy: "",
    updatedBy: "",
    charLimit: 12000000,
    tmCharLimit: 120000000,
    userLimit: "Unlimited",
    translationUnitPrice: {
      amount: 0.183,
      currency: "USD",
    },
    memoryUnitPrice: {
      amount: 0.005,
      currency: "USD",
    },
    userUnitPrice: {
      amount: 10,
      currency: "USD",
    },
    packagePrice: {
      amount: 2190,
      currency: "USD",
    },
    usdTryCurrency: 26.9066,
    packagePriceTRY: {
      amount: 58925.46,
      currency: "TRY",
    },
    packagePriceTRYWithTax: {
      amount: 70710.56,
      currency: "TRY",
    },
    membershipType: "BUSINESS",
    membershipPeriod: "YEARLY",
    paymentRequired: true,
    extendable: true,
    maxCharLimit: 18000000,
    maxTmCharLimit: 180000000,
    maxUserLimit: 10,
    order: 2,
    enabled: true,
  },
  {
    id: "645b3212cff29444d3247ab2",
    createDate: 1683698194882,
    updateDate: 1683698194882,
    createdBy: "",
    updatedBy: "",
    charLimit: 3600000,
    tmCharLimit: 36000000,
    userLimit: "Unlimited",
    translationUnitPrice: {
      amount: 0.247,
      currency: "USD",
    },
    memoryUnitPrice: {
      amount: 0.005,
      currency: "USD",
    },
    userUnitPrice: {
      amount: 10,
      currency: "USD",
    },
    packagePrice: {
      amount: 890,
      currency: "USD",
    },
    usdTryCurrency: 26.9066,
    packagePriceTRY: {
      amount: 23946.88,
      currency: "TRY",
    },
    packagePriceTRYWithTax: {
      amount: 28736.26,
      currency: "TRY",
    },
    membershipType: "PREMIUM",
    membershipPeriod: "YEARLY",
    paymentRequired: true,
    extendable: true,
    maxCharLimit: 8600000,
    maxTmCharLimit: 86000000,
    maxUserLimit: 3,
    order: 1,
    enabled: true,
  },
  {
    id: "645b3238cff29444d3247ab3",
    createDate: 1683698232394,
    updateDate: 1683698232394,
    createdBy: "",
    updatedBy: "",
    charLimit: 1200000,
    tmCharLimit: 12000000,
    userLimit: "Unlimited",
    translationUnitPrice: {
      amount: 0.292,
      currency: "USD",
    },
    memoryUnitPrice: {
      amount: 0.005,
      currency: "USD",
    },
    userUnitPrice: {
      amount: 10,
      currency: "USD",
    },
    packagePrice: {
      amount: 350,
      currency: "USD",
    },
    usdTryCurrency: 26.9066,
    packagePriceTRY: {
      amount: 9417.31,
      currency: "TRY",
    },
    packagePriceTRYWithTax: {
      amount: 11300.78,
      currency: "TRY",
    },
    membershipType: "BASIC",
    membershipPeriod: "YEARLY",
    paymentRequired: true,
    extendable: false,
    maxCharLimit: 0,
    maxTmCharLimit: 0,
    maxUserLimit: 0,
    order: 0,
    enabled: true,
  },
];
const FreePackage = {
  disabled: true,
  id: "6458e13ac4486803ca8d5af0",
  createDate: 1683546426297,
  updateDate: 1683546426297,
  createdBy: "",
  updatedBy: "",
  charLimit: 10000,
  tmCharLimit: 100000,
  userLimit: "Unlimited",
  translationUnitPrice: {
    amount: 0,
    currency: "USD",
  },
  memoryUnitPrice: {
    amount: 0,
    currency: "USD",
  },
  userUnitPrice: {
    amount: 0,
    currency: "USD",
  },
  packagePrice: {
    amount: 0,
    currency: "USD",
  },
  usdTryCurrency: 26.9066,
  packagePriceTRY: {
    amount: 0.0,
    currency: "TRY",
  },
  packagePriceTRYWithTax: {
    amount: 0.0,
    currency: "TRY",
  },
  membershipType: "FREEMIUM",
  membershipPeriod: "YEARLY",
  paymentRequired: false,
  extendable: false,
  maxCharLimit: 0,
  maxTmCharLimit: 0,
  maxUserLimit: 0,
  order: 0,
  enabled: true,
};
/*
memberships.push(FreePackage);*/

const packageOptions = {
  membership: null,
  membershipDetails: null,
  extendMode: false,
  extendPackage: null,
  invoices: null,
  paymentLocale: "USD",
  packages: {
    displayNames: {
      FREEMIUM: "Free Trial",
      BASIC: "Basic",
      PREMIUM: "Premium",
      BUSINESS: "Business",
      ENTERPRISE: "Enterprise",
    },
    supportTypeNames: {
      FREEMIUM: "Community",
      BASIC: "Community",
      PREMIUM: "Community",
      BUSINESS: "Business",
      ENTERPRISE: "Enterprise",
    },
    selectedPackage: false,
    activePackage: false,
    all: [],
    monthly: [],
    yearly: [],
    period: DEFAULT_PERIOD,
    availablePackages: [],
    features: [
      {
        id: 1,
        title: "MT House",
        status: false,
        highlight: false,
      },
      {
        id: 2,
        title: "Dynamic Routing",
        status: true,
        highlight: false,
      },
      {
        id: 3,
        title: "Private Shared Link",
        status: true,
        highlight: false,
      },
      {
        id: 4,
        title: "Document Translation",
        status: true,
        highlight: false,
      },
      {
        id: 5,
        title: "{{charLimit}} Characters",
        status: true,
        dynamic: true,
        key: "charLimit",
        highlight: true,
      },
      {
        id: 6,
        title: "Unlimited Source Keys",
        status: true,
        highlight: true,
      },
      {
        id: 7,
        title: "Glossary",
        status: true,
        highlight: false,
      },
      {
        id: 8,
        title: "API Integration",
        status: true,
        highlight: false,
      },
      {
        id: 9,
        title: "Automated Translation Memory",
        status: true,
        highlight: false,
      },
      {
        id: 10,
        title: "SDKs",
        status: true,
        highlight: false,
      },
      {
        id: 11,
        title: "{{membershipType}} Support",
        status: true,
        highlight: true,
        isString: true,
        dynamic: true,
        key: "membershipType",
      },
      {
        id: 12,
        title: "{{userLimit}} Seats Included",
        status: true,
        dynamic: true,
        key: "userLimit",
        highlight: true,
      },
    ],
  },
};

export default function Pricing() {
  const [selectedPeriod, setSelectedPeriod] = useState(DEFAULT_PERIOD);
  const [selectedCurrency, setSelectedCurrency] = useState(DEFAULT_CURRENCY);
  const [packageList, setPackageList] = useState([]);
  const [selectedPackage, setSelectedPackage] = useState(
    memberships.find(
      (m) =>
        m.membershipType === DEFAULT_PACKAGE &&
        m.membershipPeriod === DEFAULT_PERIOD
    )
  );

  const getDynamicFeatureContent = (f, activePackage) => {
    const featureValue = dynamicParse(f.title, {
      charLimit: Intl.NumberFormat("en-US").format(
        activePackage?.membershipDetails.charLimit
      ),
      userLimit: activePackage?.membershipDetails.userLimit,
      membershipType: activePackage.supportTypeName,
    });

    return featureValue;
  };

  const onClickToSelectItem = (item) => {
    ReactGA.event({
      category: "test",
      action: "test",
      label: "test",
    });

    if (item?.membershipDetails?.id || item?.id) {
      const selectedPackageId = item?.membershipDetails?.id || item?.id;
      const generateUTMLink =
        CONFIG.APP.REGISTER_URL +
        `?utm_source=${encodeURIComponent(
          window.location.href
        )}&utm_medium=referral&utm_content=js_pricing_page_btn&selectedPackageId=${selectedPackageId}`;
      window.location.href = generateUTMLink;
    }
  };

  useEffect(() => {
    if (selectedPeriod || selectedCurrency) {
      const availableList = Object.entries(packageOptions.packages.displayNames)
        .map(([key, value]) => ({
          key,
          value,
          keyName: key,
          displayName: packageOptions.packages.displayNames[key],
          membershipDetails: memberships.find(
            (m) =>
              m.membershipType === key && m.membershipPeriod === selectedPeriod
          ),
          supportTypeName: packageOptions.packages.supportTypeNames[key],
          features: packageOptions.packages.features,
        }))
        .filter((f) => !RESTRICTED_PACKAGES.includes(f.keyName));

      setPackageList(availableList);
      setSelectedPackage(
        memberships.find(
          (m) =>
            m.membershipType === DEFAULT_PACKAGE &&
            m.membershipPeriod === selectedPeriod
        )
      );
    }
  }, [selectedPeriod, selectedCurrency]);

  return (
    <div className="pricing-page">
      <section className="pricing-page-hero">
        <section className="container mx-auto">
          <div className="pricing-page-heading grid  grid-cols-4 lg:grid-cols-8">
            <div className="col-span-full flex flex-col justify-center lg:col-span-6 lg:col-start-2">
              <h1>
                Choose the <span style={{ color: "#47ccdf" }}>plan</span> that
                fits your needs
              </h1>
              <button onClick={() => onClickToSelectItem(FreePackage)}>
                Try for Free 14 Days, no credit card required.
              </button>
              <div className="segment-container">
                <SegmentedControl
                  name="period"
                  callback={(val) => setSelectedPeriod(val)}
                  controlRef={useRef()}
                  defaultIndex={0}
                  segments={[
                    {
                      label: "Monthly",
                      value: "MONTHLY",
                      ref: useRef(),
                    },
                    {
                      label: "Yearly",
                      value: "YEARLY",
                      ref: useRef(),
                    },
                  ]}
                />

                {/*<SegmentedControl
                name="currency"
                callback={(val) => setSelectedCurrency(val)}
                controlRef={useRef()}
                defaultIndex={1}
                segments={[
                  {
                    label: "₺",
                    value: "TRY",
                    ref: useRef(),
                  },
                  {
                    label: "$",
                    value: "USD",
                    ref: useRef(),
                  },
                ]}
              />*/}
              </div>
            </div>
          </div>
        </section>
      </section>

      <section className="bg-white w-full js-section_nav-dark pricing-page-content__wrapper">
        <div className="container mx-auto">
          <div className="pricing-page-content grid grid-cols-4 lg:grid-cols-8">
            <div className="col-span-full col-start-1 lg:col-span-4 lg:col-start-2"></div>
            <div className="pricing-page-list col-span-full">
              {packageList.map((item) => {
                return (
                  <div
                    className={
                      item?.membershipDetails?.id === selectedPackage?.id
                        ? `pricing-page-list__pricing-box__wrapper pricing-page-list__pricing-box__wrapper--selected`
                        : `pricing-page-list__pricing-box__wrapper`
                    }
                    onClick={() => onClickToSelectItem(item)}
                  >
                    <div
                      className={
                        item.membershipDetails.disabled
                          ? `pricing-page-list__pricing-box pricing-page-list__pricing-box--disabled`
                          : item?.membershipDetails?.id === selectedPackage?.id
                          ? `pricing-page-list__pricing-box pricing-page-list__pricing-box--selected`
                          : `pricing-page-list__pricing-box`
                      }
                    >
                      <div className="pricing-page-list__pricing-box__title">
                        {item.displayName}
                      </div>

                      <div className="pricing-page-list__pricing-box__label">
                        {item?.membershipDetails?.extendable
                          ? "EXTENDABLE"
                          : "STANDARD"}
                      </div>
                      <div className="pricing-page-list__pricing-box__features">
                        <ul>
                          {item.features.map((f, i) => (
                            <li
                              key={i}
                              className={f.highlight ? `highlighted` : ``}
                            >
                              <span className="label">
                                {f.dynamic
                                  ? getDynamicFeatureContent(f, item)
                                  : f.title}
                              </span>
                              <span className="icon">
                                <CheckIcon />
                              </span>
                            </li>
                          ))}
                        </ul>
                      </div>

                      <div className="pricing-page-list__pricing-box__price">
                        <span className="currency-period">
                          {item.membershipDetails?.membershipType === "FREEMIUM"
                            ? "2 wk."
                            : item?.membershipDetails?.membershipPeriod ===
                              "MONTHLY"
                            ? "mon."
                            : "yr."}
                        </span>
                        <MileStone
                          duration={0.25}
                          end={
                            selectedCurrency === "TRY"
                              ? item.membershipDetails.packagePriceTRYWithTax
                                  .amount
                              : item.membershipDetails.packagePrice.amount
                          }
                          formattingFn={
                            Intl.NumberFormat(
                              selectedCurrency === "TRY" ? "tr-TR" : "en-US"
                            ).format
                          }
                        />
                        <span className="currency-symbol">
                          {selectedCurrency === "TRY" ? "₺" : "$"}
                        </span>
                        <span className="currency-note">All Tax Included</span>
                      </div>
                      {item?.membershipDetails?.id === selectedPackage?.id ? (
                        <div className="pricing-page-list__pricing-box__popular">
                          POPULAR
                        </div>
                      ) : null}
                    </div>
                  </div>
                );
              })}
            </div>
            <div className="pricing-page-enterprise col-span-full">
              <div className="pricing-page-enterprise__title">
                <span className="pricing-page-enterprise__title-first">
                  Free Trial
                </span>
                <span className="pricing-page-enterprise__title-second">
                  for 14 Days
                </span>
              </div>
              <div className="pricing-page-enterprise__desc">
                Explore all the features Lugath has to offer during your
                complimentary 14-day trial.
                <br />
                Begin your trial now.
              </div>
              <div className="pricing-page-enterprise__cta">
                <button onClick={() => onClickToSelectItem(FreePackage)}>
                  Get Started
                </button>
              </div>
            </div>
            {/*<div className="pricing-page-notes col-span-full">
              Don't miss our exclusive this year valid campaign that allows you
              to add unlimited users to your account! Manage your Application
              Keys, Auto TM, and Custom MT, select your plan to according what
              you are looking for today. If you have further questions contact
              our support team.
            </div>*/}
          </div>
        </div>
      </section>
    </div>
  );
}
