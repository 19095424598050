import React, { useState, useEffect, useCallback } from "react";
import { Link } from "react-router-dom";
import { ReactComponent as SolutionSoftwareLocalization } from "../../../Assets/Icons/solution-software-localization.svg";
import { ReactComponent as SolutionDocumentTranslation } from "../../../Assets/Icons/solution-document-translation.svg";
import { ReactComponent as SolutionCustomerService } from "../../../Assets/Icons/solution-customer-service.svg";
import { ReactComponent as SolutionMarketingAsset } from "../../../Assets/Icons/solution-marketing-assets.svg";
import { ReactComponent as ChevronRight } from "../../../Assets/Icons/chevron-right.svg";
import "./index.scss";

export default function Solutions() {
  return (
    <section className="bg-solutions js-section_nav-dark">
      <div className="solutions-section container mx-auto">
        <h1 className="solutions-section__title">
          What you can localize with{" "}
          <span style={{ color: "#fff" }}>lugath? </span>
        </h1>
        <div className="solutions-section__content">
          <div className="solutions-section__list">
            <div className="solutions-section__list-item__wrapper">
              <div className="solutions-section__list-item">
                <div className="solutions-section__list-item__top">
                  <div className="solutions-section__list-item__icon-wrapper">
                    <div className="solutions-section__list-item__icon">
                      <SolutionSoftwareLocalization />
                    </div>
                  </div>
                  <div className="solutions-section__list-item__content">
                    <div className="solutions-section__list-item__title">
                      <h2>Software Localization</h2>
                    </div>
                  </div>
                </div>
                <div className="solutions-section__list-item__center">
                  <div className="solutions-section__list-item__desc">
                    <p>Perform localization for both the static and dynamic elements of web, mobile, or game apps.</p>
                  </div>
                </div>
                <div className="solutions-section__list-item__bottom">
                  <div className="solutions-section__list-item__link">
                    <Link to={"/"}>
                      Discover <ChevronRight />
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="solutions-section__list-item__wrapper">
              <div className="solutions-section__list-item">
                <div className="solutions-section__list-item__top">
                  <div className="solutions-section__list-item__icon-wrapper">
                    <div className="solutions-section__list-item__icon">
                      <SolutionDocumentTranslation />
                    </div>
                  </div>
                  <div className="solutions-section__list-item__content">
                    <div className="solutions-section__list-item__title">
                      <h2>Document Localization</h2>
                    </div>
                  </div>
                </div>
                <div className="solutions-section__list-item__center">
                  <div className="solutions-section__list-item__desc">
                    <p>
                      Submit your documents and utilize the most suitable machine translation engine while preserving the original document design and file format in seconds.
                    </p>
                  </div>
                </div>
                <div className="solutions-section__list-item__bottom">
                  <div className="solutions-section__list-item__link">
                    <Link to={"/"}>
                      Discover <ChevronRight />
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="solutions-section__list-item__wrapper">
              <div className="solutions-section__list-item">
                <div className="solutions-section__list-item__top">
                  <div className="solutions-section__list-item__icon-wrapper">
                    <div className="solutions-section__list-item__icon">
                      <SolutionCustomerService />
                    </div>
                  </div>
                  <div className="solutions-section__list-item__content">
                    <div className="solutions-section__list-item__title">
                      <h2>Customer Service</h2>
                    </div>
                  </div>
                </div>
                <div className="solutions-section__list-item__center">
                  <div className="solutions-section__list-item__desc">
                    <p>
                      Help customers within their own native languages and
                      provide excellent customer service.
                    </p>
                  </div>
                </div>
                <div className="solutions-section__list-item__bottom">
                  <div className="solutions-section__list-item__link">
                    <Link to={"/"}>
                      Discover <ChevronRight />
                    </Link>
                  </div>
                </div>
              </div>
            </div>

            <div className="solutions-section__list-item__wrapper">
              <div className="solutions-section__list-item">
                <div className="solutions-section__list-item__top">
                  <div className="solutions-section__list-item__icon-wrapper">
                    <div className="solutions-section__list-item__icon">
                      <SolutionMarketingAsset />
                    </div>
                  </div>
                  <div className="solutions-section__list-item__content">
                    <div className="solutions-section__list-item__title">
                      <h2>Marketing Assets</h2>
                    </div>
                  </div>
                </div>
                <div className="solutions-section__list-item__center">
                  <div className="solutions-section__list-item__desc">
                    <p>
                      Expand your audience by translated web pages, blogs,
                      emails, and other marketing materials.
                    </p>
                  </div>
                </div>
                <div className="solutions-section__list-item__bottom">
                  <div className="solutions-section__list-item__link">
                    <Link to={"/"}>
                      Discover <ChevronRight />
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
