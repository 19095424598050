import React from "react";
import Navbar from "../Components/Navbar";
import ScrollToTop from "../Components/ScrollToTop";
import Footer from "../Components/Footer";
import LoadingBar from "../Components/LoadingBar";
import SEOContent from "../Components/SEOContent/";

export default function ErrorLayout(props) {
  const getLayout = () => {
    return (
      <React.Fragment>
        <SEOContent />
        {/*<LoadingBar />
        <ScrollToTop />
    <Navbar />*/}
        <div>{props.children}</div>
        {/*<Footer />*/}
      </React.Fragment>
    );
  };
  return getLayout();
}
