import React, { useState } from "react";
import { useParams, useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { showFormPopUp } from "../../../Reducers/settings";

import "./accordion.scss";
export default function Accordion({ question, answer }) {
  const [showAnswer, setShowAnswer] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const contentClickHandler = (e) => {
    const targetLink = e.target.closest("a");
    if (!targetLink) return;
    e.preventDefault();
    if (targetLink.getAttribute("target") === "_blank") {
      window.open(targetLink.getAttribute("href"), "_blank").focus();
      return;
    }
    if (targetLink.getAttribute("href") === "get-early-access") {
      dispatch(showFormPopUp("dark"));
      return;
    }
    navigate(targetLink.getAttribute("href"));
  };

  return (
    <div onClick={() => setShowAnswer(!showAnswer)} className="accordion">
      <div className="accordion-question">
        <h3 className={`${showAnswer ? "active" : ""}`}>{question}</h3>
        <div
          className={`accordion-icon__wrapper ${showAnswer ? "active" : ""}`}
        >
          <div className="accordion-icon__inner">
            <div className="accordion-icon">
              <button className="button"> </button>
            </div>
          </div>
        </div>
      </div>
      <div
        className={`${
          showAnswer ? "accordion-answer show" : "accordion-answer hide"
        }`}
      >
        <div
          onClick={contentClickHandler}
          className="content"
          dangerouslySetInnerHTML={{ __html: answer }}
        ></div>
      </div>
    </div>
  );
}
