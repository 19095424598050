import React from "react";
import Accordion from "../../Components/FaqPageComponents/Accordion";
import FaqPageCategory from "../../Components/FaqPageComponents/Category";
import "./index.scss";



export default function GenericFAQs({ questions }) {
  return (
    <section className="generic-faqs js-section_nav-dark">
      <div className="w-full">
        <div className="container mx-auto">
          <div className="generic-faqs-content grid grid-cols-4 lg:grid-cols-8">
            <div className="col-span-full col-start-1 lg:col-span-6 lg:col-start-2">
              {questions.map((category) => {
                return (
                  <div
                    key={category.id}
                    className="category-group"
                    id={`${category.title}`}
                  >
                    <h2>{category.title}</h2>
                    {category.children.map((child) => {
                      return (
                        <Accordion
                          key={child.id}
                          question={child.question}
                          answer={child.answer}
                        />
                      );
                    })}
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
