import React, { useState, useEffect, useCallback } from "react";
import { ReactComponent as ChevronRight } from "../../Assets/Icons/chevron-right.svg";
import { ReactComponent as DropboxLogo } from "../../Assets/Images/Integration/dropbox.svg";
import { ReactComponent as NotionLogo } from "../../Assets/Images/Integration/notion.svg";
import { ReactComponent as EvernoteLogo } from "../../Assets/Images/Integration/evernote.svg";
import { ReactComponent as IntercomLogo } from "../../Assets/Images/Integration/intercom.svg";
import { ReactComponent as HubspotLogo } from "../../Assets/Images/Integration/hubspot.svg";
import { ReactComponent as SlackLogo } from "../../Assets/Images/Integration/slack.svg";
import { ReactComponent as JiraLogo } from "../../Assets/Images/Integration/jira.svg";
import { ReactComponent as StrapiLogo } from "../../Assets/Images/Integration/strapi.svg";
import { ReactComponent as ZendeskLogo } from "../../Assets/Images/Integration/zendesk.svg";
import { ReactComponent as ZapierLogo } from "../../Assets/Images/Integration/zapier.svg";
import { ReactComponent as TypeformLogo } from "../../Assets/Images/Integration/typeform.svg";
import { ReactComponent as LugathLogo } from "../../Assets/Icons/logo-t.svg";
import { Link } from "react-router-dom";
import "./index.scss";

export default function Integrations() {
  return (
    <section className="bg-integrations js-section_nav-dark">
      <div className="integrations-section container mx-auto">
        <h1 className="integrations-section__title">
          Works happens all
          <br />
          <span style={{ color: "#aaa" }}>over the place </span>
        </h1>
        {/*<p className="integrations-section__desc">
          Integrates with all your essential apps.
  </p>*/}
        <div className="integrations-section__content">
          <div className="parent">
            <div className="int-card div1">
              {" "}
              <span className="int-card-logo">
                <TypeformLogo />
              </span>
              <span className="int-card-desc">Typeform</span>
            </div>
            <div className="int-card int-card--lugath div2">
              <LugathLogo />
            </div>

            <div className="int-card div3">
              {" "}
              <span className="int-card-logo">
                <ZendeskLogo />{" "}
              </span>
              <span className="int-card-desc">Zendesk</span>
            </div>
            <div className="int-card div4">
              {" "}
              <span className="int-card-logo">
                <StrapiLogo />
              </span>
              <span className="int-card-desc">Strapi</span>
            </div>
            <div className="int-card div5">
              {" "}
              <span className="int-card-logo">
                <JiraLogo />
              </span>
              <span className="int-card-desc">Jira</span>
            </div>

            <div className="int-card div6">
              {" "}
              <span className="int-card-logo">
                <DropboxLogo />
              </span>
              <span className="int-card-desc">Dropbox</span>
            </div>
            <div className="int-card div7">
              {" "}
              <span className="int-card-logo">
                <HubspotLogo />
              </span>
              <span className="int-card-desc">Hubspot</span>
            </div>
            <div className="int-card div8">
              {" "}
              <span className="int-card-logo">
                <IntercomLogo />{" "}
              </span>
              <span className="int-card-desc">Intercom</span>
            </div>
            <div className="int-card div9">
              {" "}
              <span className="int-card-logo">
                <ZapierLogo />{" "}
              </span>
              <span className="int-card-desc">Zapier</span>
            </div>
          </div>
        </div>
        <div className="integrations-section__link-wrapper">
          <Link className="integrations-section__link" to={"/integrations"}>
            <span className="integrations-section__link-desc">
              View All Integrations
            </span>
            <span className="integrations-section__link-icon">
              <ChevronRight />
            </span>
          </Link>
        </div>
      </div>
    </section>
  );
}
