import _extend from "lodash/extend";
import { newsData } from "../Services/DataService";

const SUCCESS_SUFFIX = "_SUCCESS";
const LIST_NEWS = "LIST_NEWS";
const ADD_NEW = "ADD_NEW";
const UPDATE_NEW = "UPDATE_NEW";
const DELETE_NEW = "DELETE_NEW";
const GET_NEW = "GET_NEW";

const initialState = [];

// eslint-disable-next-line
export default (state = initialState, action) => {
  switch (action.type) {
    case LIST_NEWS + SUCCESS_SUFFIX:
      return action.payload.data;

    case GET_NEW + SUCCESS_SUFFIX:
      return action.payload.data;

    case ADD_NEW + SUCCESS_SUFFIX:
      return action.payload.data;

    case UPDATE_NEW + SUCCESS_SUFFIX:
      return action.payload.data;

    case DELETE_NEW + SUCCESS_SUFFIX:
      return _extend({}, state, action.payload.data);

    default:
      return state;
  }
};
// eslint-disable-next-line
export const allNews = () => {
  return (dispatch) => {
    dispatch({ type: LIST_NEWS + SUCCESS_SUFFIX, payload: { data: newsData } });
  };
};
// eslint-disable-next-line
export const setActiveNew = (slug) => {
  return (dispatch) => {
    dispatch({
      type: GET_NEW + SUCCESS_SUFFIX,
      payload: { data: newsData.filter((item) => item.slug === slug) },
    });
  };
};
