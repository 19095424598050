import React, { useState } from "react";
import SolutionDetailsHeader from "../../Components/DetailsPageComponents/SolutionDetailsHeader";
import SolutionDetailsCover from "../../Components/DetailsPageComponents/SolutionDetailsCover";
import TextToutWithImage from "../../Components/TextToutWithImage";
import TextTout from "../../Components/TextTout";
import Testimonials from "../../Components/Testimonials";
import HeroTextToutWithList from "../../Components/TextToutWithList";
import HeroImage from "../../Components/HeroImage";
import Integrations from "../../Components/Integrations";
import OtherProfiles from "../../Components/OtherProfiles";
import ProductDetailsHeader from "../../Components/DetailsPageComponents/ProductDetailsHeader";
import ProductDetailsDemo from "../../Components/DetailsPageComponents/ProductDetailsDemoVideo";
import ProductFeatureList from "../../Components/DetailsPageComponents/ProductFeatureList";
import ProductFAQs from "../../Components/DetailsPageComponents/ProductFAQs";
import BetaForm from "../../Components/BetaForm";
import ProductSpecific from "../../Components/DetailsPageComponents/ProductSpecific";
import SupportedFormats from "../../Components/DetailsPageComponents/SupportedFormats";
import WorkflowScheme from "../../Components/DetailsPageComponents/WorkflowScheme";
const modules = {
  SolutionDetailsHeader: (data, options, productName) => (
    <SolutionDetailsHeader
      data={{ ...data }}
      options={{ ...options }}
      productName={productName}
    />
  ),
  SolutionDetailsCover: (data, options, productName) => (
    <SolutionDetailsCover
      data={{ ...data }}
      options={{ ...options }}
      productName={productName}
    />
  ),
  TextToutWithImage: (data, options, productName) => (
    <TextToutWithImage
      data={{ ...data }}
      options={{ ...options }}
      productName={productName}
    />
  ),
  TextTout: (data, options, productName) => (
    <TextTout
      data={{ ...data }}
      options={{ ...options }}
      productName={productName}
    />
  ),
  Testimonials: (data, options, productName) => (
    <Testimonials
      data={{ ...data }}
      options={{ ...options }}
      productName={productName}
    />
  ),
  HeroImage: (data, options, productName) => (
    <HeroImage
      data={{ ...data }}
      options={{ ...options }}
      productName={productName}
    />
  ),
  HeroTextToutWithList: (data, options, productName) => (
    <HeroTextToutWithList
      data={{ ...data }}
      options={{ ...options }}
      productName={productName}
    />
  ),
  Integrations: (data, options, productName) => (
    <Integrations
      data={{ ...data }}
      options={{ ...options }}
      productName={productName}
    />
  ),
  OtherProfiles: (data, options, productName) => (
    <OtherProfiles
      data={{ ...data }}
      options={{ ...options }}
      productName={productName}
    />
  ),
  ProductDetailsHeader: (data, options, productName) => (
    <ProductDetailsHeader
      data={{ ...data }}
      options={{ ...options }}
      productName={productName}
    />
  ),
  ProductDetailsDemo: (data, options, productName) => (
    <ProductDetailsDemo
      data={{ ...data }}
      options={{ ...options }}
      productName={productName}
    />
  ),
  ProductFeatureList: (data, options, productName) => (
    <ProductFeatureList
      data={{ ...data }}
      options={{ ...options }}
      productName={productName}
    />
  ),
  ProductFAQs: (data, options, productName) => (
    <ProductFAQs
      data={{ ...data }}
      options={{ ...options }}
      productName={productName}
    />
  ),
  BetaForm: (data, options, productName) => (
    <BetaForm
      data={{ ...data }}
      options={{ ...options }}
      productName={productName}
    />
  ),
  ProductSpecific: (data, options, productName) => (
    <ProductSpecific
      data={{ ...data }}
      options={{ ...options }}
      productName={productName}
    />
  ),
  SupportedFormats: (data, options, productName) => (
    <SupportedFormats
      data={{ ...data }}
      options={{ ...options }}
      productName={productName}
    />
  ),
  WorkflowScheme: (data, options, productName) => (
    <WorkflowScheme
      data={{ ...data }}
      options={{ ...options }}
      productName={productName}
    />
  ),
};
export default function Modular({ module }) {
  return typeof modules?.[module?.moduleName] === "function"
    ? modules?.[module?.moduleName](
        module?.data ? module?.data : {},
        module?.options ? module?.options : {},
        module?.productName ? module?.productName : false
      )
    : null;
}
