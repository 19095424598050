import React from "react";
import CountUp from "react-countup";
import VisibilitySensor from "react-visibility-sensor";

export default function MileStone(props) {
  const [focus, setFocus] = React.useState(false);
  return (
    <CountUp
      {...(props.formattingFn ? { formattingFn: props.formattingFn } : {})}
      start={focus ? 0 : null}
      end={props.end}
      duration={props.duration ? props.duration : 4}
      redraw={true}
    >
      {({ countUpRef }) => (
        <VisibilitySensor
          onChange={(isVisible) => {
            if (isVisible) {
              setFocus(true);
            } else {
              setFocus(false);
            }
          }}
        >
          <span ref={countUpRef} />
        </VisibilitySensor>
      )}
    </CountUp>
  );
}
