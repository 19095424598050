import React from "react";
import FirstSection from "../../Components/CompanyPageComponents/FirstSection/";
import "./index.scss";

export default function Company() {
  return (
    <div className="company-page container mx-auto">
      <FirstSection></FirstSection>
    </div>
  );
}
