export const questions = [
  {
    id: 0,
    title: "General",
    children: [
      {
        id: 1,
        question: "What is Lugath?",
        answer:
          "Lugath is a localization platform for global growth. You can <b>automates web, mobile or game localization</b> with the power of <b>machine translation</b> and <b>generative AI</b>.",
      },
      {
        id: 2,
        question: "How Lugath works?",
        answer:
          "Lugath is an <b>AI-Based Localization</b> platform that use <b>machine learning</b> and <b>natural language processing</b> methods. <br/><br/>Integrated with 13 systems including OpenAI and measuring the translation quality regularly. You can work with <b>the best fit World-Class AI translation engines</b> for 8 industry domains and more than 2600 language pairs.",
      },
      {
        id: 3,
        question: "Who is Lugath for?",
        answer:
          "Lugath is designed for <a href='/solutions/localization-for-developers'>developers</a>, <a href='/solutions/localization-for-designers'>designers</a>, <a href='/solutions/localization-for-managers'>localization managers</a>, <a href='/solutions/localization-for-translators'>translators</a>, and <a href='/solutions/localization-for-enterprises'>enterprises</a>.",
      },
    ],
  },
  {
    id: 1,
    title: "Plattform",
    children: [
      {
        id: 1,
        question: "Where should I start?",
        answer:
          "You can watch our <a href='/tutorials'>video tutorials</a> or If you need automation for localization, here is the <a target='_blank' href='https://docs.lugath.com'>documentation</a> of our scalable APIs.",
      },
      {
        id: 2,
        question: "Can I use Lugath with as a company wide platform?",
        answer:
          "Yes, Lugath support <b>team creation</b> feature. You can define user accounts for your colleagues in the same team or for different departments.",
      },
    ],
  },
  {
    id: 2,
    title: "Membership",
    children: [
      {
        id: 1,
        question: "How is the pricing?",
        answer:
          "We are establishing translation character credits for monthly or yearly subscriptions.<br/><br/>You can check <a href='/pricing' class='inline-link'>pricing page</a> for more details.",
      },
      {
        id: 2,
        question: "How can I start using Lugath?",
        answer:
          "<a target='_blank' href='https://app.lugath.com'>Register for Free Trial</a> or <a href='get-early-access'>Schedule a Demo</a>",
      },
    ],
  },
  {
    id: 3,
    title: "Support",
    children: [
      {
        id: 1,
        question: "Do you need more information?",
        answer:
          "Our support team is here for you, please do not hesitate to <br/><a href='/contact' class='inline-link'>contact us</a> for further information. You can also create a support ticket or send an email to <a\n" +
          '              className="external-link"\n' +
          '              href="mailto:support@lugath.com"\n' +
          '              target="_blank"\n' +
          '              rel="noreferrer"\n' +
          "            >\n" +
          "              support@lugath.com\n" +
          "            </a>",
      },
      /*{
        id: 2,
        question: "How can I get a quote?",
        answer:
          "content",
      },
      {
        id: 3,
        question: "How is your pricing policy?",
        answer:
          "content",
      },*/
    ],
  },
];
