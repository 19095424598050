import React from "react";
import Marquee from "react-fast-marquee";
import { ReactComponent as CloseIcon } from "../../Assets/Icons/close-icon--alternative.svg";
import "./index.scss";

export default function MarqueeComponent({
  marqueeIsVisible,
  onClickCloseMarquee,
}) {
  if (marqueeIsVisible) {
    return (
      <div className="marquee-general-wrapper">
        {" "}
        <a href="https://ces2023.lugath.com">
          <Marquee gradientColor={[40, 40, 40]} speed={80} gradientWidth={300}>
            <div>
              <span>
                We look forward to meeting you at <strong>#CES2023.</strong>
              </span>
              <span>
                63319 📍 Venetian Expo G Eureka Park Booth{" "}
                <strong>#63319</strong> between{" "}
                <strong>January 5 - January 8</strong>
              </span>
              <span>
                Let´s talk about
                <strong>#machinetranslation</strong>
                and explore together! 💪
              </span>

              <span>
                For more information visit{" "}
                <a target="_blank" href="https://ces2023.lugath.com">
                  https://ces2023.lugath.com
                </a>
              </span>
            </div>
            <div>
              <span>
                We look forward to meeting you at <strong>#CES2023.</strong>
              </span>
              <span>
                📍 Venetian Expo G Eureka Park Booth <strong>#63319</strong>{" "}
                between <strong>January 5 - January 8</strong>
              </span>
              <span>
                Let´s talk about
                <strong>#machinetranslation</strong>
                and explore together! 💪
              </span>

              <span>
                {" "}
                <a target="_blank" href="https://ces2023.lugath.com">
                  https://ces2023.lugath.com
                </a>
              </span>
            </div>
          </Marquee>
        </a>
        <button
          className="marquee-close-icon"
          onClick={() => onClickCloseMarquee()}
        >
          <CloseIcon />
        </button>
      </div>
    );
  } else {
    return null;
  }
}
