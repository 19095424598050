import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { v4 as uuidv4 } from "uuid";
import { Link, useParams, useLocation, useNavigate } from "react-router-dom";
import Image from "../../Image";
import { ReactComponent as ShapeIdentity } from "../../../Assets/Images/Shapes/shape-identity.svg";
import { ReactComponent as ProductIcon } from "../../../Assets/Icons/product-ai-translation-2.svg";
import { ReactComponent as ShapeSquareLeft } from "../../../Assets/Images/Shapes/shape-square.svg";
import { ReactComponent as ShapeSquareRight } from "../../../Assets/Images/Shapes/shape-square--light.svg";
import { ReactComponent as LinearShapeRight } from "../../../Assets/Images/Shapes/shape-square-linear.svg";
import { ReactComponent as LinearShapeLeft } from "../../../Assets/Images/Shapes/shape-square-linear--left.svg";
import "./index.scss";
import Shaper from "../../Shaper";
import CTAButtonWrapper from "../../CTABtnWrapper";

export default function ProductDetailsHeader({ data, productName }) {
  useEffect(() => {}, [data]);

  return (
    <section className="product-details-header js-section_nav-dark">
      <div className="container mx-auto">
        <div className="product-details-header__top">
          <div className="product-details-header__top-inner">
            <span className="product-details-header__top__pen">
              {data?.alias}
            </span>
            {data?.status ? (
              <span
                className={`product-details-header__top__status product-details-header__top__status--${data.status.alias}`}
              >
                {data?.status.label}
              </span>
            ) : null}
          </div>
        </div>
      </div>
      <div className="container mx-auto">
        <div className="parent">
          <div className="div1">
            <div className="product-icon__wrapper">
              <div className="product-icon">
                {data?.icon ? (
                  <Image
                    fileName={`Assets/Images/Products/${data?.icon}`}
                    width=""
                  />
                ) : null}
              </div>
            </div>
          </div>
          <div className="div2">
            <div className="product-alias__wrapper">
              <div className="product-alias">
                <span className="product-alias__name">{productName}</span>
              </div>
            </div>
          </div>
          <div className="div3">
            <div className="product-shape-square-left__wrapper">
              <div className="product-shape-square-left">
                <ShapeSquareLeft />
              </div>
            </div>
          </div>
          <div className="div4">
            <div className="product-content__wrapper">
              <div className="product-content">
                {" "}
                <h1 className="product-content__name">
                  {/*<span className="highlight">Power up your </span>
                  localization with generative <span className="shine">AI</span>*/}
                  {data?.shortText}
                </h1>
                <span className="product-content__description">
                  {data?.description}
                </span>{" "}
                {data?.cta?.length ? (
                  <>
                    <div className="product-content__cta-btn__wrapper">
                      {data.cta.map((c, i) => (
                        <CTAButtonWrapper
                          key={uuidv4()}
                          options={{
                            formPopupTheme: "light",
                            utmLabel: "js_product_header_cta_btn",
                          }}
                          children={
                            <button
                              key={uuidv4()}
                              className="product-content__cta-btn"
                            >
                              {" "}
                              {c.label}
                            </button>
                          }
                          data={{
                            ...c,
                            ...{ productName: productName ? productName : "" },
                          }}
                        ></CTAButtonWrapper>
                      ))}
                    </div>
                  </>
                ) : null}
              </div>
            </div>
          </div>
          <div className="div5">
            <div className="product-shape-linear-right__wrapper">
              <div className="product-shape-linear-right">
                <LinearShapeLeft />
              </div>
            </div>
          </div>
          <div className="div6">
            <div className="product-shape-linear-left__wrapper">
              <div className="product-shape-linear-left">
                <LinearShapeRight />
              </div>
            </div>
          </div>
          <div className="div7">
            <div className="product-shape-square-right__wrapper">
              <div className="product-shape-square-right">
                <ShapeSquareRight />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
