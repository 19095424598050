import React, { useState } from 'react'
import { useLocation } from "react-router-dom";
import LoadingBar from 'react-top-loading-bar';
import ReactGA from 'react-ga';

const TopLoadingBar = (props) => {

  const [progress, setProgress] = useState(0)

  const location = useLocation();
  
  React.useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
    setProgress(100)

  }, [location]);

  return (
    <div>
      <LoadingBar color="#47CCDF" height={5}  shadow={false} progress={progress} onLoaderFinished={() => setProgress(0)} waitingTime={1000} />
    </div>
  )
}

export default TopLoadingBar;