import React, { useState, useEffect } from "react";
import { Link, useParams, useLocation } from "react-router-dom";
import Image from "../../Components/Image";
import SolutionDetailsCover from "../../Components/DetailsPageComponents/SolutionDetailsCover";
import HeroTextToutWithList from "../../Components/TextToutWithList";
import { profilesData } from "../../Services/DataService";
import "./index.scss";

export default function Solutions() {
  return (
    <div className="solution-list-page">
      <section className="solution-list-page-header js-section_nav-dark">
        <div className="container mx-auto">
          <div className="parent">
            <div className="div1">
              <div className="solution-list-page-header__title">
                Solutions by <span className="shine">lugath</span>
              </div>
            </div>
            <div className="div2">
              <div className="solution-list-page-header__content">
                <div className="solution-list-page-header__content-desc">
                  Are you searching for a localization management platform that
                  can seamlessly adapt to your dynamic business environment?{" "}
                  <br />
                  <br /> Lugath is designed to address these challenges.
                </div>
                {/*<div className="solution-list-page-header__content-cta">
                  <button>See the Latest</button>
  </div>*/}
              </div>
            </div>
          </div>
        </div>
      </section>

      <HeroTextToutWithList
        options={{
          theme: "light",
          box: "light",
          listItems: 3,
          padding: "large-both", //default, large-both, small-both, large-top, large-bottom, small-both, small-top, small-bottom
        }}
        data={{
          primaryTitle: "Think Global, Act Local",
          secondaryTitle: (
            <>
              Create continuous localization workflow to support the global
              scaling of your products or services.
            </>
          ),
          features: profilesData,
        }}
      />
    </div>
  );
}
